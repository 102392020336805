import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from 'framework/src/Utilities';

// Customizable Area Start
interface PostOtpPinResponse {
  meta: {
    new_user: boolean;
    id: number;
    user_type: string;
    token:string;
  },
  errors?:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        phoneNumber: string
      }
    },
    push: Function
    }
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  otpText: string;
  otpTextError: string;
  openDialog: boolean;
  token: string | null;
  totalSeconds: number;
  isVerified: boolean;
  userId: number;
  otpNot: string;
  // Customizable Area Start
  fullFieldOtp:boolean;
  timeIsUp:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OtpverificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postOtpPin: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      otpText: "",
      otpTextError: "",
      openDialog: false,
      token: "",
      totalSeconds: 60,
      isVerified: false,
      userId: 0,
      otpNot:""
      // Customizable Area Start
      ,fullFieldOtp:false,
      timeIsUp:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    const token = localStorage.getItem("login-token");
    this.setState({ token: token})

    const intervalId = setInterval(() => {
      this.setState((prevState) => ({
        totalSeconds: prevState.totalSeconds - 1,
      }));
      if (this.state.totalSeconds <= 0) {
        clearInterval(intervalId); 
      }
    }, 1000);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId == this.postOtpPin && responseJson) {
        this.handleApiResponse(responseJson);
      }
    }


    // Customizable Area End
  }

  // Customizable Area Start

  handleBack = () => {
    this.props.history.push("/Signuplogin")
  }
  
  handleClose = () => {
    this.setState({ openDialog: false})
  };

  handleContinue = () => {
    this.props.history.push("/LandingPage")
  };

  handleOTPChange = (event:ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    this.setState({ otpText: value});
    this.state.otpText.length>=4?this.setState({ fullFieldOtp: true}):this.setState({ fullFieldOtp: false});

    if (!/^\d{5}$/.test(value)) {
      this.setState({ otpTextError: 'OTP can’t be empty'});
    } else {
        this.setState({ otpTextError: ''});
    }
    this.setState({ otpTextError: ''});
  };

  handleSubmit  = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!/^\d{5}$/.test(this.state.otpText)) {
        this.setState({ otpTextError: 'OTP can’t be empty'});
    } else {
        this.setState({ otpTextError: ''});
        this.submitForm()
    }
  }

  submitForm = () => {
    const payload = {
      "data":{
          "pin": this.state.otpText
      }
  }
    const requestSubmitMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage));
    const requestHeader = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
    };
    this.postOtpPin = requestSubmitMessage.messageId;
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postOtpPinData,
    );
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(requestHeader)
    );
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(payload));
    runEngine.sendMessage(requestSubmitMessage.id, requestSubmitMessage);
  }

  handleApiResponse(responseJson: PostOtpPinResponse) {
    if (!responseJson.errors) {
      this.handleNoErrors(responseJson);
    } else {
      this.setState({ otpTextError: responseJson.errors });
    }
  }
  
  handleNoErrors = async(responseJson: PostOtpPinResponse) => {
    if (responseJson.meta.new_user === false) {
      await setStorageData("otp-token", responseJson.meta.token);
      await setStorageData("authToken", responseJson.meta.token);
      await setStorageData("user-id", (responseJson.meta.id)?.toString());
      this.handleExistingUser(responseJson);
    } else {
      this.handleNewUser(responseJson);
    }
  }
  
  handleExistingUser = async (responseJson: PostOtpPinResponse) => {
    if (responseJson.meta.user_type === "buyer") {
      this.setState({ isVerified: true },()=>{this.handleContinue()});
    } else if (responseJson.meta.user_type === "seller") {
      this.props.history.push("/Catalogue1");
    }
  }
  
  handleNewUser(responseJson: PostOtpPinResponse) {
    this.setState({ userId: responseJson.meta.id });
    this.props.history.push("/Signup", {
      phone: this.props.history.location.state.phoneNumber,
      user_id: this.state.userId
    });
  }

  formatTime = () => {
    const minutes = Math.floor(this.state.totalSeconds / 60);
    const seconds = this.state.totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    if(formattedMinutes=='00'&&formattedSeconds=='00'){
      if (!this.state.timeIsUp) {
        this.setState({timeIsUp:true})
      }
    }

    return `${formattedMinutes}:${formattedSeconds}`;
  }
  // Customizable Area End
}