import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface DataList {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    brand_name: string;
    price: number | null;
    prices: number[];
    change: string;
    change_up: boolean;
    description: string;
    manufacturer: string;
    specification: string;
    applications: string;
    certifications: string;
    is_recommended: boolean;
    product_id: string;
    images: {
      file_name: string;
      content_type: string;
      id: number;
      url: string;
    }[];
    category: string;
    sub_category: string;
    updated: string;
  };
}
interface FilterOptions {
  catId?: number;
  subCatId?: number;
  manuId?: string; // Adjust the type to string
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  categories: number;
  subCategories: number,
  manufacturer: string,
  searchProductData: {}[],
  categoriesList: {
    attributes: {
      id: number;
      name: string;
    }
  }[];
  subCategoryList: {
    attributes: {
      id: number;
      name: string;
    }
  }[];
  manufacturersList: {
    value: string;
    id: string;
  }[];
  proCount:number;
  searchText: string,
  rowsPerPage: number;
  page: number;
  dataList: DataList[];
  allChecked: boolean;
  allSelectedIds: number[];
  value: number;
  getCategoriesIds:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class AiproductsearchandselectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductListApiCallId: string = "";
  getSearchProductListApiCallId: string = "";
  getCategoriesListApiCallId: string = "";
  getSubCategoriesListApiCallId: string = "";
  getManufacturerListApiCallId: string = "";
  getFilterListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      categories: 0,
      subCategories: 0,
      manufacturer: "0",
      page: 0,
      proCount:0,
      searchText: "",
      searchProductData: [],
      rowsPerPage: 10,
      allSelectedIds: [],
      dataList: [],
      categoriesList: [],
      subCategoryList: [],
      manufacturersList: [],
      allChecked: false,
      value: 1,
      getCategoriesIds:""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.setState({
      getCategoriesIds:  await getStorageData("selectedCategoryIds")
    })  
    this.getProductList(0)
    this.getCategoriesList()
    this.getSubCategoriesList()
    this.getManufacturerList()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getProductListApiCallId) {
        this.setState({
          dataList: responseJson.data,
          proCount: responseJson.meta.pagy.count,
        }        
        )
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const proSearchJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getSearchProductListApiCallId) {
        this.setState({
          dataList: proSearchJson.data,
        }      
        )
      }
    }
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiProReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const catResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiProReqCallId === this.getCategoriesListApiCallId) {
        this.setState({
          categoriesList: catResJson?.data
        })      
}
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiSubCatReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const subCatResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiSubCatReqCallId === this.getSubCategoriesListApiCallId) {
        this.setState({
          subCategoryList: subCatResJson.data
        })
      }
    }
        this.apimanufReqCall(message)
        this.apiFilterReqCall(message)
    // Customizable Area End
  }
  apimanufReqCall= (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apimanufReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const manufResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apimanufReqCallId === this.getManufacturerListApiCallId) {
        this.setState({
          manufacturersList: manufResJson
        })
      }
    }
  }
  apiFilterReqCall= (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiFilterReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const filterResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiFilterReqCallId === this.getFilterListApiCallId) {        
        this.setState({
          dataList: filterResJson.data ? filterResJson.data : [],
          proCount: filterResJson.meta ? filterResJson.meta.pagy.count : 0,
        })
      }
    }
  }

  getProductList = async (pageNumber: number) => {
    const catQuery = JSON.parse(this.state.getCategoriesIds).map((catId:number) => `categories[]=${catId}`).join('&');      

    const headers = {
      token: await getStorageData('authToken'),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductListApiCallId = getValidationsMsg.messageId;    
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProductListPath}`+`${catQuery}` +`page=${pageNumber+1}&items=${this.state.rowsPerPage}`     
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getCategoriesList = async () => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesListApiCallId = getValidationMsg.messageId;
    getValidationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCategoryPath
    );

    getValidationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationMsg.id, getValidationMsg);
  }
  getSubCategoriesList = async () => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoriesListApiCallId = getValidationMessage.messageId;
    getValidationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allSubCategoryPath
    );

    getValidationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationMessage.id, getValidationMessage);
  }
  getManufacturerList = async () => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getManufacturerListApiCallId = getValMsg.messageId;
    getValMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allManufacturerPath
    );

    getValMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValMsg.id, getValMsg);
  }
  getFilterList = async ({ catId, subCatId, manuId }: FilterOptions) => {        
    const headers = {
      token: await getStorageData('otp-token'),
    };

    const getFilterValMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let filterPath = '';
    this.getFilterListApiCallId = getFilterValMsg.messageId;
    if (catId) {
      filterPath += `category_id=${catId}&`; 
    } else if (subCatId) {
      filterPath += `sub_category_id=${subCatId}&`;
    } else if (manuId !== "0") {
      filterPath += `manufacturer=${manuId}&`; 
    }
    getFilterValMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterListPath + filterPath +`page=${this.state.page+1}&items=${this.state.rowsPerPage}`    
    );

    getFilterValMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFilterValMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getFilterValMsg.id, getFilterValMsg);
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleChangeCat = (event: React.ChangeEvent<{ value: unknown }>) => {    
    const category = event.target.value as number; 
    this.setState({       
      categories:category })

      this.getFilterList({catId: category})
  }
  handleChangeSubCat = (event: React.ChangeEvent<{ value: unknown }>) => {
    const subCategory = event.target.value as number; 

    this.setState({ subCategories: subCategory })
    this.getFilterList({subCatId:subCategory})

  }
  handleChangeManu = (event: React.ChangeEvent<{ value: unknown }>) => {
    const manufact = event.target.value as string; 

    this.setState({ manufacturer: manufact })
    this.getFilterList({ manuId: manufact });

  }
  getProductDescription = (description: string) => {
    return description ?  `${description.slice(0,46)}...`:'';
  }
  handleRowClick = (event: React.ChangeEvent<HTMLInputElement>, checkedId: number) => {
    const isChecked = event.target.checked;
    this.setState((prevState: S) => {
      const updatedIds = isChecked
        ? [...prevState.allSelectedIds, checkedId]
        : prevState.allSelectedIds.filter((selectId: number) => selectId !== checkedId);
      const areAllChecked = prevState.dataList.every((data: DataList
      ) =>
        updatedIds.includes(data.attributes.id)
      );
      return {
        allSelectedIds: updatedIds,
        allChecked: areAllChecked,
      };
    });
  };


  handleAllChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isCheck = event.target.checked;
    this.setState({
      allChecked: isCheck
    })
    if (isCheck) {
      const newDataIds = this.state.dataList.map((item: DataList) => item.attributes.id)
      this.setState({
        allSelectedIds: newDataIds
      })
    } else {
      this.setState({
        allSelectedIds: []
      })
    }
  }
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      page: value
    })
    this.getProductList(value)
  };
  getSearchProductDataList = async () => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchProductListApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProductSearchListPath}`+ this.state.searchText
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleSearchProduct = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ searchText: event.target.value as string })
    this.getSearchProductDataList()
  };
  navigateToProduct = async () => {
    await setStorageData("selectedProductIds", JSON.stringify(this.state.allSelectedIds));
    this.aiProductNavigationMessage('Peritempricing')
  };

  goBack = () => {
    this.aiProductNavigationMessage('Catalogue1')
  }

  aiProductNavigationMessage = (path: string | undefined, props?: unknown) => {
    const aiProductMessage = new Message(getName(MessageEnum.NavigationMessage));
    aiProductMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    aiProductMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(aiProductMessage);
  }
  calculateEmptyRows = (rowsPerPage: number, totalCount: number, page: number): number => {
    return rowsPerPage - Math.min(rowsPerPage, totalCount - page * rowsPerPage);
  };
  
  calculateRangeEnd = (page: number, rowsPerPage: number, totalCount: number): number => {
    return Math.min((page + 1) * rowsPerPage, totalCount);
  };
  // Customizable Area End
}
