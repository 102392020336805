export const kxLogo = require("../assets/kx_logo.jpg");
export const landingImg = require("../assets/landing-img.jpg");
export const intro = require("../assets/intro.jpg");
export const about = require("../assets/about.jpg");
export const vector = require("../assets/Vector.jpg");
export const trust = require("../assets/Trust.jpg");
export const commit = require("../assets/commit.jpg");
export const customer = require("../assets/customer.jpg");
export const group = require("../assets/group.jpg");
export const boxIcon = require("../assets/box.jpg");
export const location = require("../assets/location.jpg");
export const down = require("../assets/down_arrow.jpg");
export const whatsapp = require("../assets/whatsap.jpg");
export const havels = require("../assets/havels.jpg");
export const gmIcon = require("../assets/gm.jpg");
export const crom = require("../assets/crom.jpg");
export const polycab = require("../assets/polycab.jpg");
export const footerlocation = require("../assets/footerlocation.jpg");
export const phone = require("../assets/phone.jpg");
export const mail = require("../assets/mail.jpg");
export const product = require("../assets/product.jpg");
export const locationColor = require("../assets/location-color.jpg");
export const downColor = require("../assets/down-color.jpg");
export const filter = require("../assets/filter.jpg");
export const back = require("../assets/back.png");
export const arrow = require("../assets/img.png");
export const postman = require("../assets/postman.jpg");
export const jira = require("../assets/jira.jpg");
export const jiter = require("../assets/jiter.jpg");
export const awsIcon = require("../assets/aws.jpg");
export const zapier = require("../assets/zapier.jpg");
export const keyIcon = require("../assets/key.jpg");
export const mapIcon = require("../assets/map.jpg");
export const builMat = require("../assets/budMatNew.png");
export const acc1 = require("../assets/acc1.png");
export const ultra2 = require("../assets/ultra2.png");
export const jindal3 = require("../assets/jindal3.png");
export const pidilite4 = require("../assets/pidilite4.png");
export const polycab5 = require("../assets/polycab5.png");
export const rr6 = require("../assets/rrk6.png");
export const kalika7 = require("../assets/kalika7.png");
export const globe8 = require("../assets/globe8.png");
export const greenply9 = require("../assets/greenply9.png");
export const dots = require("../assets/dots.png");
export const code1 = require("../assets/code1.png");
export const stack2 = require("../assets/stack2.png");
export const time3 = require("../assets/time3.png");
export const price4 = require("../assets/price4.png");
export const deliver5 = require("../assets/deliver5.png");
export const hands6 = require("../assets/hands6.png");
export const find7 = require("../assets/find7.png");
export const quality8 = require("../assets/quality8.png");
export const circle = require("../assets/circle.png");
export const x1 = require("../assets/x1.png");
export const facebook2 = require("../assets/facebook2.png");
export const linkedin3 = require("../assets/linkedin3.png");
export const youtube4 = require("../assets/youtube4.png");
export const chat = require("../assets/chatIco.png");
export const pim = require("../assets/PIM.png");
export const standart1 = require("../assets/standart1.png");
export const enrich2 = require("../assets/enrich2.png");
export const harmonize3 = require("../assets/harmonize3.png");
export const integrate4 = require("../assets/integrate4.png");
export const kratexAI = require("../assets/kratexAI.png");
export const procure = require("../assets/procure.png");
export const approve = require("../assets/approve.png");
export const automate = require("../assets/automate.png");
export const schedule = require("../assets/schedule.png");
export const statuses = require("../assets/statuses.png");
export const custom = require("../assets/custom.png");





