import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from 'react';
import { getStorageData } from 'framework/src/Utilities';
export interface Product {
  id: number;
  name: string;
  change: null;
  brand_name: string;
  price: number | null;
  created_at: string;
  updated_at: string;
  change_up: boolean;
  description: string;
  specification: string;
  is_recommended: boolean;
  applications: string;
  certifications: string;
  category_id: number;
  sub_category_id: number;
  manufacturer: string;
  pid: string;
}

interface ProductData {
  id: number;
  name: string;
  change: null;
  brand_name: string;
  price: number | null;
  created_at: string;
  updated_at: string;
  change_up: boolean;
  description: string;
  specification: string;
  is_recommended: boolean;
  applications: string;
  certifications: string;
  category_id: number;
  sub_category_id: number;
  manufacturer: string;
  pid: string;
}

interface ChipDataItem {
  label: string;
  key: number;
  checked: boolean;
  value: string
}

interface MenuItem {
  name: string;
  path: string;
}

interface ProductCat {
  productId: string;
  productName: string;
  imageUrl: string | null;
  imageName: string | null;
  bestSeller: boolean;
  New: string; 
  status: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: {
    location: {
      state: {
        selectCat: number
      }
    }
  }
  location: {
    state: {
      breadCrum: MenuItem[]
    }
  }
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  showFilterData: boolean;
  chipData: ChipDataItem[];
  page: number;
  filterData: ProductData[]
  shortChipData: ChipDataItem[];
  catBlock:boolean;
  tokenData: string,
  categoryId:  number | null,
  subCategoryId: number | null,
  productList: ProductCat[];
  idBrand: number | null;
  chipString: string[];
  isChecked: number[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getProductList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      showFilterData: false,
      chipData: [
        { label: "Newest to Oldest", key: 0, checked: false, value: "updated_desc" },
        { label: "Oldest to Newest", key: 1, checked: false, value: "updated_asc" },
        { label: "Sort by A to Z", key: 2, checked: false, value: "name_asc" },
      ],
      page: 1,
      filterData: [],
      shortChipData: [],
      catBlock:false,
      tokenData: "",
      categoryId:null,
      subCategoryId: null,
      productList: [],
      idBrand: null,
      chipString: [],
      isChecked: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductList != null &&
      this.getProductList ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        const productDetails = responseJson.data.map((product:{id: number, attributes:{description: string, images: any, is_best_seller: boolean, is_new: boolean, status: string }}) => {
          const productId = product.id;
          const productName = product.attributes.description;
          const images = product.attributes.images;
          const bestSeller = product.attributes.is_best_seller;
          const New = product.attributes.is_new;
          const status = product.attributes.status
          
          const imageUrl = images?.[0]?.url ?? null;

            return {
                productId,
                productName,
                imageUrl,
                bestSeller,
                New,
                status
            };
        });
        this.setState({ productList: productDetails})
        
      }else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ productList: []})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({ categoryId: this.props.history.location.state.selectCat });
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const tokenData = await getStorageData('otp-token');
    this.setState({ tokenData: tokenData});
    this.getProductListData()
  }

  handleOpen = () => {
    this.setState({ showFilterData: !this.state.showFilterData })
  }
  
  handleDropdownClick = (data: { label: string, key: number, checked: boolean }, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedChipData = this.state.chipData.map(item =>
      item.key === data.key ? { ...item, checked: event.target.checked } : item
    )

    const updatedShortChipData = updatedChipData.filter(item => item.checked)

    const dataValue = updatedShortChipData.map((data:ChipDataItem) => {
      return data.value
    })
  
    this.setState({
      chipData: updatedChipData,
      shortChipData: updatedShortChipData,
      chipString: dataValue
    },() => {
      this.getProductListData()
    });

  }

  handleDelete = (chipToDelete: { label: string, key: number, checked: boolean }) => () => {
    const updatedChipData = this.state.chipData.map(item =>
      item.key === chipToDelete.key ? { ...item, checked: false } : item
    )
    const deleteDataStore = this.state.shortChipData.filter((item: { label: string, key: number, checked: boolean }) => item.key !== chipToDelete.key);

    const deleteStringData = deleteDataStore.map((data: any) => {
      return data.value
    })
    this.setState({ chipData: updatedChipData, shortChipData: deleteDataStore, chipString: deleteStringData}, () => {
      this.getProductListData()
    })
  };

  delete = () => {
    this.setState({ shortChipData: [], chipData: this.state.chipData.map(item => ({ ...item, checked: false })), showFilterData: false, chipString:[] }, () => {
      this.getProductListData()
    });
  }

  handleChange = (event: ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
  };

  handlePrevClick = () => {
    if (this.state.page > 1) {
      this.setState((prevState) => ({ page: prevState.page - 1 }));
      this.getProductListData();
    }
  };
  handleToggleSidebar = () => {     
    this.setState({ catBlock: !this.state.catBlock});}

  handleNextClick = () => {
    if (this.state.page < 10) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
      this.getProductListData();
    }
  };

  handleCatId = (selectedCategoryId: {selectedCategoryId: number }) => {
    this.setState({ categoryId: selectedCategoryId.selectedCategoryId }, () => {
      this.getProductListData()
    })
    if(selectedCategoryId.selectedCategoryId === null){
      this.setState({subCategoryId: null }, () => {
        this.getProductListData()
      })
    }
  }

  handleSubCatId = (selectedSubCategoryId: {selectedSubCategoryId:number}) => {
    this.setState({ subCategoryId: selectedSubCategoryId.selectedSubCategoryId}, () => {
      this.getProductListData()
    })
  }

  handleBrandId = (isChecked: {isChecked: number}) => {
    this.setState({ idBrand: isChecked.isChecked}, () => {
      this.getProductListData()
    })

  }

  getProductListData = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.tokenData,
    };
  
    const queryParams = this.constructQueryParams();
    
    const endpoint = `bx_block_productdescription/products?${queryParams}`;
  
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductList = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  
  constructQueryParams = () => {
    const { subCategoryId, categoryId, idBrand, page, chipString } = this.state;
    let queryParams = '';
    if (subCategoryId !== null) {
      queryParams += `sub_category_id=${subCategoryId}&`;
    }
  

    if (idBrand !== null) {
      queryParams += `brand_id[]=${idBrand}&`;
    }

    if (page !== null) {
      queryParams += `page=${page}&`;
    }

    if (chipString !== null) {
      queryParams += `sort_by=${chipString}&`;
    }
  
    return queryParams;
  }
  
  // Customizable Area End
}
