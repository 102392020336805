import React from "react";

import {
  Box,
  Typography,
  Hidden,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { downArrow } from "./assets";


const AddressBlock = styled("div")(({ theme }) => ({
  cursor:"default",
  "& .label-text": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
  },
  "& .label-text::after": {
    color: "red",
    marginRight: "4px"
  },
  "& .input-data": {
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    padding: "10px"
  },
  "& .input-data:focus": {
    borderColor: "#FF6600",
    outline: "none",
  },
  '& .dropdown-list-language': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "41px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginTop: "10px"
  },
  "& .dropdown-list-data-language": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "350px",
    top: "60px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "98%",
    overflowY: "auto",
  },
  "& .dropdown-list-data-language li": {
    padding: "0px 20px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .input-data[type=number]::-webkit-inner-spin-button,.input-data[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .input-data[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& .address-data": {
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    padding: "10px"
  },
  "& .address-data-text": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#2B2A29",
    fontFamily: 'Inter, sans-serif',
  },
  "& .err-msg": {
    marginTop: "2px",
    color: "red",
    fontSize: "12px",
    fontFamily: 'Inter, sans-serif',
  },
  "& .search-data": {
    width: "100%",
    height: "30px",
    border: "1px solid #CBD5E1",
    borderRadius: "2px"
  }
}))
// Customizable Area End

import AddressInfoController, {
  Props,
} from "./AddressInfoController";

export default class AddressInfo extends AddressInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <AddressBlock>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box style={{ marginTop: "39px" }}>
                <Typography className="address-data-text">BILLING ADDRESS</Typography>
                <Box className="address-data">
                  <textarea
                    rows={2} 
                    cols={35}
                    style={{width:"100%", height:"100%", border:"none", font:"inherit, sans-serif", outline:"none", resize:"none"}}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Typography style={{ marginTop: "37px" }} className="address-data-text">DELIVERY ADDRESS</Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="label-text">Street</Typography>
              <input data-test-id="street-data" className="input-data" type="text" placeholder="Enter your street" value={this.state.addressData.street} onChange={this.handleAddressInfoChange} name="street"></input>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box style={{ position: "relative" }}>
                <Typography className="label-text">City</Typography>
                <Box data-test-id="city-btn" className='dropdown-list-language' onClick={this.handleDropdownCity}>
                  <Typography style={{ marginLeft: "15px" }}>{this.state.selectedOption}</Typography>
                  <img style={{ marginRight: "15px" }} src={downArrow} alt="down_arrow" />
                </Box>
                <Hidden >
                  {this.state.showDropDownCity && (
                    <div>
                      <ul className="dropdown-list-data-language">
                        <input
                          type="text"
                          data-test-id="search-btn-city"
                          placeholder="Search city..."
                          value={this.state.searchTerm}
                          className="search-data"
                          onChange={this.handleSearchChange}
                        />
                        {this.state.filteredCitiesData?.map((datas: { name: string }) => (
                          <li
                            id="dropdowndata"
                            className="dropdown-item"
                            data-test-id="dropdown-data-cities"
                            onClick={() => this.handleDropdownChange(datas.name)}
                          >
                            <div>{datas.name}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Hidden>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box style={{ position: "relative" }}>
                <Typography className="label-text">State<span style={{ color: "red" }}>*</span></Typography>
                <Box data-test-id="state-btn" className='dropdown-list-language' onClick={this.handleDropdownState}>
                  <Typography style={{ marginLeft: "15px" }}>{this.state.selectedStateOption}</Typography>
                  <img style={{ marginRight: "15px" }} src={downArrow} alt="down_arrow" />
                </Box>
                <Typography className="err-msg">{this.state.fieldRequiredData}</Typography>
                <Hidden >
                  {this.state.showDropDownState && (
                    <div>
                      <ul className="dropdown-list-data-language">
                        <input
                          type="text"
                          data-test-id="search-btn-state"
                          placeholder="Search state..."
                          value={this.state.searchStateTerm}
                          className="search-data"
                          onChange={this.handleStateSearch}
                        />
                        {this.state.filteredStatesData.map((datas: { name: string }) => (
                          <li
                            id="dropdowndata"
                            className="dropdown-item"
                            data-test-id="dropdown-data-state"
                            onClick={() => this.handleStateChange(datas.name)}
                          >
                            <div>{datas.name}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Hidden>
              </Box>
              <Box style={{ position: "relative" }}>
                <Box>
                  <Typography className="label-text">Zip code</Typography>
                  <input className="input-data" type="number" placeholder="Enter your street" value={this.state.addressData.zipcode} onChange={this.handleAddressInfoChange} name="zipcode"></input>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="label-text">Country<span style={{ color: "red" }}>*</span></Typography>
                <Box data-test-id="country-btn" className='dropdown-list-language' onClick={this.handleDropdownCountry}>
                  <Typography style={{ marginLeft: "15px" }}>{this.state.selectedCountryOption}</Typography>
                </Box>
            </Grid>
          </Grid>
        </Box>
      </AddressBlock>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End