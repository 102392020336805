import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        phoneNumber: string
      }
    },
    push: Function
  }
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  showCountry: boolean;
  phoneNumber: string;
  isValid: boolean;
  requireMsg: string;
  // Customizable Area Start
  selectedFlag:string;
  fullNumberField:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postOtpData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showCountry: false,
      phoneNumber: "",
      isValid: true,
      requireMsg: ""
      // Customizable Area Start
      ,selectedFlag:"+91",
      fullNumberField:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId == this.postOtpData) {
        if(responseJson.errors){      
          this.setState({ requireMsg: responseJson.errors })
        }else{
          localStorage.setItem("login-token",responseJson?.meta?.token)
          this.props.history.push("/Otpverification", { phoneNumber: this.state.phoneNumber})
        }
      }
    }


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  handleCode = (event:any) => {
    this.setState({ selectedFlag: event.target.value });
  }

  handleOtp = () => {
    this.submitForm()
  }

  handlePhoneNumberChange = (event:ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    this.state.phoneNumber.length >= 9 ? this.setState({fullNumberField:true}): this.setState({fullNumberField:false})   
    const isValidPhoneNumber = /^\d{0,10}$/.test(inputValue);
    if(isValidPhoneNumber){
      this.setState({ phoneNumber: inputValue, requireMsg:""})
    }
  };

  submitForm = () => {
   
    const payload = {
      "data": {
        "attributes": {
          "full_phone_number": this.state.phoneNumber
        }
      }
    }
    const requestSubmitMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    const requestHeader = {
      "Content-Type": configJSON.validationApiContentType,
    };
    this.postOtpData = requestSubmitMessage.messageId;
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postOtp,
    );
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));
    runEngine.sendMessage(requestSubmitMessage.id, requestSubmitMessage);
  }
  // Customizable Area End
}