export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/image_logo.jpg");
export const designTop = require("../assets/design-top.jpg");
export const designBottom = require("../assets/design-bottom.jpg");
export const roundLeft = require("../assets/round-left.jpg");
export const login = require("../assets/login.jpg");
export const flag = require("../assets/India-flag.jpg");
export const usaFlag = require("../assets/USA.jpg");
export const down = require("../assets/caret-down.jpg");
export const check = require("../assets/check.jpg");
export const back = require("../assets/arrow_back.jpg");
export const rightTick = require("../assets/right-tick.jpg");
export const cross = require("../assets/cross.jpg");
export const topDesign = require("../assets/top-design.jpg");
export const downArrow = require("../assets/down.jpg");
export const checking = require("../assets/che.png");
export const imgDown = require("../assets/image-down.jpg");
export const uploadIcon = require("../assets/upload-icon.jpg");
export const kxLogo = require("../assets/kxlogo.jpg");
export const correct = require("../assets/correct.jpg");
export const close = require("../assets/close.png");
















