import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { State, City }  from 'country-state-city';

export interface Location {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
}

export interface Statelocationdata {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
  isoCode: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  deliveryAdd:{phonenumber: string, contact: string, street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string, delivery_point:string};
  passState: Function;
  fieldRequiredData: string;
  // Customizable Area End
}

interface S {
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
    // Customizable Area Start
    deliveryData:{phonenumber: string, contact: string, street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string, delivery_point: string};
    selectedOption: string;
    showDropDownCity: boolean;
    searchTerm: string;
    selectedStateOption: string;
    showDropDownState: boolean;
    searchStateTerm: string;
    selectedCountryOption: string;
    filteredCitieData:  Location[];
    filteredStateData:  Statelocationdata[]
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class DeliveryAddBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    //   txtInputValue: "",
    //   txtSavedValue: "A",
    //   enableField: false,
      
            // Customizable Area Start
      deliveryData:{phonenumber: "", contact: "", street: "", zipcode: "", selectedOption: "", selectedStateOption: "", selectedCountryOption: "",delivery_point:""},
      selectedOption: "",
      showDropDownCity: false,
      searchTerm:"",
      selectedStateOption: "",
      showDropDownState: false,
      searchStateTerm: "",
      selectedCountryOption: "India",
      filteredCitieData: [],
      filteredStateData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({deliveryData: this.props.deliveryAdd, selectedOption: this.props.deliveryAdd.selectedOption,selectedStateOption:this.props.deliveryAdd.selectedStateOption});
    
    const cities = City.getCitiesOfCountry('IN');

    const filteredCitie: Location[] | undefined = cities && cities.filter((city: { name: string }) => city.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())) as Location[];
    this.setState({ filteredCitieData: filteredCitie ? filteredCitie : [] });
    
    const states = State.getStatesOfCountry('IN');
    const filteredState: Statelocationdata[] | undefined = states && states.filter((state:{name:string}) => state.name.toLowerCase().includes(this.state.searchStateTerm.toLowerCase())) as Statelocationdata[];
    this.setState({ filteredStateData:filteredState})
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
    if(prevProps.deliveryAdd.phonenumber !== this.props.deliveryAdd.phonenumber){   
      this.setState({ deliveryData: this.props.deliveryAdd, selectedOption: this.props.deliveryAdd.selectedOption, selectedStateOption: this.props.deliveryAdd.selectedStateOption, selectedCountryOption: this.state.selectedCountryOption})
    }
  }

  // Customizable Area Start
  handleDeliveryInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({deliveryData:{...this.state.deliveryData, [event.target.name]:event.target.value}});
    this.props.passState({ ...this.state.deliveryData, [event.target.name]: event.target.value, selectedOption: this.state.selectedOption, selectedStateOption:this.state.selectedStateOption, selectedCountryOption: this.state.selectedCountryOption });
  }

  handleCityDropdownChange = (event: string) => {
    this.setState({ selectedOption: event, showDropDownCity: false});
    this.props.passState({...this.state.deliveryData, selectedOption:event, selectedCountryOption: this.state.selectedCountryOption})

  };

  handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleDropdownCity = () => {
    this.setState({ showDropDownCity: !this.state.showDropDownCity })
  }

  handleStateChange = (event: string) => {
    this.setState({ selectedStateOption: event, showDropDownState: false });
    this.props.passState({...this.state.deliveryData,selectedStateOption:event,selectedOption:this.state.selectedOption, selectedCountryOption: this.state.selectedCountryOption })
  };


  handleDropdownState = () => {
    this.setState({ showDropDownState: !this.state.showDropDownState })
  }


  handleStateSearch = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchStateTerm: event.target.value });
  };
  // Customizable Area End
}
