import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type ProductFeatureData = {
  id: string;
  type: string;
  attributes: {
      feature_name: string;
      feature_options: {
          id: number;
          option_value: string;
          product_feature_id: number;
      }[];
  };
};
interface ProductFeature {
  data: ProductFeatureData[];
}
interface Image {
  file_name: string;
  content_type: string;
  id: number;
  url: string;
}
interface Price {
  id: number;
  action: string;
  quantity: number;
  amount: number;
  product_id: number | null;
  created_at: string;
  updated_at: string;
  seller_product_id: number;
}
interface ProductAttributes {
  id: number;
  name: string;
  brand_name: string;
  price: number | null;
  prices:Price[];
  change: string;
  change_up: boolean;
  description: string;
  manufacturer: string;
  specification: string;
  applications: string;
  certifications: string;
  is_recommended: boolean;
  status: string;
  product_id: string;
  images?: Image[];
  category: string;
  sub_category: string;
  brand:string;
  updated: string;
  is_new: string;
  is_best_seller: boolean;
  product_feature: ProductFeature;
}
interface ProductData {
  id: string;
  type: string;
  attributes: ProductAttributes;
}
interface SellerProductAttributes {
  price: string | number;
  account_id: number;
  product_id: number;
  created_at: string;
  updated_at: string;
  prices: Price[];
  product: {
    data: ProductData;
  };
}
export interface SellerProduct {
  id: number;
  type: string;
  attributes: SellerProductAttributes;
}

interface Product{
  id: string;
  type: string;
  attributes: {
      price: string;
      account_id: number;
      product_id: number;
      created_at: string; 
      updated_at: string; 
      prices: Price[];
      product: {
          data: {
              id: string;
              type: string;
              attributes: {
                  id: number;
                  name: string;
                  brand_name: string;
                  price: number;
                  prices: Price[]; 
                  change: string;
                  change_up: boolean;
                  description: string;
                  manufacturer: string;
                  specification: string; 
                  applications: string;
                  certifications: string;
                  is_recommended: boolean;
                  status: string;
                  product_id: string;
                  images: {
                      file_name: string;
                      content_type: string;
                      id: number;
                      url: string; 
                  }[];
                  category: string;
                  sub_category: string;
                  brand: string;
                  updated: string;
                  is_new: string;
                  is_best_seller: boolean;
                  product_feature: {
                      data: ProductFeatureData[]; 
                  };
              };
          };
      };
  };
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  categories: string
  rowsPerPage: number;
  page: number;
  openView: boolean;
  openModel: boolean;
  dataList: SellerProduct[];
  viewProData: {
    product: {
      data: Product;
    };
  };
  value: number;
  range: {
    id: number,
    quantity:number,
    amount:number,
    action:string,
    isNew?: boolean
  }[];
  proTotalCount: number;
  searchText: string,
  deletedId: number | string;
  deletePriceId:number;
  tableLoading:boolean;
  showFullText:boolean;
  showFullObj:boolean;
  getProductIds: string,
  allPrice: {
    id: number,
    price: string | number;
  }[]
  priceIds: {
    id: number | string,
    price: string | number;
  }[];
  rangeIds:{
    id?: number,
    quantity:number,
    amount:number,
    action: string,
    isNew?: boolean
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PeritempricingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postCreateProApiCallId: string = "";
  getSearchListApiCallId: string = "";
  getDeleteApiCallId: string = "";
  getDeletePriceApiCallId: string = "";
  updatePriceApiCallId: string = "";
  productPriceApiCallId: string = "";
  viewProductApiCallId: string = "";
  postProUpdateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      categories: "categories",
      page: 0,
      value: 0,
      showFullText: false,
      showFullObj: false,
      tableLoading:true,
      range: [{id:0, action: '<',quantity: 0, amount: 0,isNew:true}],
      openView: false,
      openModel: false,
      priceIds: [],
      rangeIds:[],
      searchText: "",
      rowsPerPage: 10,
      getProductIds: "",
      dataList: [],
      viewProData:{
        product: {
          data: {
            id: "",
            type: "",
            attributes: {
                price: "",
                account_id: 0,
                product_id: 0,
                created_at: "",
                updated_at: "",
                prices: [],
                product: {
                    data: {
                        id: "",
                        type: "",
                        attributes: {
                            id: 0,
                            name: "",
                            brand_name: "",
                            price: 0,
                            prices: [],
                            change: "",
                            change_up: false,
                            description: "",
                            manufacturer: "",
                            specification: "",
                            applications: "",
                            certifications: "",
                            is_recommended: false,
                            status: "",
                            product_id: "",
                            images: [],
                            category: "",
                            sub_category: "",
                            brand: "",
                            updated: "",
                            is_new: "",
                            is_best_seller: false,
                            product_feature: {
                                data: []
                            }
                        }
                    }
                }
            }
        }
      }
      },
      deletedId: 0,
      deletePriceId:0,
      proTotalCount: 0,
      allPrice: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    const selectedProductIds = await getStorageData("selectedProductIds")
    this.setState({
      getProductIds: selectedProductIds
    })
    this.createProductData();
    this.getProductPricingData(0);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
     
      if (apiRequestCallId === this.postCreateProApiCallId) {
        this.getProductPricingData(0)
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const proSearchJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSearchListApiCallId) {       
        
        this.setState({
          dataList: proSearchJson.data,
          proTotalCount: proSearchJson.meta.pagy.count
        }
        )
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiDeleteReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const proDeleteJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiDeleteReqCallId === this.getDeleteApiCallId) {
        if (proDeleteJson.product === "Seller Product Delete Successfully") {
          const findIndex = this.state.dataList.findIndex((data) => data.id === this.state.deletedId)
          const newUpdatedData = [
            ...this.state.dataList.slice(0, findIndex),
            ...this.state.dataList.slice(findIndex + 1)
          ];
          this.setState({
            dataList: newUpdatedData
          })
        }
      }
    }
  this.productPrice(message)
  this.viewProduct(message)
  this.deletePrice(message)
   
    // Customizable Area End
  }
  deletePrice = (message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiDeleteReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const proDeletePriceJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiDeleteReqCallId === this.getDeletePriceApiCallId) {
        if (proDeletePriceJson.message === "Price has beend deleted!") {          
          const findIndex = this.state.range.findIndex((data) => data.id === this.state.deletePriceId)
          const newUpdatedData = [
            ...this.state.range.slice(0, findIndex),
            ...this.state.range.slice(findIndex + 1)
          ];
          this.setState({
            range: newUpdatedData
          })
        }
      }
    }
  }
  productPrice = (message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const proPricingJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiCallId === this.productPriceApiCallId) {       
        this.setState({
          tableLoading:false,
          dataList: proPricingJson.data,
          proTotalCount: proPricingJson.meta.pagy.count
        }
        )       
      }
    }  
   
  }
  viewProduct= (message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiViewCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const viewProJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiViewCallId === this.viewProductApiCallId) {  
        const extractedPrices = viewProJson.product.data.attributes.prices.map(
          (price: Price) => ({
            id: price.id,
            action: price.action,
            quantity: price.quantity,
            amount: price.amount
          })
        );     
        this.setState({         
          viewProData: viewProJson,  
          range: extractedPrices?.length>0 ? extractedPrices : [{id:0, action: '<',quantity: 0, amount: 0,isNew:true}]         
        }
        )       
      }
    }
  }
  createProductData = async () => {
    const payload = {
      products: JSON.parse(this.state.getProductIds)
    }
    const requestSubmitMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    const requestHeader = {
      token: await getStorageData('otp-token'),
      "Content-Type": configJSON.validationApiContentType,
    };
    this.postCreateProApiCallId = requestSubmitMessage.messageId;
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createProductPath,
    );
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestSubmitMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));
    runEngine.sendMessage(requestSubmitMessage.id, requestSubmitMessage);
  }
  updateBulkPrice = async () => {
    const payload = this.state.priceIds
    const requestUpdateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    const requestHeader = {
      token: await getStorageData('otp-token'),
      "Content-Type": configJSON.validationApiContentType,
    };
    this.updatePriceApiCallId = requestUpdateMessage.messageId;
    requestUpdateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePricePath,
    );
    requestUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );
    requestUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));
    runEngine.sendMessage(requestUpdateMessage.id, requestUpdateMessage);
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getSearchDataList = async (pageNumber:number) => {
    const headers = {
      token: await getStorageData('otp-token'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchListApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchProductPath+`search=` + this.state.searchText 
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  deleteProductData = async (deletedId: number | string) => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeleteApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProductPath + deletedId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  deleteProducaPrice = async (deletedId: number) => {
    const headers = {
      token: await getStorageData('otp-token'),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeletePriceApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.priceDeletePath + deletedId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getProductPricingData = async (pageNumber: number) => {
    this.setState({tableLoading:true})
    let tokenData =  await getStorageData('otp-token')
    const headers = {
      token: tokenData
    };
    const getProPriceValMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productPriceApiCallId = getProPriceValMsg.messageId;
    getProPriceValMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productPricingListPath + `page=${pageNumber + 1}&items=${this.state.rowsPerPage}`
    );

    getProPriceValMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getProPriceValMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getProPriceValMsg.id, getProPriceValMsg);
  }
  getViewProductData = async (dataId: string | number) => {
    this.setState({tableLoading:true})
    let tokenData =  await getStorageData('otp-token')
    const headers = {
      token: tokenData
    };
    const getViewProValMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.viewProductApiCallId = getViewProValMsg.messageId;
    getViewProValMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewProductListPath  + Number(dataId)
    );

    getViewProValMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getViewProValMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getViewProValMsg.id, getViewProValMsg);
  }
  priceUpdateData = async (dataId:string) => {
    const payload ={data:this.state.rangeIds.map((element:{ id?: number; quantity: number; amount: number; action: string;isNew?: boolean}) => {
      return element.isNew ? {quantity: element.quantity, amount: element.amount, action: element.action} : {id:element.id,quantity: element.quantity, amount: element.amount, action: element.action}
    })};
    
    const reqUpdateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    const requestHeader = {
      token: await getStorageData('otp-token'),
      "Content-Type": configJSON.validationApiContentType,
    };
    this.postProUpdateApiCallId = reqUpdateMessage.messageId;
    reqUpdateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.priceUpdatePath + dataId,
    );
    reqUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );
    reqUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    reqUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));
    runEngine.sendMessage(reqUpdateMessage.id, reqUpdateMessage);
  }


  handleChangePagePerItem = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      page: value
    })
    this.getProductPricingData(value)
  };

  handleDelete = () => {
    this.deleteProductData(this.state.deletedId)
    this.handleClose()
  };

  navigateToProduct = () => {
    this.updateBulkPrice();
  };
  handleSaveBtn=(dataId:string) => {
    this.priceUpdateData(dataId)
  }
  handleOpenView = (event: React.MouseEvent<HTMLImageElement, MouseEvent>, dataId: string | number) => {
    this.setState({
        openView: true
    });
    this.getViewProductData(dataId);
};
  handleViewClose = () => {
    this.setState({
      openView: false,
      tableLoading: false
    })
  }
  handleReadMoreClick = () => {
    this.setState({ showFullText: true })   
  };
  handleLessClick = () => {
    this.setState({ showFullText: false })
  }; 
  handleLearnMoreClick = () => {
    this.setState({ showFullObj: true })   
  };
  handleLearnLessClick = () => {
    this.setState({ showFullObj: false })
  };
  handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

handleChangeQty = (event: React.ChangeEvent<{ value: unknown, name: string }>, index: number) => {
  const { value, name } = event.target;
  const parsedValue = name === 'quantity' || name === 'amount' ? parseFloat(value as string) : value;
    let range = this.state.range[index];
    this.state.range[index] = {...range,[name]:parsedValue}
    const updatedRange = this.state.rangeIds.filter((rang: { id?: number; quantity: number; amount: number; action: string;}) => rang.id !== range.id)    
    this.setState({ range: this.state.range,rangeIds: [...updatedRange,this.state.range[index]] });
};



handleAddRange = () => {
  const newEntry = {
    id: this.state.range.length,
    quantity: 0,
    amount: 0,
    action: "",
    isNew: true
  };
  this.setState((prevState: S) => ({
    range: [...prevState.range, newEntry]
  }));
};
getSpecifications = (specification:string) => {
  return specification && specification !== "{}" ? JSON.parse(specification) : {};
}

  handleDeleteRange = (dataId:number, indexToDelete:number) => {    
    this.setState((prevState: S) => ({
      range: prevState.range.filter((ranges, index) => index !== indexToDelete),
    }))    
    this.deleteProducaPrice(dataId)
    this.setState({ deletePriceId: dataId });
  };
  handleSearchData = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ searchText: event.target.value as string })
    this.getSearchDataList(this.state.page)
  };
  handleClickOpen = (dataId: string|number ) => {
    this.setState({ 
      deletedId: dataId ,
      openModel: true
    
    })
   
  };
  handleClose = () => {
    this.setState({ openModel: false });
  };
  handlePriceChange = (event: {value: string , dataId: number | string}) => {
    const updatedProductData = this.state.dataList.map((item) => {
      if (item.id === event.dataId) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            price: event.value
          }
        };
      }
      return item;
    });
    let idsProduct = this.state.priceIds.findIndex((element: { id: number | string, price: number | string }) => element?.id === event.dataId);
    let priceData = this.state.priceIds;
    if (idsProduct !== -1) {
      priceData[idsProduct] = { id: event.dataId, price: event.value }
    } else {
      priceData.push({ id: event.dataId, price: event.value })
    }
    this.setState({ priceIds: priceData })
    this.setState({ dataList: updatedProductData });
  };

  goBackProduct = () => {
    this.perItemNavigationMessage('Aiproductsearchandselection', this.props)
  }
  perItemNavigationMessage = (path: string | undefined, props?: unknown) => {
    const perItemMessage = new Message(getName(MessageEnum.NavigationMessage));
    perItemMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    perItemMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);
    this.send(perItemMessage);
  }
  itemsEmptyRows = (rowsPerPage: number, totalCount: number, page: number): number => {
    let emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCount - page * rowsPerPage);
    return emptyRows;
  };
  
  itemRangeEnd = (page: number, rowsPerPage: number, totalCount: number): number => {
    let endRange = Math.min((page + 1) * rowsPerPage, totalCount);
    return endRange
  };
  // Customizable Area End
}
