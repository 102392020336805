import React from "react";

import { Typography, Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import {
  pim,
  standart1,
  enrich2,
  harmonize3,
  integrate4,
  kratexAI,
  procure,
  approve,
  automate,
  schedule,
  statuses,
  custom
} from "./assets";

import LandingPageController, { Props } from "./LandingPageController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import Marquee from "react-fast-marquee";

export const PlatformBlock = styled("div")(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  cursor:"default",
  "& .main-content": {
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden'
  },
  "& .platformPage":{
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color:"#565D6D",
    cursor:"pointer",
    "@media (max-width:900px)": {
      padding:"10px"
    },
  },
  "& .platformPage_selected":{
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color:"#FF6600",
    backgroundColor:"#FFF6F0",
    padding:"15px 12px",
    borderRadius:"18px",
    display:"flex",
    justifyContent:"center",
    alignItems:'center',
    cursor:"pointer",
    "@media (max-width:900px)": {
      padding:"10px"
    },
  },
  "& .platformPages":{
    marginTop:"50px",
    marginBottom:"30px",
    "@media (max-width:900px)": {
      width:"100vw",
      paddingLeft:"40px"
    },
  },
  "& .revProdLabel":{
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
    color:"#323743",
    textAlign:"center"
  },
  "& .revProdLabel1":{
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color:"#323743",
    textAlign:"center"
  },
  "& .efficientPIM":{
    fontSize: "48px",
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
    color:"#323743",
    textAlign:"center", 
    "@media (max-width:900px)": {
      fontSize: "35px",    
    },
  },
  "& .efficientPIMSecondary":{
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color:"#323743",
    textAlign:"center",
    width:"90vw"
  },
  "& .efficientPIMSecondaryContainer":{
    display:"flex",
    justifyContent:"center",
    marginTop:"15px"
  },
  "& .pimImg":{
    width:"90vw"
  },
  "& .pimImgContainer":{
    display:"flex",
    justifyContent:"center",
    marginTop:"35px",
  },
  "& .separator":{
    borderBottom:"1px solid #FFC9A4",
    marginTop:"-20px",
    "@media (max-width:900px)": {
      marginTop:"-5px",
    },
  },
  "& .prodRevText":{
    fontSize: "32px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color:"#171A1F",
    textAlign:"center",
    "@media (max-width:900px)": {
      fontSize: "25px",
    },
  },
  "& .prodRevContainer":{
    margin:"88px",
    marginBottom:"66px",
    "@media (max-width:900px)": {
      margin:"40px",
    }
  },
  "& .prodCard":{
    width:"250px",
    textAlign:"center",
    margin:"36px"
  },
  "& .prodCard__img":{
    height:"90px"
  },
  "& .prodCards":{
   marginBottom:"84px"
  },
  "& .prodCard__text":{
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
    color:"#323743",
    marginBottom:"20px"
  },
  "& .kratexAIAssistant__text":{
    fontSize: "48px",
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
    color:"#323743",
    "@media (max-width:900px)": {
      fontSize: "30px",
    },
  },
  "& .kratexAIAssistant__textContainer":{
    width:"80vw",
    display:"flex",
    justifyContent:"center",
    textAlign:"center",
    margin:"0px 10vw"
  },
  "& .kratexAIAssistant__Img":{
    width:"95vw"
  },
  "& .kratexAIAssistant__ImgContainer":{
    display:"flex",
    justifyContent:"center",
    marginBottom:"180px",
    "@media (max-width:900px)": {
      marginBottom:"170px",
    },
  },
  "& .procureSecondaryContainer":{
    display:"flex",
    justifyContent:"center",
    textAlign:"center",
    padding:"0px 140px",
    "@media (max-width:900px)": {
      padding:"0px 40px",
      width:"100vw"
    },
  },
  "& .procureSecondary":{
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color:"#9095A1",
    lineHeight:"26px"
  },
  "& .procureImg":{
    width:"760px",
    "@media (max-width:900px)": {
      width:"400px",
    },
  },
  "& .procureTextContainer":{
    textAlign:"center",
  },
  "& .procureTextContainer_main":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "48px",
    fontWeight: 500,
    color:"#323743",
    lineHeight:"68px",
    marginTop:"16px"
  },
  "& .procureTech":{
    width:"100vw"
  },
  "& .procureTextContainer_secondary":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#9095A1",
  },
  "& .textOrange":{
    color:"#FF6600"
  },
  "& .procureContainer":{
    display:"flex",
    justifyContent:"center",
    "@media (max-width:900px)": {
      width:"100vw"
    },
  },
  "& .graySeparator":{
    width:"100%",
    border:"1px solid #DEE1E6",
    margin:"60px 0px"
  },
  "& .apprAutoProcSecondary":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    color:"#9095A1",
  },
  "& .approveImg":{
    width:"600px",
    "@media (max-width:900px)": {
      width:"80vw"
    },
  },
  "& .mobileMargin":{
    "@media (max-width:900px)": {
      width:"80vw"
    },
  },
  "& .approveText_main":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "40px",
    fontWeight: 500,
    color:"#323743",
  },
  "& .approveTextContainer":{
    textAlign:"center",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .approveText_sec":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#9095A1",
    width:"80%"
  },
  "& .automateArg":{
    margin:"0px 60px"
  },
  "& .controlStatusText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "32px",
    fontWeight: 500,
    color:"#323743",
    textAlign:"center",
    margin:"0px 100px",
    "@media (max-width:900px)": {
      margin:"0px 0px",
      fontSize: "25px",
    },
  },
  "& .controlStasusImg":{
    width:"100vw"
  },
  "& .customText_sec":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#9095A1",
  },
  "& .mobileWidth":{
    "@media (max-width:900px)": {
      width:"100vw"
    }
  },
  "& .AIBlock":{
    padding:"55px 63px 55px 50px",
    border:"1px solid #BDC1CA",
    borderRadius:"24px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    width:"40%",
    "@media (max-width:900px)": {
      padding:"5px 6px 5px 5px",
      borderRadius:"12px",
    },
  },
  "& .AIBlock-main":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "32px",
    fontWeight: 400,
    color:"#171A1F",
    marginBottom:"20px",
    textAlign:"center",
    "@media (max-width:900px)": {
      fontSize: "12px",
      marginBottom:"10px",
    },
  },
  "& .AIBlock-sec":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "18px",
    fontWeight: 400,
    color:"#9095A1",
    marginBottom:"20px",
    textAlign:"center",
    "@media (max-width:900px)": {
      fontSize: "6px",
      marginBottom:"10px",
    },
  },
  "& .AIBlock-btn":{
    border:"1px solid transparent",
    borderRadius:"12px",
    backgroundColor:"#FF6600",
    fontSize: "18px",
    fontWeight: 400,
    color:"#FFF",
    textAlign:"center",
    padding:"12px 70px",
    cursor:"pointer",
    "@media (max-width:900px)": {
      fontSize: "6px",
      padding:"4px 12px",
      borderRadius:"6px",
    },
  },
  "& .AIBlockContainer":{
    display:"flex",
    width:"100vw",
    padding:"0px 40px",
    marginBottom:"180px",
    marginTop:"60px",
    "@media (max-width:900px)": {
      padding:"0px 10px",
      marginBottom:"160px"
    },
    
  },
  "& .AIBlockContainer-img":{
    width:"29%",
    height:"auto",
    objectFit: "contain",
  },
  "& .stasusImg":{
    height:"36px",
    margin:"0px 6px",
  },
  "& .marqueeWrap":{
    marginTop:"40px"
  },
  "& .confirmed-status":{
    width:"138px",
    height:"36px",
    backgroundColor:"#FFF6F0",
    borderRadius:"18px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    border:"1px solid #117B34"
  },
  "& .confirmed-status-text":{
    fontWeight:400,
    fontSize:"14px",
    color:"#117B34"
  },
  "& .renderedStatus":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    border:"1px solid",
    borderRadius:"18px", 
    padding:"0px 30px",
    height:"36px",
    margin:"0px 6px",
  },
  "& .renderedStatus-text":{
    fontWeight:400,
    fontSize:"14px"
  },
  "& .prodCard__container":{
    width:"250px",
    height:"310px",
    border:"1px solid #DEE1E6",
    borderRadius:"18px",
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    alignItems: "center",
    justifyItems: "center"
  },
  "& .prodCard__desc": {
    alignSelf: "start",
    justifySelf: "center",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 500,
    color:"#9095A1",
    padding:"0px 18px"
  }
}));



export default class Platform extends LandingPageController {
  prodCard=[
    {
      name:"STANDARDIZE",
      desc:'Achieve uniformity in data collection and processing, ensuring compatibility with any tool and system.',
      src: standart1
    },
    {
      name:"ENRICH",
      desc:'Industry data is transformed into a powerhouse of insights, enhancing quality and depth to unlock meaningful information from every data point.',
      src: enrich2
    },
    {
      name:"HARMONIZE",
      desc:'Bring harmony to a world of scattered data. Experience seamless, realtime synchronization across all tools and platforms, ensuring cohesive and accurate data flow.',
      src: harmonize3
    },
    {
      name:"INTEGRATE",
      desc:'Integrate standardized and harmonized data across your digital landscapes and backend to achieve seamlessness.',
      src: integrate4
    },
  ]

  render() {

    return (
        <PlatformBlock>
          <Navbar history={this.props.history} handleNavigate={() => this.handleNavigate()} data-test-id="nav-bar"/>
          <Grid container className="platformPages" justifyContent="center" spacing={5}>
            <Grid item className={this.state.page==1?"platformPage_selected":"platformPage"} data-test-id="marketplace-btn" onClick={()=>{this.setState({page:1})}}>
              KrateX Marketplace
            </Grid>
            <Grid item className={this.state.page==2?"platformPage_selected":"platformPage"} data-test-id="prod-btn" onClick={()=>{this.setState({page:2})}}>
              Prod IQ™
            </Grid>
            <Grid item className={this.state.page==3?"platformPage_selected":"platformPage"} data-test-id="assistant-btn" onClick={()=>{this.setState({page:3})}}>
              Kratex AI Assitant
            </Grid>
            <Grid item className={this.state.page==4?"platformPage_selected":"platformPage"} data-test-id="procure-btn" onClick={()=>{this.setState({page:4})}}>
              ProcureTech AI
            </Grid>
            <Grid item className={this.state.page==5?"platformPage_selected":"platformPage"} data-test-id="kredline-btn" onClick={()=>{this.setState({page:5})}}>
              KredLine
            </Grid>
          </Grid>
          {this.state.page == 2 ?
            <Grid container justifyContent="center" direction="column">
            <Grid item sm={12} md={12} lg={12}>
              <Typography className="revProdLabel">
                Revolutionize Construction Procurement with 
              </Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <Typography className="efficientPIM">
              ProdIQ™ <span style={{color:"#FF6600"}}>The most efficient PIM</span> 
              </Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} className="efficientPIMSecondaryContainer">
              <Typography className="efficientPIMSecondary">
              It is a solution tailor made for the construction industry, offering seamless, 
              real-time data synchronization and standardization, 
              offering effortless backend integration with features like continous updation, 
              AI-driven data cleansing, and harmonization, 
              ensuring your product information is always accurate and up-to-date.
              </Typography>
            </Grid>
            <Grid item className="pimImgContainer" sm={12} md={12} lg={12}> 
              <img src={require("../assets/pim.svg")} className="pimImg"/>
            </Grid>
            <Box className="separator"></Box>
            <Grid item className="prodRevContainer" sm={12} md={12} lg={12}>
              <Typography className="prodRevText">
                <span style={{color:"#FF6600"}}>ProdIQ™  revolutionizes</span> industry specific data management by ingesting, extracting,
                and organizing data into a powerful standardized format.
              </Typography>
            </Grid>
            <Grid container direction="row" className="prodCards" justifyContent="center">
              {this.prodCard.map((elem, i)=>{
                return(
                  <Grid item direction="column" className="prodCard" key={i}>
                    <Typography className="prodCard__text">
                      {elem.name}
                    </Typography>
                    <Box className="prodCard__container">
                      <img src={elem.src} className="prodCard__img"/>
                    <Typography className="prodCard__desc">
                      {elem.desc}
                    </Typography>
                    </Box>                    
                  </Grid>
                )
              })}
            </Grid>
            </Grid>
          : ""}
          {this.state.page == 3 ? 
            <Grid container className="kratexAIAssistant" direction="column" justifyContent="center">
              <Grid item className="kratexAIAssistant__textContainer" sm={12} md={12} lg={12}>
                <Typography className="kratexAIAssistant__text">
                  Kratex AI Assistant: The Smartest AI for the Construction Industry
                </Typography>
              </Grid>
              <Grid item className="AIBlockContainer">
                <img src={require("../assets/firstAi.svg")} alt="ai" className="AIBlockContainer-img"/>
                <div className="AIBlock">
                  <Typography className="AIBlock-main">
                    <span className="textOrange">Unleash</span> the hidden power of
                    industry specific master data
                  </Typography>
                  <Typography className="AIBlock-sec">
                    Harness the power of Kratex AI, <br />
                    leveraging Prod IQ's comprehensive data to <br />
                    revolutionize your construction projects<br />
                    with standardization of material library and <br />
                    with more than 150 data points associated<br />
                    with a unique product.
                  </Typography>
                  <button className="AIBlock-btn">
                    Request Access
                  </button>
                </div>
                <img src={require("../assets/secondAi.png")} alt="ai" className="AIBlockContainer-img"/>
              </Grid>
            </Grid>
          : ""}
          {this.state.page == 4 ? 
            <Grid container justifyContent="center" direction="column" className="procureTech">
              <Grid item sm={12} md={12} lg={12} className="mobileWidth">
                <Typography className="revProdLabel1 mobileWidth">
                  Elevate Your Procurement with
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={12} className="mobileWidth">
                <Typography className="efficientPIM mobileWidth">
                  ProcureTech AI
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={12} className="procureSecondaryContainer">
                <Typography className="procureSecondary">
                  Step into the future of procurement with ProcureTech, 
                  the ultimate solution designed to streamline and automate 
                  your entire procurement process. Achieve Unmatched Efficiency, 
                  Control, and Savings with Intelligent Automation.<br />
                  Search, discover, sample, quote and purchase from over hundreds 
                  of suppliers - 
                  <span style={{fontWeight:600, color:"#323743"}}> all in one place.</span> 
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={12} justifyContent="center" className="mobileWidth">
                <Grid container direction="row" className="procureContainer">
                  <Grid item sm={10} md={6} lg={6} className="procureImgContainer">
                    <img src={require("../assets/proc.svg")} className="procureImg" />
                  </Grid>
                  <Grid item sm={10} md={4} lg={4} className="procureTextContainer">
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className="procureTextContainer_main">
                          Search. 
                        </Typography>
                        <Typography className="procureTextContainer_secondary">
                          Leverage AI-driven precision to refine your search, 
                          ensuring you quickly find the most suitable materials. 
                          Utilize comprehensive filters to narrow down your options 
                          based on detailed specifications, preferred brands, and necessary certifications. 
                          Experience the efficiency of instant results tailored to meet your project's specific needs, 
                          enabling you to make informed decisions with speed and confidence.
                        </Typography>
                        <Typography className="procureTextContainer_main textOrange">
                          Discover. 
                        </Typography>
                        <Typography className="procureTextContainer_secondary">
                          Effortless Discovery: Explore a wide range of building materials with ease. 
                          Dive into our extensive catalog, featuring a vast selection of materials 
                          from hundreds of top suppliers. Access detailed product information and 
                          customer reviews to make informed decisions. Benefit from AI-driven recommendations 
                          that provide personalized suggestions based on your unique preferences and needs. 
                          Discover the perfect materials for your projects with Kratex today.
                        </Typography>
                        <Typography className="procureTextContainer_main">
                          Sample. 
                        </Typography>
                        <Typography className="procureTextContainer_secondary">
                          Test Before You Invest: Easily request and track samples to ensure quality. 
                          With one-click sample requests, you can order samples directly from product pages, 
                          allowing you to test materials before making a commitment. 
                          Customers can also request quotes or view Technical Data Sheets (TDS) 
                          and Safety Data Sheets (SDS) with just a few clicks, ensuring you have 
                          all the information needed to make informed decisions. 
                          Experience seamless, hassle-free procurement with Kratex.
                        </Typography>
                        <Typography className="procureTextContainer_main textOrange">
                          Request. 
                        </Typography>
                        <Typography className="procureTextContainer_secondary">
                          Experience the convenience of our Quick Quote and Upload RFQ features 
                          to streamline your procurement process. 
                          Easily request quotations for your desired items by simply uploading 
                          your RFQ documents or using our Quick Quote tool. 
                          Receive instant, competitive quotations tailored to your specific requirements, 
                          ensuring you get the best deals without the wait. 
                          With Kratex, getting accurate and timely quotes for your projects has never been easier.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box className="graySeparator"></Box>
              <Grid item sm={12} md={12} lg={12} className="mobileWidth">
                <Typography className="efficientPIM mobileWidth">
                  Approve. <span className="textOrange">Automate.</span>  Process.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={12} className="procureTextContainer mobileWidth">
                <Typography className="apprAutoProcSecondary mobileWidth">
                  Workflow Automation: Eliminate manual tasks, 
                  reduce errors, and increase efficiency.
                </Typography>
              </Grid>
              <Grid item sm={10} md={12} lg={12} className="automateArg mobileMargin">
                <Grid container direction="row" style={{margin:"40px 0px"}} className="mobileWidth mobileMargin">
                  <Grid item sm={12} md={6} lg={6} className="approveImgContainer">
                    <img src={require("../assets/approval.svg")} className="approveImg" />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} className="approveTextContainer mobileWidth" direction="column">
                    <Typography className="approveText_main">
                      Implement  <span className="textOrange">Approval</span> Workflows for increased transparency
                    </Typography>
                    <Typography className="approveText_sec">
                      Never postpone an order or miss out on a supplier discount 
                      due to delayed approval with KrateX. 
                      This platform allows you to automatically route purchase orders and invoices 
                      to the right decision-maker based on various conditions, such as location, 
                      department, project, or amount threshold. By ensuring timely approvals, 
                      KrateX helps maintain smooth operations and capitalizes on supplier discounts.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{margin:"40px 0px"}} className="mobileWidth mobileMargin">
                  <Grid item sm={12} md={6} lg={6} className="approveTextContainer mobileWidth" direction="column">
                    <Typography className="approveText_main">
                      <span className="textOrange">Automate</span> Purchase Orders for faster Order Processing 
                    </Typography>
                    <Typography className="approveText_sec mobileMargin">
                      Streamline your order processing with KrateX, 
                      where Purchase Orders (POs) are automatically sent to suppliers as soon 
                      as they are released. This automation accelerates the entire order management process, 
                      reducing delays and improving operational efficiency, 
                      ensuring that your orders are processed faster and more efficiently.
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} className="approveImgContainer">
                    <img src={require("../assets/purchase.svg")} className="approveImg" />
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{margin:"80px 0px"}} className="mobileWidth mobileMargin">
                  <Grid item sm={12} md={6} lg={6} className="approveImgContainer">
                    <img src={require("../assets/smarter.svg")} className="approveImg" />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} className="approveTextContainer" direction="column">
                    <Typography className="approveText_main mobileMargin">
                      <span className="textOrange">Smarter scheduling</span> for your Purchase Orders
                    </Typography>
                    <Typography className="approveText_sec">
                      One Click Order Scheduling with PO Subscribe feature, 
                      simplifies the process for customers to set up recurring orders and deliveries. 
                      With this feature, customers can create Blanket Purchase Order (PO) 
                      requests that include a detailed schedule specifying the number of deliveries
                      and the quantity of products in each delivery. Through an intuitive interface, 
                      users can easily subscribe to a PO, set the start and end dates or the number 
                      of deliveries, and define the frequency and quantity of each delivery. 
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box className="graySeparator"></Box>
                <Grid item sm={12} md={12} lg={12} className="mobileWidth">
                  <Typography className="controlStatusText mobileWidth">
                    Control the status of each Quotation, Purchase Order, Invoice, 
                    Delivery Challan to ensure on-time delivery, 
                    realtime so your business operations run smoothly
                  </Typography>
                  <div className="marqueeWrap">
                    <Marquee direction="left" data-test-id="marquee">
                      <img src={require("../assets/sentSupp1.png")} className="stasusImg" />
                      <img src={require("../assets/draft1.png")} className="stasusImg" />
                      <img src={require("../assets/pending1.png")} className="stasusImg" />
                      <img src={require("../assets/received1.png")} className="stasusImg" />
                      <img src={require("../assets/rejected1.png")} className="stasusImg" />
                      <img src={require("../assets/inRevision1.png")} className="stasusImg" />
                      <img src={require("../assets/completed1.png")} className="stasusImg" />
                      <img src={require("../assets/sentSupp1.png")} className="stasusImg" />
                      <img src={require("../assets/draft1.png")} className="stasusImg" />
                      <img src={require("../assets/pending1.png")} className="stasusImg" />
                      <img src={require("../assets/received1.png")} className="stasusImg" />
                      <img src={require("../assets/rejected1.png")} className="stasusImg" />
                      <img src={require("../assets/inRevision1.png")} className="stasusImg" />
                      <img src={require("../assets/completed1.png")} className="stasusImg" />
                    </Marquee>  
                    <Marquee direction="right" style={{marginTop:"20px"}} data-test-id="marquee"> 
                      <div className="confirmed-status">
                        <Typography className="confirmed-status-text">
                          Confirmed
                        </Typography>
                      </div>   
                      <img src={require("../assets/processed2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/cancelled2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/notReceived2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/notBilled2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/paid2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/approved2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/notSent2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/amendRejected2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <div className="confirmed-status">
                        <Typography className="confirmed-status-text">
                          Confirmed
                        </Typography>
                      </div>  
                      <img src={require("../assets/processed2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/cancelled2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/notReceived2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/notBilled2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/paid2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/approved2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/notSent2.png")} className="stasusImg" data-test-id="marquee-img"/>
                      <img src={require("../assets/amendRejected2.png")} className="stasusImg" data-test-id="marquee-img"/>                       
                    </Marquee>  
                  </div>              
                </Grid>
              <Box className="graySeparator"></Box>
              <Grid item sm={12} md={12} lg={12} className="mobileWidth" data-test-id="customizeText">
                <Typography className="efficientPIM mobileWidth">
                Customize. <span className="textOrange">Analyze.</span>  Optimize.
                </Typography>
              </Grid >
              <Grid item sm={12} md={12} lg={12} className="procureTextContainer mobileWidth">
                <Typography className="apprAutoProcSecondary mobileWidth">
                  Actionable Insights: Gain full visibility with real-time analytics and reporting.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={12} className="automateArg mobileMargin">
                <Grid container direction="row" style={{marginTop:"70px", marginBottom:"160px"}} className="mobileMargin">
                  <Grid item sm={12} md={6} lg={6} className="approveImgContainer">
                    <img src={require("../assets/customReports.svg")} className="approveImg" />
                  </Grid>
                  <Grid item sm={12} md={4} lg={4} className="approveTextContainer mobileMargin" direction="column">
                    <Typography className="approveText_main mobileMargin">
                      <span className="textOrange">Custom</span> reports for your company data
                    </Typography>
                    <Typography className="customText_sec mobileMargin">
                      Effortlessly Build Tailored Reports Using Any Data Points to Make Informed Decisions. 
                      Optimize your insights with our intelligent, 
                      automated solution designed for precision and efficiency. 
                      Empower your team with real-time, comprehensive analytics that streamline your 
                      decision-making process..
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
          : ""}
          <Footer />
        </PlatformBlock>
    );
  
  }
}
