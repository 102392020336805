import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
interface DocumentDetails {
  gstin_number: string;
  pdf: string;
}

interface AddressData {
  country: string;
  state: string;
  zipcode: number;
  biling_address: string;
  street: string;
  city: string;
}

interface BankDetailsData {
  account_number: string;
  branch_name: string;
  bank_name: string;
  ifsc_code: string;
  beneficiary_name: string;
}

export interface CompanyInformationData {
  id: string;
  type: string;
  attributes: {
      full_phone_number: string;
      contact_name: string;
      company_name: string;
      company_type: string;
      email: string;
      document_details: DocumentDetails;
      address: AddressData;
      account_id: number;
      bank_details: BankDetailsData;
  };
}

interface Project {
  id: string;
  type: string;
  attributes: ProjectAttributes;
}

interface ProjectAttributes {
  name: string;
  company_information_id: number;
  rera: null | string; 
  contact_person: null | string; 
  contact_number: string;
  biling_address: AddressProject;
  delivery_address: DeliveryAddress;
}

interface AddressProject {
  biling_address: null;
  street: null;
  city: null;
  country: null;
  state: null;
  zipcode: null;
  addr_type: null;
}

interface DeliveryAddress {
  biling_address: null;
  street: null;
  city: null;
  country: null;
  state: null;
  zipcode: null;
  addr_type: null;
  delivery_point: null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
// Customizable Area Start
showComData: boolean;
showProjectData: boolean;
isEdit: boolean;
value: number;
openDialog: boolean;
showFormData: boolean;
userId: number;
billAdd: {street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string};
deliveryAdd:{phonenumber: string, contact: string, street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string, delivery_point:string};
companyNamesList:{id:number,companyName:string}[];
selectedCompany: {id:number | null,companyName:string};
projectName: string;
projectNameList: {id:number,projectName:string}[];
selectProjectName: {id:number | null,projectName:string};
selectedDropCompany: {id:number | null,companyName:string};
fieldRequiredData: string;
reraData: string;
deliveryAddress: number;
bilingAddress: number
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyInfo: string = "";
  getProjectnfo: string = "";
  createProject: string = "";
  getSpecificProjectnfo: string = "";
  updateProjectData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
    //   txtInputValue: "",
    //   txtSavedValue: "A",
    //   enableField: false,
      
    // Customizable Area Start
    showComData: false,
    showProjectData: false,
    isEdit: false,
    value: 0,
    openDialog: false,
    showFormData: false,
    userId: 0,
    billAdd: {street: "", zipcode: "", selectedOption: "", selectedStateOption: "", selectedCountryOption: ""},
    deliveryAdd:{phonenumber: "", contact:"",street: "", zipcode: "", selectedOption: "", selectedStateOption: "", selectedCountryOption: "",delivery_point:""},
    companyNamesList:[],
    selectedCompany: {id: null,companyName:""},
    projectName: "",
    projectNameList:[],
    selectProjectName: {id: null,projectName:""},
    selectedDropCompany: {id: null,companyName:""},
    reraData: "",
    fieldRequiredData:"",
    deliveryAddress: 1,
    bilingAddress: 0
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    const dataUser = await getStorageData('user-id');

    const number = parseInt(dataUser)
    this.setState({ userId: number}) 
    this.getAllCompanyInformation();
    this.getAllProjectInformation();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if(responseJson && !responseJson.errors){
        switch (apiRequestCallId) {
          case this.getProjectnfo:
              const projectNames = responseJson.Project.data.map((project: { id: string, attributes: { name: string } }) => ({
                id: project.id,
                projectName: project.attributes.name
              }));

              this.setState({ projectNameList: projectNames, selectProjectName:projectNames[0]}, () => {
                this.getSpecificProjectInformation()
              });
            break;
        
          case this.createProject:
              this.getAllProjectInformation();
            break;

          case this.getSpecificProjectnfo:
            const billingAdd = {
              street: responseJson.project?.data?.attributes.biling_address.street,
              selectedOption: responseJson.project?.data.attributes.biling_address.city,
              selectedStateOption: responseJson.project?.data.attributes.biling_address.state,
              selectedCountryOption:responseJson.project?.data.attributes.biling_address.country,
              zipcode: responseJson.project?.data.attributes.biling_address.zipcode
            }

            const deliveryAdd = {
              street: responseJson.project?.data.attributes.delivery_address.street,
              selectedOption: responseJson.project?.data.attributes.delivery_address.city,
              selectedStateOption: responseJson.project?.data.attributes.delivery_address.state,
              selectedCountryOption: responseJson.project?.data.attributes.delivery_address.country,
              zipcode: responseJson.project?.data.attributes.delivery_address.zipcode,
              delivery_point: responseJson.project?.data.attributes.delivery_address.delivery_point,
              phonenumber: responseJson.project?.data.attributes.delivery_address.contact_number,
              contact:responseJson.project?.data.attributes.delivery_address.contact_person
            }

            const reraData = responseJson.project.data.attributes.rera
            this.setState({ billAdd: billingAdd, deliveryAdd: deliveryAdd, reraData: reraData})
            break;  

          case this.updateProjectData:
            break;  
        
          default:
              const companyData = responseJson.CompanyInformations?.data.map((item: CompanyInformationData) => ({
                id: item.id,
                companyName: item.attributes?.company_name
              }));
              this.setState({ companyNamesList: companyData });
            break;
        }
      }
    }
    // Customizable Area End
  }

 

  // Customizable Area Start

    getAllCompanyInformation = async () => {
    const getCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyInfo = getCompanyMsg.messageId;
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/company_informations`
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
      })
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCompanyApiMethodType
    );
    runEngine.sendMessage(getCompanyMsg.id, getCompanyMsg);
  };

  getAllProjectInformation = async () => {  
    const currenProjectId = this.state.selectedDropCompany?.id ?? '';
    const getCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectnfo = getCompanyMsg.messageId;
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/projects?company_information_id=${currenProjectId}`
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
      })
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCompanyApiMethodType
    );
    runEngine.sendMessage(getCompanyMsg.id, getCompanyMsg);
  };

  getSpecificProjectInformation = async () => { 
    const currenProjectId = this.state.selectProjectName?.id ?? '';
    const getCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpecificProjectnfo = getCompanyMsg.messageId;
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/projects/${currenProjectId}`
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
      })
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCompanyApiMethodType
    );
    runEngine.sendMessage(getCompanyMsg.id, getCompanyMsg);
  };


  createNewProject = async () => {

    const payload = {
      "name": this.state.projectName,
      "company_information_id": Number(this.state.selectedCompany.id)
    }

    let tokenData = await getStorageData("otp-token")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenData
    };
    const createCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProject = createCompanyMsg.messageId;
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/projects`
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));
    runEngine.sendMessage(createCompanyMsg.id, createCompanyMsg);
  };

  updateProject = async () => {
    const payload = {
      "data": {
        "attributes": {
          // "name": "Updated Project",
          "rera": this.state.reraData
        },
        "biling_address_attributes": {
          "country": this.state.billAdd.selectedOption,
          "state": this.state.billAdd.selectedStateOption,
          "street": this.state.billAdd.street,
          "city": this.state.billAdd.selectedOption,
          "zipcode": this.state.billAdd.zipcode,
          "addr_type": this.state.bilingAddress
        },
        "delivery_address_attributes": {
          "country": this.state.deliveryAdd.selectedCountryOption,
          "state": this.state.deliveryAdd.selectedStateOption,
          "street":  this.state.deliveryAdd.street,
          "city": this.state.deliveryAdd.selectedOption,
          "zipcode": this.state.deliveryAdd.zipcode,
          "addr_type": this.state.deliveryAddress,
          "delivery_point": this.state.deliveryAdd.delivery_point,
          "contact_number": this.state.deliveryAdd.phonenumber,
          "contact_person": this.state.deliveryAdd.contact
        }
      }
    }
    

    let tokenData = await getStorageData("otp-token")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenData
    };
    const createCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProjectData = createCompanyMsg.messageId;
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/projects/${this.state.projectNameList[0].id}`
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));
    runEngine.sendMessage(createCompanyMsg.id, createCompanyMsg);
  };

  handleBillingAddValidation = () => {
    let message = "Required field";
      if(this.state.billAdd.selectedStateOption || this.state.deliveryAdd.selectedStateOption){ 
        this.setState({fieldRequiredData: ""})
      }
      else{
        this.setState({fieldRequiredData: message})
      }
  }   

  handleOpen = () => {
    this.setState({ showComData: !this.state.showComData })
  }

  handleProjectOpen = () => {
    this.setState({ showProjectData: !this.state.showProjectData })
  }

  handleEdit = () => {
    this.setState({ isEdit: true})
  }

  handleChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    this.setState({ value: newValue})
  };

  handleClose = () => {
    this.setState({ openDialog: false})
  };

  handleComOpen = () => {
    this.setState({ openDialog: true});
    this.getAllCompanyInformation()
  };

  handleFormOpen = () => {
    this.setState({ showFormData: !this.state.showFormData })
  }

  selectComName = (companyData:{id:number,companyName:string}) => {
    this.setState({ selectedCompany: companyData, showFormData: false});
  }

  selectComNameDrop = (companyData:{id:number,companyName:string}) => {
    this.setState({ selectedDropCompany: companyData, showComData: false}, () => {
      this.getAllProjectInformation()
    });
  }

  handleProjectChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({projectName: event.target.value});
  } 

  hadleAddProject = () => {
    this.createNewProject();
    this.setState({ openDialog: false})
  }

  selectProjectName = (projectData:{id:number,projectName:string}) => {
    this.setState({ selectProjectName: projectData, showProjectData: false}, () => {
      this.getSpecificProjectInformation();
    })
  }

  handleBillingBuyerState = (addressData:{street: "", zipcode: "", selectedOption: "", selectedStateOption: "", selectedCountryOption: ""}) => {
    this.setState({...this.state, billAdd:addressData})
  }

  handleDeliveryBuyerState = (deliveryData:{phonenumber: "", contact: "", street: "", zipcode: "", selectedOption: "", selectedStateOption: "", selectedCountryOption: "",delivery_point:""}) => {
    this.setState({...this.state, deliveryAdd:deliveryData})
  }

  handleReraBuyerState = (rera: string) => {
    this.setState({ reraData: rera})
  }

  handleSaveProject = () => {
    if(this.state.billAdd.selectedStateOption || this.state.deliveryAdd.selectedStateOption){ 
      this.updateProject();
      this.setState({ isEdit: false})
    }
    else{
      this.handleBillingAddValidation()
    }
  }
  // Customizable Area End
}
