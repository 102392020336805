import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  reraData: string;
  passState: Function
  // Customizable Area End
}

interface S {
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
    // Customizable Area Start
    rera:string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReraBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    //   txtInputValue: "",
    //   txtSavedValue: "A",
    //   enableField: false,
      
            // Customizable Area Start
      rera: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
        this.setState({ rera: this.props.reraData})
  }

 

  // Customizable Area Start

  handleReraChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({rera:event.target.value});
    this.props.passState({ rera:event.target.value});
  }
  // Customizable Area End
}
