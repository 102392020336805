import React from "react";
// Customizable Area Start
import { Box } from '@material-ui/core';
import SideBar from "../../../components/src/SideBar.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainer
      
        <Box component="nav" style={{ backgroundColor: "#fff" }}>
          <SideBar navigation={this.props.navigation} >
            <></>
            </SideBar>         
        </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
