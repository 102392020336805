//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import Check from "@material-ui/icons/Check";
import ArrowRight from "@material-ui/icons/ArrowRight";
import BoltIcon from '@material-ui/icons/OfflineBoltOutlined';
import AddShopping from '@material-ui/icons/AddShoppingCartOutlined';
import INbox from '@material-ui/icons/InboxOutlined';
import History from '@material-ui/icons/HistoryOutlined';
import Planet from '@material-ui/icons/PublicOutlined';
import Truck from '@material-ui/icons/LocalShippingOutlined';
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import {parcel} from "./assets"

export const ProductBlock = styled("div")(({ theme }) => ({
  "& .tableEye":{
    width:"12px"
  },
  "& .viewText":{
    fontWeight:400, 
    fontSize:"12px",
    display:"flex",
    cursor:"pointer"
  },
  "& .variationTh":{
    whiteSpace:"nowrap",
    padding:"15px"
  },
  "& .mainPhoto-container":{
    position:"relative",
    width:"386px",
    "@media (max-width:1200px)": {
      width:"100%",
    },
    "@media (max-width:900px)": {
      width:"90vw",
      margin:"0 auto",
      display:"flex",
      justifyContent:"center"
    },
  },
  "& .mainPhoto__zoom":{
    position:"absolute",
    right:"11px",
    bottom:'11px',
    cursor:"pointer"
  },
  "& .currentWay":{
    fontWeight:400,
  },
  "& .globalContainer":{
    padding: "0px 90px",
    "@media (max-width:1200px)": {
      padding: "0px 20px",
    },
    "@media (max-width:900px)": {
      padding: "0px 10px",
    },
  },
  "& .navMenu":{
    marginLeft:"90px",
    "@media (max-width:1200px)": {
      marginLeft:"0px",
      padding:"0px 20px"
    },
    "@media (max-width:900px)": {
      marginLeft:"0px",
      padding:"0px 10px"
    },
  },
  "& .notMainPhoto-container":{
    width:'386px',
    "@media (max-width:1200px)": {
      width: "100%"
    },
    "@media (max-width:900px)": {
      width:'386px'
    },
  },
  "& .notMainPhoto":{
    width: "90px",
    cursor:"pointer",
    "@media (max-width:1200px)": {
      width: "60px"
    },
    "@media (max-width:900px)": {
      width: "70px"
    },
  },
  "& .MuiGrid-grid-md-2":{
    flexBasis:"auto"
  },
  "& .documentsTable":{
    width:"386px",
    "@media (max-width:1200px)": {
      width:"90%",
    },
    "@media (max-width:900px)": {
      width:"80vw",
    },
  },
  "& .featureTableText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#9095A1"
  },
  "& .Variations__mainText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 700,
    color:"#323743"
  },
  "& .Variations__text":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    color:"#171A1F",
    lineHeight:"26px"
  },
  "& .MuiCheckbox-root":{
    color: "#565D6D",
    '&.Mui-checked': {
      color: "#E55C00",
    },
  },
  "& .brandTableBody":{
    display: 'block',
    height:"160px",
    overflowY:"scroll",
    scrollbarColor:"#BDC1CA transparent",
    scrollbarWidth:"thin"
  },
  "& .brandTableBody::-webkit-scrollbar-track":{
    backgroundColor:"blue"
  },
  "& .brandTableHead":{
    display:'block'
  },
  "& .tableBrand-Brand":{
    width:"90px",
    padding:"15px",
    textAlign:"center"
  },
  "& .tableBrand-Name":{
    width:"150px",
    padding:"15px",
    textAlign:"center",
    whiteSpace:"nowrap"
  },
  "& .tableBrand-SKU":{
    width:"115px",
    textAlign:"center"
  },
  "& .tableBrand-arrow":{
    width:"50px",
    textAlign:"center",
    cursor:"pointer"
  },
  "& .tableBrand-checkbox":{
    width:"45px",
    textAlign:"center"
  },
  "& .quote":{
    marginLeft:"50px",
    "@media (max-width:1200px)": {
      marginLeft:"5px"
    },
    "@media (max-width:900px)": {
      marginTop:"20px",
      marginLeft:"0px"
    },
  },
  "& .request":{
    marginLeft:"50px",
    "@media (max-width:1200px)": {
      marginLeft:"5px"
    },
    "@media (max-width:900px)": {
      marginLeft:"0px"
    },
  },
  "& .requestSample":{
    fontSize: "16px",
    fontWeight: 700,
    color:"#323743",
    fontFamily: 'Epilogue, sans-serif',
  },
  "& .noteText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 400,
    color:"#9095A1",
    lineHeight:"26px",
    fontStyle:"italic"
  },
  "& .noteTextMain":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 400,
    color:"#323743",
    lineHeight:"26px",
    fontStyle:"normal"
  },
  "& .mainTextDescription":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#171A1F",
    lineHeight:"24px",
  },
  "& .seccondaryTextDescription":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    color:"#565D6D",
    lineHeight:"24px",
  },
  "& .specificationMainText":{
    fontFamily: 'Inter, sans-serif',
    fontSize: "24px",
    fontWeight: 600,
    color:"#181A1E",
    lineHeight:"30px",
    marginBottom:"24px"
  },
  "& .specTableRow:nth-child(odd)":{
    backgroundColor:"#FAFAFB"
  },
  "& .certTable":{
    width:"100%",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    lineHeight:"20px",
    color:"#1E293B",
    marginBottom:"20px",
    border:"1px solid #DEDFE3",
    overflow:"hidden",
    borderRadius:"8px"
  },
  "& .specTableRow__td-text":{
    width:"90%",
    padding:"20px"
  },
  "& .gridTableSpec":{
    "@media (min-width:900px)": {
      maxWidth:"48%",
      flexBasis:"48%"
    },
  },
  "& .specTableRow__td-eye":{
    width:"90%",
    padding:"20px"
  },
  "& .certTableRow:nth-child(odd)":{
    backgroundColor:"#FAFAFB"
  },
  "& .certificationEye":{
    cursor:"pointer"
  },
  "& .quantityNumberArrow":{
    cursor:"pointer"
  }
}))
const theme = createTheme({
  cursor:"default",
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const dummySpec = [
  {key: "Category", value: "Power Tools"},
  {key: "Sub - Category", value: "Professional Router"},
  {key: "Item Group", value: "Item Group"},
  {key: "Product Type", value: "Product Type"},
  {key: "HSN/SAC", value: "HSN/SAC"},
  {key: "Category", value: "Power Tools"},
  {key: "Sub - Category", value: "Professional Router"},
  {key: "Item Group", value: "Item Group"},
  {key: "Product Type", value: "Product Type"},
]
const dummyCertificate = ["Federal Communications Commission (FCC)", "Industry Canada (IC)",
"RoHS Compliance", "WEEE Compliance", "UL Listing (USA)", "CE Marking (Europe)",
"Energy Star (USA)", "ETL Listed Mark (USA)", "ISO 9001 (Quality Management)"
]
const dummyBrandTable = [
  {
    brand:"Finolex",
    name:"ELBOW 90° PLAIN",
    sku:"2025000"
  },
  {
    brand:"Paras",
    name:"Elbow 90 Plain",
    sku:"675427846"
  },
  {
    brand:"Astral",
    name:"Elbow 90 Plain",
    sku:"675427846"
  },
  {
    brand:"Finolex",
    name:"ELBOW 100 PLAIN",
    sku:"2025800"
  },
  {
    brand:"Paraska",
    name:"Elbow 98 Plain",
    sku:"675427246"
  },
  {
    brand:"Astrall",
    name:"Elbow 92 Plain",
    sku:"675427841"
  }
]
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.dummyImg = [dummyProduct, dummyProduct, dummyProduct, dummyProduct];
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPostData();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <ProductBlock>
        <Navbar history={this.props.history} handleNavigate={() => this.handleNavigate()}/>
        <Grid container direction="row" alignItems="center" style={webStyle.navMenu} className="navMenu" justifyContent="flex-start">
          <Grid item style={webStyle.navMenuItem} id="navigation"  xs={4} sm={4} md={2}>
            <span>Home</span>   
            <ArrowForward />        
          </Grid>
          
          <Grid item style={webStyle.navMenuItem} id="navigation" xs={4} sm={4} md={2}>
            <span>Power & Tools</span>  
            <ArrowForward />          
          </Grid>  
               
          <Grid item style={webStyle.navMenuItem} id="navigation" xs={4} sm={4} md={2}>
            <span>Power Tools</span> 
            <ArrowForward />          
          </Grid>
          
          <Grid item style={webStyle.navMenuItem} id="navigation" className="currentWay" xs={4} sm={4} md={1}>
            <span>Router</span>
          </Grid>
         
          
        </Grid>
        <Grid container style={webStyle.globalContainer} className="globalContainer">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Grid container className="products-photos" direction="column">
              <Grid item className="main-photo">
                
                <Grid container className="slider" style={{width:"25vw"}} direction="column" justifyContent="center">
                  <Grid item className="mainPhoto-container">
                    <img
                    src={this.dummyImg[this.state.focusedImageIndex]}
                    alt={`Product`}
                    style={webStyle.mainPhoto}
                    id="mainPhoto"
                    />
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="mainPhoto__zoom">
                      <path d="M6.28516 9.71484L2.28516 13.7148" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10"/>
                      <path d="M13.7148 2.28516L9.71484 6.28516" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10"/>
                      <path d="M9.71484 9.71484L13.7148 13.7148" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10"/>
                      <path d="M2.28516 2.28516L6.28516 6.28516" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10"/>
                      <path d="M10.2852 2.28516L13.7137 2.28516V5.71373" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                      <path d="M5.71373 13.7137H2.28516L2.28516 10.2852" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                      <path d="M2.28516 5.71373L2.28516 2.28516L5.71373 2.28516" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                      <path d="M13.7137 10.2852V13.7137H10.2852" stroke="#9095A1" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                    </svg>

                  </Grid>
                  <Grid item style={{margin:"20px 0"}}>
                    <Grid container direction="row" className="notMainPhoto-container" justifyContent="space-around">
                    {this.dummyImg.map((item, index)=>{
                      return(
                        <img
                        src={item}
                        alt={`Product`}
                        id="carousel"
                        style={webStyle.notMainPhoto}
                        className="notMainPhoto"
                        onClick={()=>{
                          this.setState({focusedImageIndex: index})
                        }}  
                      />
                      )
                    })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="view-documents">
                <Typography style={webStyle.documentsText}>View Documents</Typography>
                <table style={webStyle.documentsTable} cellPadding={0} cellSpacing={0} className="documentsTable">
                  <tr>
                    <td style={webStyle.tableTd}>Technical Data Sheets (TDS)</td>
                    <td style={webStyle.tableTd} className="viewText">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="tableEye">
                    <path d="M1.44487 6.70901C1.34091 6.56038 1.28516 6.38339 1.28516 6.20201C1.28516 6.02063 1.34091 5.84364 1.44487 5.69501C2.11901 4.74058 3.77887 2.77344 5.9993 2.77344C8.21973 2.77344 9.87959 4.74058 10.5537 5.69501C10.6577 5.84364 10.7134 6.02063 10.7134 6.20201C10.7134 6.38339 10.6577 6.56038 10.5537 6.70901C9.87959 7.66344 8.21973 9.63058 5.9993 9.63058C3.77887 9.63058 2.11901 7.66344 1.44487 6.70901Z" stroke="#323743" stroke-width="1.02857" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M5.99944 7.91685C6.94622 7.91685 7.71373 7.14934 7.71373 6.20257C7.71373 5.25579 6.94622 4.48828 5.99944 4.48828C5.05267 4.48828 4.28516 5.25579 4.28516 6.20257C4.28516 7.14934 5.05267 7.91685 5.99944 7.91685Z" stroke="#323743" stroke-width="1.02857" stroke-miterlimit="10" stroke-linecap="square"/>
                    </svg>
                    View
                    </td>
                  </tr>
                  <tr>
                    <td style={webStyle.tableTd}>Brochure</td>
                    <td style={webStyle.tableTd} className="viewText">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="tableEye">
                    <path d="M1.44487 6.70901C1.34091 6.56038 1.28516 6.38339 1.28516 6.20201C1.28516 6.02063 1.34091 5.84364 1.44487 5.69501C2.11901 4.74058 3.77887 2.77344 5.9993 2.77344C8.21973 2.77344 9.87959 4.74058 10.5537 5.69501C10.6577 5.84364 10.7134 6.02063 10.7134 6.20201C10.7134 6.38339 10.6577 6.56038 10.5537 6.70901C9.87959 7.66344 8.21973 9.63058 5.9993 9.63058C3.77887 9.63058 2.11901 7.66344 1.44487 6.70901Z" stroke="#323743" stroke-width="1.02857" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M5.99944 7.91685C6.94622 7.91685 7.71373 7.14934 7.71373 6.20257C7.71373 5.25579 6.94622 4.48828 5.99944 4.48828C5.05267 4.48828 4.28516 5.25579 4.28516 6.20257C4.28516 7.14934 5.05267 7.91685 5.99944 7.91685Z" stroke="#323743" stroke-width="1.02857" stroke-miterlimit="10" stroke-linecap="square"/>
                    </svg>
                    View
                    </td>
                  </tr>
                </table>
              </Grid>
              
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={webStyle.secondPart}>
              <Grid container className="NameVariations" direction="row">
                <Grid item className="NameFeature" direction="row">
                  <h2 style={webStyle.h2}>90° UPVC Elbow SOC SCH 80</h2>
                  <span style={webStyle.h4}><span style={webStyle.h4Main}>Description:</span> uPVC fitting used to give a 90 turn to a pipeline.</span>
                  <h4 style={webStyle.h4}><span style={webStyle.h4Main}>UPID:</span> XXXXXXXXXX</h4>
                  <h4 style={webStyle.h4}><span style={webStyle.h4Main}>Selected Brand:</span> Astral</h4>
                  <Grid container className="Feature" direction="column">
                    <h3 style={webStyle.h3}>Features</h3>
                    <table style={webStyle.featureLabel}>
                      <tr>
                        <td style={{display:"flex", alignItems:"center"}}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.13443 9.27765L3.09276 7.23598C2.98493 7.12681 2.83787 7.06536 2.68443 7.06536C2.53098 7.06536 2.38393 7.12681 2.27609 7.23598C2.04859 7.46348 2.04859 7.82514 2.27609 8.05264L4.72026 10.4968C4.94776 10.7243 5.31526 10.7243 5.54276 10.4968L11.7261 4.31931C11.9536 4.09181 11.9536 3.73015 11.7261 3.50265C11.6183 3.39348 11.4712 3.33203 11.3178 3.33203C11.1643 3.33203 11.0173 3.39348 10.9094 3.50265L5.13443 9.27765Z" fill="#C6CAD5"/>
                          </svg>
                          <Typography className="featureTableText">
                            Brand Name :
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{display:"flex", alignItems:"center"}}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.13443 9.27765L3.09276 7.23598C2.98493 7.12681 2.83787 7.06536 2.68443 7.06536C2.53098 7.06536 2.38393 7.12681 2.27609 7.23598C2.04859 7.46348 2.04859 7.82514 2.27609 8.05264L4.72026 10.4968C4.94776 10.7243 5.31526 10.7243 5.54276 10.4968L11.7261 4.31931C11.9536 4.09181 11.9536 3.73015 11.7261 3.50265C11.6183 3.39348 11.4712 3.33203 11.3178 3.33203C11.1643 3.33203 11.0173 3.39348 10.9094 3.50265L5.13443 9.27765Z" fill="#C6CAD5"/>
                          </svg>
                          <Typography className="featureTableText">
                            GSM :
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{display:"flex", alignItems:"center"}}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.13443 9.27765L3.09276 7.23598C2.98493 7.12681 2.83787 7.06536 2.68443 7.06536C2.53098 7.06536 2.38393 7.12681 2.27609 7.23598C2.04859 7.46348 2.04859 7.82514 2.27609 8.05264L4.72026 10.4968C4.94776 10.7243 5.31526 10.7243 5.54276 10.4968L11.7261 4.31931C11.9536 4.09181 11.9536 3.73015 11.7261 3.50265C11.6183 3.39348 11.4712 3.33203 11.3178 3.33203C11.1643 3.33203 11.0173 3.39348 10.9094 3.50265L5.13443 9.27765Z" fill="#C6CAD5"/>
                          </svg>
                          <Typography className="featureTableText">
                            No. of Sheets :
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{display:"flex", alignItems:"center"}}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.13443 9.27765L3.09276 7.23598C2.98493 7.12681 2.83787 7.06536 2.68443 7.06536C2.53098 7.06536 2.38393 7.12681 2.27609 7.23598C2.04859 7.46348 2.04859 7.82514 2.27609 8.05264L4.72026 10.4968C4.94776 10.7243 5.31526 10.7243 5.54276 10.4968L11.7261 4.31931C11.9536 4.09181 11.9536 3.73015 11.7261 3.50265C11.6183 3.39348 11.4712 3.33203 11.3178 3.33203C11.1643 3.33203 11.0173 3.39348 10.9094 3.50265L5.13443 9.27765Z" fill="#C6CAD5"/>
                          </svg>
                          <Typography className="featureTableText">
                            No of Ream :
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{display:"flex", alignItems:"center"}}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.13443 9.27765L3.09276 7.23598C2.98493 7.12681 2.83787 7.06536 2.68443 7.06536C2.53098 7.06536 2.38393 7.12681 2.27609 7.23598C2.04859 7.46348 2.04859 7.82514 2.27609 8.05264L4.72026 10.4968C4.94776 10.7243 5.31526 10.7243 5.54276 10.4968L11.7261 4.31931C11.9536 4.09181 11.9536 3.73015 11.7261 3.50265C11.6183 3.39348 11.4712 3.33203 11.3178 3.33203C11.1643 3.33203 11.0173 3.39348 10.9094 3.50265L5.13443 9.27765Z" fill="#C6CAD5"/>
                          </svg>
                          <Typography className="featureTableText">
                            Ideal For :
                          </Typography>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className="Variations" direction="column" style={{marginTop:"24px"}}>
                <span className="Variations__mainText">Variations:</span>
                <span className="Variations__text">Lorem:</span>
                <Grid item>
                  <Grid container className="Buttons" direction="row">
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 01
                      </span>
                    </button>
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 02
                      </span>
                    </button>
                    <button style={webStyle.variationButton}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 03
                      </span>
                    </button>
                    <button style={webStyle.variationButtonActive}>
                      <span style={webStyle.fontVariationButton}>
                        Variation 04
                      </span>
                    </button>
                  </Grid> 
                </Grid>
              </Grid>
              <Grid item>
                  <Grid container className="Brands" direction="row">
                    <table style={webStyle.brandTable} cellPadding={0} cellSpacing={0}>
                      <thead className="brandTableHead">
                      <tr style={webStyle.brandTableRow}>
                        <th className="variationTh tableBrand-Brand">Brand</th>
                        <th className="variationTh tableBrand-Name">Product Brand Name</th>
                        <th className="tableBrand-SKU">Brand SKU</th>
                        <th className="tableBrand-arrow"></th>
                        <th className="tableBrand-checkbox"></th>
                      </tr>
                      </thead>
                      <tbody className="brandTableBody">
                        {
                          dummyBrandTable.map((item:any)=>{
                            return(
                              <tr>
                                <td style={webStyle.brandTableCell} className="tableBrand-Brand">{item.brand}</td>
                                <td style={webStyle.brandTableCell} className="tableBrand-Name">{item.name}</td>
                                <td style={webStyle.brandTableCell} className="tableBrand-SKU">{item.sku}</td>
                                <td style={webStyle.brandTableCell} className="tableBrand-arrow">
                                  <ArrowRight/>
                                </td>
                                <td style={webStyle.brandTableCell} className="tableBrand-checkbox">
                                      <Checkbox 
                                      color="inherit"                          
                                    />            
                                </td>
                              </tr>
                            )
                          })
                        }                        
                      </tbody>
                    </table>                    
                  </Grid> 
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="column" className="quote" style={webStyle.quote} justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container direction="row" alignItems="center" className="quoteBtn">
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <h4 style={webStyle.getQoute}>Get a Quote</h4>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} justifyContent="flex-end" style={{display:"grid"}}>
                          <button style={webStyle.quoteButton}>
                          <svg width="12" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.61737 5.53734C8.60317 5.47724 8.57435 5.42157 8.53346 5.37529C8.49257 5.329 8.44089 5.29353 8.38299 5.27203L5.68252 4.25906L6.36971 0.821715C6.38527 0.74185 6.37444 0.659091 6.33883 0.585925C6.30323 0.51276 6.2448 0.453159 6.17236 0.416116C6.09991 0.379073 6.01738 0.366598 5.93722 0.380573C5.85707 0.394549 5.78363 0.434216 5.72799 0.49359L0.477994 6.11859C0.435354 6.16352 0.404508 6.21831 0.388211 6.27807C0.371915 6.33783 0.370675 6.4007 0.384603 6.46106C0.398532 6.52141 0.427194 6.57738 0.46803 6.62395C0.508865 6.67053 0.560603 6.70626 0.618619 6.72797L3.32002 7.74093L2.63471 11.1745C2.61915 11.2544 2.62999 11.3372 2.66559 11.4103C2.70119 11.4835 2.75962 11.5431 2.83207 11.5801C2.90451 11.6172 2.98704 11.6296 3.0672 11.6157C3.14736 11.6017 3.2208 11.562 3.27643 11.5027L8.52643 5.87765C8.5683 5.83271 8.59849 5.77818 8.61435 5.71884C8.6302 5.6595 8.63124 5.59717 8.61737 5.53734ZM3.62893 10.0294L4.11971 7.57406C4.13728 7.48698 4.12336 7.39649 4.08044 7.31872C4.03751 7.24094 3.96838 7.18093 3.88534 7.14937L1.40846 6.2189L5.37502 1.96922L4.88471 4.42453C4.86715 4.51161 4.88107 4.60209 4.92399 4.67987C4.96691 4.75764 5.03605 4.81766 5.11909 4.84922L7.59409 5.77734L3.62893 10.0294Z" fill="white"/>
                          </svg>
                            &nbsp;
                            Instant Quote
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container direction="column" justifyContent="space-evenly" style={webStyle.quantity}>
                        <h5 style={webStyle.orderQuantityText}>Order Quantity</h5>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                          <input style={webStyle.quantityInput} placeholder="Quantity"></input>
                          <h7 style={webStyle.quantityNomer}>No.</h7>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="quantityNumberArrow">
                            <path d="M10.0155 4.76552L6.26552 8.51552C6.23069 8.55039 6.18934 8.57805 6.14381 8.59692C6.09829 8.61579 6.04949 8.6255 6.00021 8.6255C5.95093 8.6255 5.90213 8.61579 5.85661 8.59692C5.81108 8.57805 5.76972 8.55039 5.7349 8.51552L1.9849 4.76552C1.91453 4.69516 1.875 4.59972 1.875 4.50021C1.875 4.4007 1.91453 4.30526 1.9849 4.2349C2.05526 4.16453 2.1507 4.125 2.25021 4.125C2.34972 4.125 2.44516 4.16453 2.51552 4.2349L6.00021 7.72005L9.4849 4.2349C9.51974 4.20005 9.5611 4.17242 9.60662 4.15356C9.65214 4.1347 9.70094 4.125 9.75021 4.125C9.79948 4.125 9.84827 4.1347 9.8938 4.15356C9.93932 4.17242 9.98068 4.20005 10.0155 4.2349C10.0504 4.26974 10.078 4.3111 10.0969 4.35662C10.1157 4.40214 10.1254 4.45094 10.1254 4.50021C10.1254 4.54948 10.1157 4.59827 10.0969 4.64379C10.078 4.68932 10.0504 4.73068 10.0155 4.76552Z" fill="#FF6600"/>
                          </svg>
                        </Grid>
                        <h4 style={webStyle.h4Convert}><span style={webStyle.h4ConvertMain}>Unit Conversion <span style={{fontStyle: "italic"}}>-</span> </span> 1 bag = 20 kg</h4>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                          <button style={webStyle.addQuotation}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.57031 6.85603L8.99888 6.85603V5.57031" stroke="white" stroke-width="1.02857" stroke-miterlimit="10"/>
                            <path d="M2.57031 2.57031L5.99888 2.57031" stroke="white" stroke-width="1.02857" stroke-miterlimit="10"/>
                            <path d="M2.57199 11.144C3.04537 11.144 3.42913 10.7602 3.42913 10.2868C3.42913 9.81344 3.04537 9.42969 2.57199 9.42969C2.0986 9.42969 1.71484 9.81344 1.71484 10.2868C1.71484 10.7602 2.0986 11.144 2.57199 11.144Z" fill="white"/>
                            <path d="M9.42746 11.144C9.90084 11.144 10.2846 10.7602 10.2846 10.2868C10.2846 9.81344 9.90084 9.42969 9.42746 9.42969C8.95407 9.42969 8.57031 9.81344 8.57031 10.2868C8.57031 10.7602 8.95407 11.144 9.42746 11.144Z" fill="white"/>
                            <path d="M1.28516 1.28516L2.57087 2.57087L2.57087 6.85658L1.71373 8.57087L10.7137 8.57087" stroke="white" stroke-width="1.02857" stroke-miterlimit="10"/>
                            <path d="M9 1.28516L9 3.85658" stroke="white" stroke-width="1.02857" stroke-miterlimit="10" stroke-linecap="square"/>
                            <path d="M7.71484 2.57031L10.2863 2.57031" stroke="white" stroke-width="1.02857" stroke-miterlimit="10" stroke-linecap="square"/>
                          </svg>
                          &nbsp;Add for Quotation
                          </button>
                        </Grid>
                      </Grid>
                      <Grid container direction="column" justifyContent="flex-start" style={webStyle.quantity}>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                            <img src={parcel} style={{height:"16px"}} />
                            <div style={webStyle.quoteInfo}>
                              <span>MOQ</span>
                              <span style={webStyle.quoteInfoAdd}>20 No.s</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 4.57031L8 7.99888L11.4286 7.99888" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                          <path d="M2.83845 14.211L2.42188 10.8984L5.67445 11.8047" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                          <path d="M2.4217 10.9004C3.12981 12.2646 4.31318 13.322 5.74807 13.8727C7.18296 14.4235 8.76992 14.4295 10.2089 13.8895C11.6479 13.3495 12.8392 12.301 13.5575 10.9423C14.2758 9.58349 14.4715 8.00861 14.1073 6.5154C13.7432 5.02219 12.8447 3.71412 11.5815 2.83852C10.3183 1.96292 8.77813 1.58047 7.2521 1.76348C5.72607 1.94649 4.31998 2.68228 3.29967 3.83173C2.27936 4.98117 1.71555 6.46462 1.71484 8.00158" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10"/>
                          </svg>

                            <div style={webStyle.quoteInfo}>
                              <span>Lead Time</span>
                              <span style={webStyle.quoteInfoAdd}>Quote Required</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.24186 3.60938L5.12129 4.33223L6.019 5.29052L5.79786 6.7848L4.38358 7.5008L3.63672 8.13795L4.00015 8.75795L4.71329 9.52195L5.09843 10.8705L4.02758 11.7688L3.83158 12.6025" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10"/>
                            <path d="M7.07923 14.2154L6.98437 13.6103L8.10152 12.4034L8.65409 10.8863L10.4232 10.2148L12.1375 10.5571L13.0821 11.4726" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10"/>
                            <path d="M13.9413 7.42797C14.1248 8.70861 13.8891 10.0143 13.2692 11.1498C12.6493 12.2854 11.6786 13.1899 10.5022 13.7281C9.32572 14.2664 8.00668 14.4095 6.74217 14.1361C5.47766 13.8627 4.33557 13.1874 3.48659 12.2112C2.6376 11.235 2.12729 10.0103 2.03193 8.72005C1.93657 7.42984 2.26128 6.1434 2.95752 5.05299C3.65376 3.96258 4.68416 3.12675 5.89474 2.67039C7.10531 2.21404 8.43106 2.16167 9.67386 2.52111" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10"/>
                            <path d="M11.4308 4.57254C11.7464 4.57254 12.0022 4.31671 12.0022 4.00112C12.0022 3.68552 11.7464 3.42969 11.4308 3.42969C11.1152 3.42969 10.8594 3.68552 10.8594 4.00112C10.8594 4.31671 11.1152 4.57254 11.4308 4.57254Z" fill="#171A1F"/>
                            <path d="M13.7121 4.00056C13.7121 5.41142 11.4263 7.42913 11.4263 7.42913C11.4263 7.42913 9.14062 5.41142 9.14062 4.00056C9.14062 3.39435 9.38144 2.81297 9.8101 2.38431C10.2387 1.95566 10.8201 1.71484 11.4263 1.71484C12.0325 1.71484 12.6139 1.95566 13.0426 2.38431C13.4712 2.81297 13.7121 3.39435 13.7121 4.00056Z" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                          </svg>

                            <div style={webStyle.quoteInfo}>
                              <span>Regional Availabililty</span>
                              <span style={webStyle.quoteInfoAdd}>Available for your Project</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item >
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={webStyle.quoteItem}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.28516 4.57031L11.428 4.57031L11.9994 6.85603L14.2852 7.99888L14.2852 11.4275L11.3303 11.4275" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10"/>
                          <path d="M6.28627 2.28516L1.71484 2.28516L1.71484 11.428L6.28627 11.428L6.28627 2.28516Z" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                          <path d="M9.71429 13.7137C10.6611 13.7137 11.4286 12.9462 11.4286 11.9994C11.4286 11.0527 10.6611 10.2852 9.71429 10.2852C8.76751 10.2852 8 11.0527 8 11.9994C8 12.9462 8.76751 13.7137 9.71429 13.7137Z" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                          <path d="M9.14062 6.28516V7.42801H10.2835" stroke="#171A1F" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
                          </svg>

                            <div style={webStyle.quoteInfo}>
                              <span>Transportation</span>
                              <span style={webStyle.quoteInfoAdd}>Quote Required</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column" style={webStyle.request} className="request">
                <span className="requestSample">Request for a Sample</span>
                <button style={webStyle.requestButton}>
                  Request Sample
                </button>
                <span className="noteText"><span className="noteTextMain">Note:</span> Sampling not available for this product.</span>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container style={webStyle.description} direction="column">
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="center">
                    <button id="button-1" style={this.state.pageDescription === 1 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 1})}}>Description</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-2" style={this.state.pageDescription === 2 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 2})}}>Specifications</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-3" style={this.state.pageDescription === 3 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 3})}}>Ways of Application</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-4" style={this.state.pageDescription === 4 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 4})}}>Use</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-5" style={this.state.pageDescription === 5 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 5})}}>Certifications</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-6" style={this.state.pageDescription === 6 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 6})}}>Standards</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-7" style={this.state.pageDescription === 7 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 7})}}>Quality Tests</button>
                    <div style={webStyle.separator}></div>
                    <button id="button-8" style={this.state.pageDescription === 8 ? webStyle.descriptionButton__active : webStyle.descriptionButton} onClick={()=>{this.setState({pageDescription: 8})}}>New Button</button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" style={webStyle.textDescription}>
                  {this.state.pageDescription === 1 ? 
                  <div>
                    <h7 className="mainTextDescription">The Bosch 1300W Professional Router is a powerful and versatile tool that can handle a variety of routing tasks. With its 1300 watt</h7> 
                    <br/>
                    <span className="seccondaryTextDescription">motor, it can easily handle even the most challenging routing jobs</span>
                    <br/>
                    <h7 className="mainTextDescription">Power 1300W,Collet and bit size [6mm,8mm,1/4 inch],Plunge length 55 mm,Weight 3.5 kg</h7> 
                    <br/>
                    <span className="seccondaryTextDescription">
                      Quick carbon brush change, constant Speed: maximum cutting, Lock on and lock off switch, Overload protection and Restart protection,
                      Flexible options for fast and easy bit changes, 2.5m long cord
                      Warranty 6 Months from the date of invoice (provide invoice copy to claim warranty)
                      Power 1300W, Collet and bit size [6mm, 8mm,/4 inch], Plunge length 55 mm,Weight 3.5 kg
                    </span>
                  </div>
                  : ""}
                  {this.state.pageDescription === 2 ? 
                  <Grid container direction="row" style={webStyle.spec} justifyContent="space-around">
                    <Grid item xs={12} sm={5} md={5} lg={5} className="gridTableSpec">
                      <Typography className="specificationMainText">
                        Features
                      </Typography>
                      <table style={webStyle.specTable} cellPadding={0} cellSpacing={0}>
                        {dummySpec.map((item, index) => {
                          return (<tr className="specTableRow">
                            <td style={webStyle.specKey}>{item.key}</td>
                            <td style={webStyle.specValue}>{item.value}</td>
                          </tr>)
                        })}
                      </table>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5} className="gridTableSpec">
                      <Typography className="specificationMainText">
                        General Properties
                      </Typography>
                      <table style={webStyle.specTable} cellPadding={0} cellSpacing={0}>
                        {dummySpec.map((item, index) => {
                          return (<tr className="specTableRow">
                            <td style={webStyle.specKey}>{item.key}</td>
                            <td style={webStyle.specValue}>{item.value}</td>
                          </tr>)
                        })}
                      </table>
                    </Grid>
                  </Grid>
                  : ""} 
                  {this.state.pageDescription === 5 ? 
                  <Grid container direction="row" style={webStyle.spec} justifyContent="space-around">
                    <Grid item xs={12} sm={5} md={5} lg={5} className="gridTableSpec">
                      <table className="certTable" cellPadding={0} cellSpacing={0}>
                        {dummyCertificate.map((item, index) => {
                          return (<tr className="certTableRow">
                            <td className="specTableRow__td-text">{item}</td>
                            <td className="specTableRow__td-eye">
                            <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.2547 12C12.5047 12 13.5674 11.5627 14.4427 10.688C15.3174 9.81267 15.7547 8.75 15.7547 7.5C15.7547 6.25 15.3174 5.18733 14.4427 4.312C13.5674 3.43733 12.5047 3 11.2547 3C10.0047 3 8.94202 3.43733 8.06669 4.312C7.19202 5.18733 6.75469 6.25 6.75469 7.5C6.75469 8.75 7.19202 9.81267 8.06669 10.688C8.94202 11.5627 10.0047 12 11.2547 12ZM11.2547 10.2C10.5047 10.2 9.86735 9.93733 9.34269 9.412C8.81735 8.88733 8.55469 8.25 8.55469 7.5C8.55469 6.75 8.81735 6.11233 9.34269 5.587C9.86735 5.06233 10.5047 4.8 11.2547 4.8C12.0047 4.8 12.6424 5.06233 13.1677 5.587C13.6924 6.11233 13.9547 6.75 13.9547 7.5C13.9547 8.25 13.6924 8.88733 13.1677 9.412C12.6424 9.93733 12.0047 10.2 11.2547 10.2ZM11.2547 15C8.93802 15 6.82135 14.3877 4.90469 13.163C2.98802 11.9377 1.53802 10.2833 0.554688 8.2C0.504688 8.11667 0.471354 8.01233 0.454688 7.887C0.438021 7.76233 0.429688 7.63333 0.429688 7.5C0.429688 7.36667 0.438021 7.23733 0.454688 7.112C0.471354 6.98733 0.504688 6.88333 0.554688 6.8C1.53802 4.71667 2.98802 3.06267 4.90469 1.838C6.82135 0.612666 8.93802 0 11.2547 0C13.5714 0 15.688 0.612666 17.6047 1.838C19.5214 3.06267 20.9714 4.71667 21.9547 6.8C22.0047 6.88333 22.038 6.98733 22.0547 7.112C22.0714 7.23733 22.0797 7.36667 22.0797 7.5C22.0797 7.63333 22.0714 7.76233 22.0547 7.887C22.038 8.01233 22.0047 8.11667 21.9547 8.2C20.9714 10.2833 19.5214 11.9377 17.6047 13.163C15.688 14.3877 13.5714 15 11.2547 15ZM11.2547 13C13.138 13 14.8674 12.504 16.4427 11.512C18.0174 10.5207 19.2214 9.18333 20.0547 7.5C19.2214 5.81667 18.0174 4.479 16.4427 3.487C14.8674 2.49567 13.138 2 11.2547 2C9.37135 2 7.64202 2.49567 6.06669 3.487C4.49202 4.479 3.28802 5.81667 2.45469 7.5C3.28802 9.18333 4.49202 10.5207 6.06669 11.512C7.64202 12.504 9.37135 13 11.2547 13Z" fill="#64748B"/>
                            </svg>
                            </td>
                          </tr>)
                        })}
                      </table>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5} className="gridTableSpec">
                      <table className="certTable" cellPadding={0} cellSpacing={0}>
                        {dummyCertificate.map((item, index) => {
                          return (<tr className="certTableRow">
                            <td className="specTableRow__td-text">{item}</td>
                            <td className="specTableRow__td-eye">
                            <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="certificationEye">
                              <path d="M11.2547 12C12.5047 12 13.5674 11.5627 14.4427 10.688C15.3174 9.81267 15.7547 8.75 15.7547 7.5C15.7547 6.25 15.3174 5.18733 14.4427 4.312C13.5674 3.43733 12.5047 3 11.2547 3C10.0047 3 8.94202 3.43733 8.06669 4.312C7.19202 5.18733 6.75469 6.25 6.75469 7.5C6.75469 8.75 7.19202 9.81267 8.06669 10.688C8.94202 11.5627 10.0047 12 11.2547 12ZM11.2547 10.2C10.5047 10.2 9.86735 9.93733 9.34269 9.412C8.81735 8.88733 8.55469 8.25 8.55469 7.5C8.55469 6.75 8.81735 6.11233 9.34269 5.587C9.86735 5.06233 10.5047 4.8 11.2547 4.8C12.0047 4.8 12.6424 5.06233 13.1677 5.587C13.6924 6.11233 13.9547 6.75 13.9547 7.5C13.9547 8.25 13.6924 8.88733 13.1677 9.412C12.6424 9.93733 12.0047 10.2 11.2547 10.2ZM11.2547 15C8.93802 15 6.82135 14.3877 4.90469 13.163C2.98802 11.9377 1.53802 10.2833 0.554688 8.2C0.504688 8.11667 0.471354 8.01233 0.454688 7.887C0.438021 7.76233 0.429688 7.63333 0.429688 7.5C0.429688 7.36667 0.438021 7.23733 0.454688 7.112C0.471354 6.98733 0.504688 6.88333 0.554688 6.8C1.53802 4.71667 2.98802 3.06267 4.90469 1.838C6.82135 0.612666 8.93802 0 11.2547 0C13.5714 0 15.688 0.612666 17.6047 1.838C19.5214 3.06267 20.9714 4.71667 21.9547 6.8C22.0047 6.88333 22.038 6.98733 22.0547 7.112C22.0714 7.23733 22.0797 7.36667 22.0797 7.5C22.0797 7.63333 22.0714 7.76233 22.0547 7.887C22.038 8.01233 22.0047 8.11667 21.9547 8.2C20.9714 10.2833 19.5214 11.9377 17.6047 13.163C15.688 14.3877 13.5714 15 11.2547 15ZM11.2547 13C13.138 13 14.8674 12.504 16.4427 11.512C18.0174 10.5207 19.2214 9.18333 20.0547 7.5C19.2214 5.81667 18.0174 4.479 16.4427 3.487C14.8674 2.49567 13.138 2 11.2547 2C9.37135 2 7.64202 2.49567 6.06669 3.487C4.49202 4.479 3.28802 5.81667 2.45469 7.5C3.28802 9.18333 4.49202 10.5207 6.06669 11.512C7.64202 12.504 9.37135 13 11.2547 13Z" fill="#64748B"/>
                            </svg>
                            </td>
                          </tr>)
                        })}
                      </table>
                    </Grid>
                  </Grid>
                  : ""}                  
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        <Footer />
        </ProductBlock>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  globalContainer:{
    marginTop:"20px",
  },
  tableEye:{
    width:"12px",
    height:"12px"
  },
  documentsTable: {
    borderRadius:"6px",
    border:"1px solid #DEDFE3",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 700,
    overflow:"hidden",
  },
  tableTd:{
    padding:"10px",
    border:"1px solid #DEDFE3",
    textAlign:"center",
    alignItems:'center',
    justifyContent:"center"
  },
  tableChild:{
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildLeftUp: {
    padding:"19px",
    textAlign:"center",
  },
  tableChildRightUp: {
    padding:"19px",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildLeftDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildRightDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  firstCell: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  lastCell: {
    border: "1px solid",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px"
  },
  variationButton: {
    cursor:"pointer",
    height:"35px",
    padding: "8px 12px",
    margin: "7px 5px",
    border: '1px solid',
    borderColor: "#F0F0F0",
    borderRadius:"9px",
    backgroundColor: "#FFFFFF",
    color:"#1E293B",
    "&:hover": {
      backgroundColor: "#EE6920",
      color: "#FFFFFF"
    },
    fontFamily: 'Inter, sans-serif',
  },
  fontVariationButton:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
  },
  variationButtonActive: {
    height:"35px",
    padding: "8px 12px",
    margin: "7px 5px",
    border: '1px solid',
    borderColor: "transparent",
    borderRadius:"9px",
    backgroundColor: "#FF6600",
    color: "#FFFFFF"
  },
  brandTable:{
    border:"1px solid",
    borderRadius:"12px",
    borderColor:"#DEDFE3",
    overflow:"hidden",
    marginTop:"20px",
    height:"200px",
  },
  brandTableCell:{
    borderTop:"1px solid",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderColor:"#DEE1E6",
    color:"#171A1F"
  },
  brandTableRow:{
    backgroundColor: "#F3F4F6",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 500,
    color:"#323743"
  },
  quoteButton:{
    color:"#FFFFFF",
    backgroundColor:"#FF6600",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderRadius:"9px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
    borderColor:"transparent",
    padding:"10px 20px",
    whiteSpace:"nowrap",
    cursor:"pointer"
  },
  quote:{
    border:"1px solid",
    borderRadius:"8px",
    borderColor:"#DEDFE3",
    padding:"20px"
  },
  quantityInput:{
    width:"130px",
    height:"30px",
    backgroundColor:"#F3F4F6",
    borderRadius:"9px",
    alignItems:"center",
    borderColor:"transparent",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    cursor:"pointer"
  },
  quantity:{
   
  },
  quantityNomer:{
    color:"#FF6600",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    marginLeft:"15px",
    cursor:"pointer"
  },
  addQuotation:{
    color:"#FFFFFF",
    backgroundColor:"#FF6600",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderRadius:"9px",
    padding:"10px 8px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
    borderColor:"transparent",
    marginTop:"8px",
    cursor:"pointer"
  },
  quoteInfo:{
    display: "flex",
    alignContent:"center",
    flexDirection:"column",
    marginLeft:"12px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 700,
    color:"#323743",
    lineHeight:"26px"
  },
  quoteItem:{
    marginTop:"16px"
  },
  request:{
    border:"1px solid",
    borderRadius:"12px",
    borderColor:"#DEDFE3",
    marginTop:"20px",
    padding:"15px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
  },
  requestButton:{
    color:"#FFFFFF",
    backgroundColor:"#969697",
    borderRadius:"9px",
    marginTop:"12px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 400,
    borderColor:"transparent",
    padding:"6px 0px",
    width:"120px",
    cursor:"pointer"
  },
  description:{
    border: "1px solid",
    borderRadius:"9px",
    marginTop:"60px",
    padding:"19px 24px 30px 24px",
    borderColor:"#DEDFE3",
    marginBottom:"60px",
    height:"auto",
  },
  descriptionButton__active:{
    borderRadius:"12px",
    backgroundColor:"#FF6600",
    color:"#FFF",
    padding:"11px 43px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    borderColor:"transparent",
    cursor:"pointer"
  },
  descriptionButton:{
    backgroundColor:"#FFF",
    color:"#FF6600",
    padding:"11px 43px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
    borderColor:"transparent",
    cursor:"pointer"
  },
  separator:{
    height:"22px",
    border:"1px solid",
    borderColor:"#FFEBDD",
  },
  textDescription:{
    marginTop:"25px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "20px",
    fontWeight: 400,
    color:"#334155",
  },
  navMenu:{
    marginTop:"15px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 700,
    color:"#323743",
  },
  navMenuItem:{
    height:"19px",
    alignItems:"center",
    whiteSpace:"nowrap",
    display:"flex",
    cursor:"pointer"
  },
  notMainPhoto:{
    borderRadius:"12px",
    border:"1px solid #9095A1"
  },
  mainPhoto:{
    width:"100%", 
    borderRadius:"12px",
  },
  documentsText:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 700,
    lineHeight:"38px",
    color:"#323743"
  },
  secondPart:{
    fontFamily: 'Inter, sans-serif',
  },
  h2:{
    fontSize: "24px",
    fontWeight: 700,
    color:"#323743",
    margin:"4px 0px"
  },
  h4:{
    fontSize: "12px",
    fontWeight: 400,
    color:"#323743",
    margin:"5px 0px"
  },
  h4Main:{
    fontWeight: 700,
  },
  h3:{
    fontSize: "12px",
    fontWeight: 700,
    margin:"4px 0px"
  },
  featureLabel:{
    fontSize: "16px",
    fontWeight: 500,
    color:"#9095A0"
  },
  getQoute:{
    fontSize: "16px",
    fontWeight: 700,
    color:"#171A1F",
    fontFamily: 'Epilogue, sans-serif',
  },
  orderQuantityText:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "12px",
    fontWeight: 700,
    color:"#424856"
  },
  h4Convert:{
    fontFamily: 'Inter, sans-serif',
    fontSize: "11px",
    fontWeight: 400,
    color:"#9095A1",
    margin:"0px",
    lineHeight:"26px"
  },
  h4ConvertMain:{
    color:"#1E293B",
    fontWeight: 700,
  },
  quoteInfoAdd:{
    color:"#9095A1",
    fontSize: "12px",
    fontWeight: 400,
  },
  specTable:{
    width:"100%",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 500,
    lineHeight:"20px",
    color:"#666666",
    marginBottom:"20px",
    border:"1px solid #DEDFE3",
    overflow:"hidden",
    borderRadius:"8px"
  },
  spec:{
    height:"auto"
  },
  specKey:{
    color:"#1E293B",
    padding:"20px",
    width:"270px"

  },
  specValue:{
    height:"50px",
    width:"60%",
    color:"#666666",
     padding:"20px",
  }
};
// Customizable Area End
