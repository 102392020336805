import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Badge, Hidden, Checkbox, Chip, Button, Container, IconButton, Drawer } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BreadCrumb from "../../../components/src/BreadCrumb.web";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import { back, down, plus, wishlist, eyeIcon, ribon, bestSeller, soldOut } from "./assets";
import Categories from "../src/Categories.web";
import { styled } from "@material-ui/styles";
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from '@material-ui/icons/Close';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Menu } from '@material-ui/icons';

const CatalogBlock = styled("div")(({ theme }) => ({
  "& .cat-box": {
    border: "1px solid #CBD5E1",
    borderRadius: "4px",
    position: "relative",
    height:"270px"
  },
  "& .cat-img": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mixBlendMode: "multiply"
  },
  "& .cat-desc": {
    fontSize: "15px", fontWeight: 600, margin: "10px",
    overflowY: "auto",
    maxHeight: "95px"
  },
  "& .price-txt": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .cut-price": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000"
  },
  "& .offer-txt": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#FF6600"
  },
  "& .short-boxes": {
    display: "flex", justifyContent: "right",
    "@media (max-width:600px)": {
      justifyContent: "left !important"
    },
  },
  "& .heading": {
    fontSize: "23px",
    fontWeight: 700,
    color: "#000000",
  },
  "& .badge": {
    width: "74px",
    "@media (max-width:1040px) and (min-width:900px)": {
      marginTop: "10px",
      marginLeft:"-155px",
    },
    "@media (max-width:700px) and (min-width:600px)": {
      marginTop: "50px",
      marginLeft:"-195px",
    },
  },
  "& .main-heading": {
    display: "flex",
    alignItems: "center",    
    width: "max-content",
    marginLeft: "15px",
     "@media (max-width:1040px) and (min-width:900px)": {
      flexDirection:"column",    
    },
    "@media (max-width:600px)": {
      marginLeft: "0px",   
    } 
  },
  "& .grid-data": {
    width: "50px",
    height: "50px",
    background: "#FF6600",
    borderRadius: "4px"
  },
  "& .list-data": {
    width: "50px",
    height: "50px",
    background: "white",
    borderRadius: "4px",
    border: "1px solid #475569",
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  "& .page-data": {
    display: "flex",
    width: "158px",
    height: "50px",
    marginRight: "10px",
    border: "1px solid #475569",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    backgroundColor: "#E2E8F0",
    color: "#64748B"
  },
  "& .sort-data": {
    display: "flex",
    width: "200px",
    height: "25px",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
    marginRight:"15px"
  },
  "& .dropdown-list-data-language": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "43px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "14px",
    fontWeight: 400,
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "190px"
  },
  "& .dropdown-list-data-language li": {
    padding: "0px 13px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#FF6600"
  },
  "& .MuiCheckbox-root": {
    color: "#CBD5E1"
  },
  "& .check-btn": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "& .img-title": {
    fontSize: "38px", fontWeight: 700, color: "white", position: "absolute", left: "80px", zIndex: 1, top: "68px",
    "@media (max-width:650px)": {
      fontSize: "18px",
      left: "20px"
    },
    "@media (max-width:900px)": {
      left: "20px"
    },
  },
  "& .img-slider": {
    width: "100%",
    height: "200px",
    position: "relative",
    "@media (max-width:600px)": {
      height: "150px",
    },
  },
  '& .gridBox':{
    marginTop: "30px", paddingRight: "50px",
    "@media (max-width:900px)": {
      marginTop: 0
    },
  },
  "& .MuiChip-root": {
    backgroundColor: "#E2E8F0"
  },
  "& .banner-txt": {
    marginTop: "10px",
  },
  "& .pagination-content": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px"
  },
  "& .next-btn": {
    width: "63px",
    height: "29px",
    backgroundColor: "#FF6600",
    color: "white"
  },
  
  "& .cat-box:hover .data-box": {
    display: "flex", visibility: "visible"
  },
  "& .data-box": {
    position: "absolute",
    top: 0,
    right: 0,
    display: "none",
    flexDirection: "column",
    gap: "15px",
    visibility: "hidden",
    backgroundColor: "#fff",
    padding: "10px",
    zIndex:1,
    background: "none !important"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    border: "none",
    backgroundColor: "white",
    fontFamily:"Inter, sans-serif",
    fontWeight:700,
    fontSize:"12px",
    color:"#979797",
  },
  "& .MuiPaginationItem-ellipsis":{
    borderBottom:"1px solid #FFF !important"
  },
  '[aria-label="Go to next page"]':{
    borderBottom:"1px solid #FFF !important"
  },
  "& .MuiPaginationItem-root":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"12px",
    color:"#979797",
    borderRadius: "0px",
    borderBottom:"1px solid #979797"
  },
  "& .filter-content": {
    display: "flex", justifyContent: 'right', gap: "12px", marginRight: "0px",
    "@media (min-width:1240px)": {
      display: "flex",
      alignItems: "center",
      marginRight: "120px",
    },
  },
  "& .navigation-text":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"14px",
    color:"#171A1F",
  },
  "& .navigation-text-main":{
    fontFamily:"Inter, sans-serif",
    fontWeight:700,
    fontSize:"24px",
    color:"#171A1F",
    marginTop:"12px"
  },
  "& .navigation-text-sec":{
    fontFamily:"Inter, sans-serif",
    fontWeight:500,
    fontSize:"14px",
    color:"#9095A0",
    marginTop:"12px"
  },
  "& .navigation-categories":{
    padding:"30px 50px",
    borderBottom:"1px solid #E2E8F0"
  },
  "& .nav-separator":{
    height:"100%",
    border:"1px solid #BCC1CA",
    margin:"0px 40px"
  },
  "& .third-level-categories-img-active":{
    width:"90px",
    height:"90px",
    borderRadius:"12px",
    border:"1px solid #FF6600",
    borderShadow:"none",
  },
  "& .third-level-categories-img":{
    width:"90px",
    height:"90px",
    borderRadius:"12px",
  },
  "& .third-level-categories-text-active":{
    textAlign:"center",
    fontFamily:"Inter, sans-serif",
    fontWeight:600,
    fontSize:"14px",
    color:"#171A1F",
    marginTop:"4px"
  },
  "& .third-level-categories-text":{
    textAlign:"center",
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"14px",
    color:"#171A1F",
    marginTop:"4px",
  },
  "& .third-level-categories":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    marginRight:"30px",
  },
  "& .numberItems":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"14px",
    color:"#9095A0",
  },
  "& .sortingText":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"14px",
    color:"#171A1F",
  },
  "& .cat-box-img-container":{
    padding:"20px 0px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .cat-box-img":{
    height:"90px",
    width:"150px",  //remove when api img issue has been resolved
    border:"1px solid",
    borderRadius:"5px"
  },
  "& .cat-box-name":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"14px",
    color:"#171A1F",
    textAlign:"center",
  },
  "& .productDescription":{
    padding:"20px"
  },
  "& .cat-box-size":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"12px",
    color:"#9095A0",
    marginTop:"12px"
  },
  "& .cat-box-type":{
    border:"1px solid #9095A0",
    borderRadius:"13px",
    textAlign:"center",
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"12px",
    color:"#9095A0",
    display:"flex",
    padding:"6px 12px",
    marginTop:"12px",
    lineHeight:"13px",
    marginLeft:"5px"
  },
  "& .cat-box-type-container":{
    display:"flex"
  },
  "& .pagination":{
    display:"flex",
    alignItems:"center",
    flexDirection:"row",
    color:"#979797",
    marginTop:"20px"
  },
  "& .paginationItems":{
    display:"flex",
    alignItems:"center",
    flexDirection:"row",
    gap:"4px",
    margin:"0px 10px"
  },
  "& .paginationItems__page-active":{
    fontFamily:"Inter, sans-serif",
    fontWeight:700,
    fontSize:"12px",
    color:"#979797",
    padding:"3px 5px",
    lineHeight:"18px"
  },
  "& .paginationItems__page":{
    fontFamily:"Inter, sans-serif",
    fontWeight:400,
    fontSize:"12px",
    color:"#979797",
    padding:"3px 5px",
    lineHeight:"18px",
    textAlign:"center",
  },
  "& .pagination__number":{
    borderBottom:"1px solid #979797"
  }
}))

interface ProductCat {
  productId: string;
  productName: string;
  imageUrl?: any; 
  imageName: string | null;
  bestSeller: boolean;
  New: boolean | string; 
  status: string;
}

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: { id: number, attributes: { average_rating: string, name: string, price: string, images: [{ url: string }] } }) {
    let value = item.attributes;
    return (
      <Box sx={webStyle.productBox} key={`product-item-${item.id}`}>
        <Box sx={webStyle.ImgContainer}>
          <img style={webStyle.productImg} src={value.images?.[0].url} />
        </Box>
        <Box sx={webStyle.detailContent}>
          <Typography variant="h6">{value.name}</Typography>
          <Typography variant="h6">{value.price}</Typography>
          <Typography variant="h6">{value.average_rating}</Typography>
        </Box>
      </Box>
    );
  }

  filterItems = [
    { label: "Newest to Oldest", key: 0, checked: false },
    { label: "Oldest to Newest", key: 1, checked: false },
    { label: "Price Lowest", key: 2, checked: false },
    { label: "Price Higest", key: 3, checked: false },
    { label: "Sort by A to Z", key: 4, checked: false },
  ];

  getProductImage = (product: ProductCat) => {
    if (product.New === true) {
        return ribon;
    } else if (product.bestSeller === true) {
        return bestSeller;
    } else if (product.status === "available") {
        return "";
    } else {
        return soldOut;
    }
};

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <Navbar history={this.props.history} />
        <CatalogBlock>
          <Grid container direction="row" className="navigation-categories">
            <Grid item direction="column" sm={12} xs={12} md={4} lg={4}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className="navigation-text">Plumbing / {this.props?.location?.state?.breadCrum[2].name}</Typography>
                </Grid>
                <Grid item>
                  <Typography className="navigation-text-main">
                    SWR System
                  </Typography>
                  <Typography className="navigation-text-sec">
                    SWR stands for Soil, Waste and Rainwater Pipe and Fittings 
                    for building drainage systems
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box className="nav-separator"></Box>
            </Grid>
            <Grid item sm={12} xs={12} md={7} lg={7}>
              <Grid container>
                <Grid item className="third-level-categories">
                  <Box>
                    <img src={back} className="third-level-categories-img-active"/>
                  </Box>
                  <Typography className="third-level-categories-text-active">
                    Pipes
                  </Typography>
                </Grid>
                <Grid item className="third-level-categories">
                  <Box >
                    <img src={back} className="third-level-categories-img"/>
                  </Box>
                  <Typography className="third-level-categories-text">
                    Fittings
                  </Typography>
                </Grid>
                <Grid item className="third-level-categories">
                  <Box >
                    <img src={back} className="third-level-categories-img"/>
                  </Box>
                  <Typography className="third-level-categories-text">
                    Adhesives
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>            


          </Box>
          <MasterFinances >
            <IconButton onClick={this.handleToggleSidebar}>
              <Menu />
            </IconButton>
          </MasterFinances>
          <Grid container direction="row" spacing={1}>
            <Grid item xl={2} lg={3} md={3}>
              <CategoryMenu >
                <Drawer open={this.state.catBlock} anchor="left" onClose={this.handleToggleSidebar} >
                  <Categories passState={this.handleCatId} passSubCat={this.handleSubCatId} passBrand={this.handleBrandId} navigation={this.props.navigation} handleToggleSidebar={this.handleToggleSidebar} id={""} />
                </Drawer>
              </CategoryMenu>
              <CategoryBLock >
                <Categories  passState={this.handleCatId} passSubCat={this.handleSubCatId} passBrand={this.handleBrandId} navigation={this.props.navigation} handleToggleSidebar={this.handleToggleSidebar} id={""} />
              </CategoryBLock>
            </Grid>
            <Grid item xl={10} lg={9} md={9} sm={12} spacing={3}  className="gridBox" >
              <Grid container >
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <Box className="main-heading">
                    <Typography className="numberItems">
                      Showing 10 of 179 items
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={12} className="short-boxes" >
                  <Box className="short-boxes">
                    <Box className="sort-data">
                      <Box>
                        <Typography className="sortingText">Sort By:</Typography>
                      </Box>
                      <Box data-test-id="sort-btn" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap:"10px", marginLeft:"2px", cursor: "pointer" }} onClick={this.handleOpen}>
                        <Typography className="sortingText">Default</Typography>
                        <img src={down} alt="down" />
                      </Box>

                      <Hidden>
                        {this.state.showFilterData && (
                          <ul className="dropdown-list-data-language">
                            {this.state.chipData.map((item: { label: string, key: number, checked: boolean }) => (
                              <li
                                key={item.label}
                                id="dropdowndata"
                                className="dropdown-item"
                                style={{ display: "flex", alignItems: "center", justifyContent: "start" }}
                                data-test-id="filter-data"
                              >
                                <Checkbox style={{ width: "20px", borderRadius: "6px" }}
                                  checked={item.checked}
                                  data-test-id="check-btn"
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDropdownClick(item, event)}
                                  className="check-btn" />
                                <div style={{ fontFamily: 'Inter, sans-serif' }}>{item.label}</div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </Hidden>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box style={{ position: 'relative' }}>
                    <Box style={{ display: "flex", gap: "10px", position: "absolute", top: "10px", overflow: 'auto', width: "99%" }}>
                      {this.state.shortChipData?.map((data: { label: string, key: number, checked: boolean }) => {
                        return (
                          <Chip
                            key={data.key}
                            data-test-id="delete-btn"
                            label={data.label}
                            onDelete={this.handleDelete(data)}
                            deleteIcon={<CloseIcon style={{ width: "15px", height: "15px", color: "black" }} />}

                          />
                        )
                      })}
                      {this.state.shortChipData.length > 0 ? (
                        <Chip
                          style={{ backgroundColor: "#FFF0E5" }}
                          data-test-id="delete-all"
                          label={"Clear all"}
                          onClick={this.delete}
                        />) : ""}
                    </Box>
                    <Grid container spacing={4}  justifyContent="space-around" xl={12} lg={12} md={12} sm={12} style={{ marginTop:"0px"}}>
                      {this.state.productList.map((product: ProductCat) => (
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                        <Box className="cat-box">
                          <Box style={{ display: "flex", justifyContent: "space-between", marginRight: "10px", marginTop: "20px" }}>
                            <Box className="data-box">
                              <Box style={{ width: "44px", height: "44px", backgroundColor: "#FF6600", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={plus} alt="plus" />
                              </Box>
                              <Box style={{ width: "44px", height: "44px", backgroundColor: "#64748B", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={wishlist} alt="wishlist" />
                              </Box>
                              <Box style={{ width: "44px", height: "44px", backgroundColor: "#64748B", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={eyeIcon} alt="eye" />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="cat-box-img-container">
                            <img src={product.imageUrl} alt="category" className="cat-box-img" />
                          </Box>
                          <Grid container direction="column" className="productDescription">
                            <Grid item>
                              <Typography className="cat-box-name">
                                {product.productName}
                              </Typography>
                            </Grid>
                            <Grid item className="cat-box-type-container">
                              <Typography className="cat-box-type">
                                Type: Male Threaded Tee
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className="cat-box-size">
                                Size: 32
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} style={{marginTop:"30px"}}>
                  <Box className="main-heading">
                    <Typography className="numberItems">
                      1 - 12 of 48 results
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={12} className="short-boxes" direction="row">
                  <Box className="pagination">
                    <IconButton onClick={this.handlePrevClick} disabled={this.state.page === 1}>
                      <ArrowLeft/>
                    </IconButton>
                    <Box className="paginationItems">
                      <Typography className="paginationItems__page-active">
                          1                      
                      </Typography>
                      <Typography className="paginationItems__page">
                        <Box className="pagination__number">
                          2
                        </Box> 
                      </Typography>
                      <svg width="10" height="3" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg" className="paginationItems__page">
                        <path d="M1.65341 2.06818C1.44318 2.06818 1.26278 1.9929 1.11222 1.84233C0.961648 1.69176 0.886364 1.51136 0.886364 1.30114C0.886364 1.09091 0.961648 0.910511 1.11222 0.759943C1.26278 0.609375 1.44318 0.534091 1.65341 0.534091C1.86364 0.534091 2.04403 0.609375 2.1946 0.759943C2.34517 0.910511 2.42045 1.09091 2.42045 1.30114C2.42045 1.44034 2.38494 1.56818 2.31392 1.68466C2.24574 1.80114 2.15341 1.89489 2.03693 1.96591C1.9233 2.03409 1.79545 2.06818 1.65341 2.06818ZM4.9581 2.06818C4.74787 2.06818 4.56747 1.9929 4.4169 1.84233C4.26634 1.69176 4.19105 1.51136 4.19105 1.30114C4.19105 1.09091 4.26634 0.910511 4.4169 0.759943C4.56747 0.609375 4.74787 0.534091 4.9581 0.534091C5.16832 0.534091 5.34872 0.609375 5.49929 0.759943C5.64986 0.910511 5.72514 1.09091 5.72514 1.30114C5.72514 1.44034 5.68963 1.56818 5.61861 1.68466C5.55043 1.80114 5.4581 1.89489 5.34162 1.96591C5.22798 2.03409 5.10014 2.06818 4.9581 2.06818ZM8.26278 2.06818C8.05256 2.06818 7.87216 1.9929 7.72159 1.84233C7.57102 1.69176 7.49574 1.51136 7.49574 1.30114C7.49574 1.09091 7.57102 0.910511 7.72159 0.759943C7.87216 0.609375 8.05256 0.534091 8.26278 0.534091C8.47301 0.534091 8.65341 0.609375 8.80398 0.759943C8.95455 0.910511 9.02983 1.09091 9.02983 1.30114C9.02983 1.44034 8.99432 1.56818 8.9233 1.68466C8.85511 1.80114 8.76278 1.89489 8.64631 1.96591C8.53267 2.03409 8.40483 2.06818 8.26278 2.06818Z" fill="#979797"/>
                      </svg>
                      <Typography className="paginationItems__page">
                        <Box className="pagination__number">
                          8
                        </Box> 
                      </Typography>
                    </Box>
                    <IconButton onClick={this.handleNextClick} disabled={this.state.page === 8}>
                      <ArrowRight/>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box style={{ marginTop: "40px" }}>
            <Footer />
          </Box>
        </CatalogBlock>

        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <Box sx={webStyle.productContainer}>
              {this.state.arrayHolder &&
                this.state.arrayHolder.map((item: { id: number, attributes: { average_rating: string, name: string, price: string, images: [{ url: string }] } }) =>
                  this.getWebList(item)
                )}
            </Box>
          </Container>
        </ThemeProvider>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const CategoryMenu = styled(Box)({
  display: "none",
  "@media (max-width: 600px)": {
    display: "block !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "block !important",
  },
});
const CategoryBLock = styled(Box)({
  display: "block",
  margin:"50px 30px 50px 30px",
  
  height: "100%",
  "@media (max-width: 600px)": {
    display: "none !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "none !important",
  },
});
const MasterFinances = styled(Box)({
  display: "none !important",
  
  "@media (max-width: 600px)": {
    display: "block !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "block !important",
    marginLeft:"15px"
  },

});
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End