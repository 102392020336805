import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton, InputBase, TextField } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { styled } from "@material-ui/styles";
import { Message } from "../../../packages/framework/src/Message";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData } from "../../framework/src/Utilities";


type S = { showDropDownCountry: boolean, showDropDownLanguage: boolean, scrollPosition: number, showDropDownCategori: boolean, selectedCategory: string, isSubMenuOpen: boolean, token: any, catData: any, catAllData: any, subCat: any, selectedItem: any, dropAccount: boolean, showCartData: boolean };
interface Props {
    handleNavigate?: () => void
    toggleSlider : ()=> void
    handleSearchFun?: (event:any)=> void;
}


const NavigationBlock = styled("div")(({ theme }) => ({
    height: "76px",
    '& .logo': {
        width: "92px",
        height: "48px",
        margin: "auto 0",
        '@media (max-width: 600px)': {
            width: "70px",
            height: "34px",
        }
    },
    '& .dropdown-list': {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "10px"
    },
    '& .location-icon': {
        width: "20px",
        height: "22px"
    },
    '& .down-icon': {
        cursor: "pointer",
        margin: "1px"
    },
    "& .dropdown-list-data": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "45px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        left: "156px",
        width: "162px",
        '@media (max-width: 600px)': {
            left: "70px !important",
        },
        '@media (max-width: 900px)': {
            left: "120px",
        }
    },
    "& .dropdown-list-data li": {
        padding: "0px 20px",
        margin: "15px 0px",
    },
    "& .dropdown-list-data li:last-of-type a": {
        border: "none",
    },
    "& .dropdown-list a": {
        margin: "4px",
        display: "inline-block",
        textDecoration: "none",
        fontSize: "16px",
        fontWeight: "400",
        color: "#FAFAFA",
        padding: " 10px 0",
        transition: "all 0.3s ease-out",
        zIndex: 100,
    },
    "& .dropdwon-box": {
        position: "relative",
        "& .dropdown-wrapper": {
            position: "absolute"
        }
    },
    "& .dropdown-item": {
        display: "flex",
        alignItems: "center"
    },
    "& .dropdown-item .selected": {
        backgroundColor: "#FFE0CC",
        color: "black",
        display: "flex",
        alignItems: "center"
    },
    "& .dropdown-item:hover": {
        backgroundColor: "#FFE0CC",
        borderRadius: "8px"
    },
    "& .search-icon": {
        width: "24px",
        height: "24px"
    },
    '& .dropdown-list-language': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: "36px 7px",
        gap: "10px"
    },
    "& .dropdown-list-language-logout": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: "36px 31px",
        gap: "10px"
    },
    "& .dropdown-list-data-language": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        width: "162px",
        '@media (max-width: 600px)': {
            width: "100px",
        },
    },
    "& .dropdown-list-data-language li": {
        padding: "0px 20px",
        margin: "15px 0px",
    },
    "& .dropdown-list-data-language li:last-of-type a": {
        border: "none",
    },
    "& .flag-icon": {
        width: "24px"
    },
    '& .account-list': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginRight: "55px",
    },
    "& .account-list-logout": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        margin: "21px 61px",
        gap: "10px",
    },
    "& .user-icon": {
        width: "44px",
        height: "44px"
    },
    "& .order-data": {
        margin: "35px 34px"
    },
    "& .order-data-login": {
        margin: "35px 28px"
    },
    "& .dropdown-list-cat": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        top: "35px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        left: "83px",
        width: "228px",
    },
    "& .dropdown-list-cat li": {
        padding: "6px 20px",
    },
    "& .dropdown-list-cat li:last-of-type a": {
        border: "none",
    },
    "& .dropdown-list-cat a": {
        margin: "4px",
        display: "inline-block",
        textDecoration: "none",
        fontSize: "14px",
        fontWeight: "400",
        color: "#0F172A",
        transition: "all 0.3s ease-out",
        zIndex: 100,
    },
    "& .multi-level-dropdown": {
        fontFamily: "Red Hat Display",
        position: "relative"
    },
    "& .main-menu": {
        listStyle: "none",
        padding: "0",
    },
    "& .menu-item": {
        display: "flex",
        position: "relative",
        marginBottom: "5px",
        width: "80px",
        border: "none",
        zIndex: 1,
    },
    "& .menu-item-label": {
        cursor: "pointer",
        padding: "10px",
    },
    "& .menu-item-label-sub": {
        cursor: "pointer",
        marginLeft: "10px",
        marginRight: "100px",
        marginTop: "10px",
        marginBottom: "10px",
        width: "100%",
    },
    "& .menu-item-label-sub-data": {
        cursor: "pointer",
        marginLeft: "10px",
        marginRight: "100px",
        marginTop: "19px",
        marginBottom: "10px",
        width: "100%",
    },
    '& .menu-item:hover .sub-menu': {
        display: 'block',
    },
    '& .sub-menu': {
        position: 'relative',
        top: '36px',
        left: "402%",
        display: 'none',
        listStyle: 'none',
        padding: 0,
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
    },
    '& .sub-menu li': {
        padding: '6px 20px',
    },
    "& .upload-btn": {
        background: "#FF6600",
        width: "142px",
        height: "44px",
        margin: "22px 28px",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: 'Inter, sans-serif'
    },
    "& .user-data": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "47px",
        gap: "10px",
        marginBottom: "11px"
    },
    "& .dropdown-list-account": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "60px",
        right: "16px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        width: "162px",
    },
    "& .dropdown-list-account li": {
        padding: "0px 20px",
        margin: "15px 0px",
        gap: "16px"
    },
    "& .dropdown-list-account li:last-of-type a": {
        border: "none",
    },
    "& .cart-main": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "69px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        width: "497px",
        height: "361px",
        right: "10px"
    },
    "& .close-btn": {
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "31px",
        marginRight: "34px",
    },
    "& .view-btn": {
        border: "1px solid #FF6600",
        width: "405px",
        height: "50px",
        borderRadius: "10px",
        marginLeft: "10px",
        marginTop: "10px"
    },
    "& .input-step": {
        display: "inline-flex",
        width: "90px",
        // height: "37.5px",
        overflow: "visible",
        padding: "4px",
        border: "1px solid #94A3B8",
        height: "30px",
        borderRadius: "8px"
    },
    "& .input-step input": {
        background: "#0000",
        border: "0",
        color: "black",
        height: "100%",
        textAlign: "center",
        width: "4em"
    }
}
))


interface SS {
    id: any;
}
class DashboardNavbar extends BlockComponent<Props, S, SS>{
    getCatList: string = "";
    getSearchDataListApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            showDropDownCountry: false,
            showDropDownLanguage: false,
            scrollPosition: 0,
            showDropDownCategori: false,
            selectedCategory: "",
            isSubMenuOpen: false,
            token: "",
            catData: [],
            catAllData: [],
            subCat: "",
            selectedItem: "",
            dropAccount: false,
            showCartData: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        await super.componentDidMount();
        const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
        this.setState({ token: token })
        this.getCategory()
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: any): void {

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);        
        // Customizable Area Start       
        // Customizable Area End
    }
   
    handleDropdownClick = (label: any) => {
        const subCategories = label.sub_categories.map((subCategory: any) => subCategory.name);
        this.setState((prevState: any) => ({
            showDropDownCategori: true,
            isSubMenuOpen: !prevState.isSubMenuOpen,
            subCat: subCategories,
            selectedItem: label.name
        }));
    };

    handleAccountClick = (label: any) => {
        if (label === "Logout") {
            localStorage.removeItem('otp-token');
            localStorage.clear();
        }
    };

    handleToggleSubMenu = () => {
        this.setState((prevState: any) => ({
            isSubMenuOpen: !prevState.isSubMenuOpen,
        }));
    };

    handleDropdownOpen = () => {
        this.setState({ showDropDownCountry: !this.state.showDropDownCountry })
    }

    handleDropdownLanguageOpen = () => {
        this.setState({ showDropDownLanguage: !this.state.showDropDownLanguage })
    }

    handleAccountOpen = () => {
        this.setState({ dropAccount: !this.state.dropAccount })
    }

    handleDrodownCat = () => {
        this.setState({ showDropDownCategori: !this.state.showDropDownCategori })
    }
   
    handleScroll = (scrollType: any) => {
        const container = document.getElementById('scroll-container');
        const scrollAmount = 200;
        const newPosition =
            scrollType === 'right'
                ? this.state.scrollPosition + scrollAmount
                : this.state.scrollPosition - scrollAmount;

        if (container) {
            container.scrollTo({
                top: 0,
                left: newPosition,
                behavior: 'smooth',
            });
        }

        this.setState({ scrollPosition: newPosition })
    };

    cartOpen = () => {
        this.setState({ showCartData: !this.state.showCartData })
    }

    getCategory = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCatList = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_categories/categories/category_list")
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }    

    render() {

        return (
            <>
                <NavigationBlock>
                    <Box sx={webStyle.navbar}>
                        <Box sx={webStyle.mainBlock}>
                            <MasterFinances >
                                <IconButton onClick={this.props.toggleSlider}>
                                    <Menu />
                                </IconButton>
                            </MasterFinances>
                            <img className="logo" src={require("./kx_logo.jpg")} alt="kxLogo" />

                            <Box sx={webStyle.searchContent}>
                                <InputBase data-testId="SearchButtonTestId" style={{ color: "#94A3B8", fontFamily: 'Inter, sans-serif', borderBottom:"none" }} placeholder='Search Product' onChange={this.props.handleSearchFun} />
                                <Box >
                                    <img className='search-icon' src={require("./search.jpg")} alt="search" />
                                </Box>
                            </Box>
                        </Box>


                        <Box style={webStyle.rcBlock}>
                            <Box style={webStyle.boxStyles}>
                                RC
                            </Box>
                            <Box sx={webStyle.typoBox}>

                                <Typography style={webStyle.typo}>Reality Craft</Typography>
                            </Box>
                        </Box>

                    </Box>

                </NavigationBlock>

            </>
        )
    }
}

const MasterFinances = styled(Box)({
    display: "none",
    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
        display: "block !important",
    },

});
const webStyle = {
    mainBlock: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
    },
    rcBlock: {
        display: "flex",
        flexDitection: "row"
    },
    boxStyles: {
        borderRadius: '50%',
        backgroundColor: '#fff',
        color: "orange",
        borderColor: 'orange',
        borderWidth: '2px',
        borderStyle: 'solid',
        minWidth: '35px',
        minHeight: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            // fontSize:"15px",
            // minWidth: '20px !important',
            // minHeight: '20px !important',

        },
    },
    typoBox: {
        display: "flex",
        justifyContent: 'center',
        fontSize: "16px",
        alignItems: 'center',
        '@media (max-width: 600px)': {
            '& .MuiTypography-body1': {
                fontSize: "10px !important"
            }
        },
    },
    typo: {
        marginLeft: "10px",
        fontWeight: 700,
        fontFamily: 'Inter, sans-serif',
    },
    loginText: {
        marginLeft: "10px",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: 'Inter, sans-serif',
        cursor: "pointer"
    },

    profileIcon: {
        color: "#FFFFFF",
        fontSize: "30px",
    },
    profileIconContainer: {
        height: "44px",
        width: '44px',
        borderRadius: "50%",
        bgcolor: "#FF6600",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        '@media (max-width: 600px)': {
            width: "30px",
            height: "30px",
            padding: "3px"
        }
    },
    bulkOrder: {
        fontWeight: 700, fontFamily: 'Inter, sans-serif', fontSize: { xs: "12px", sm: "16px" }, minWidth: "max-content"
    },
    navbar: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        padding: "5px",
        height: "76px",
        paddingRight: "20px"
    },
    languageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
    displayBox: {
        display: { xs: "none", md: 'block', lg: "block" }
    },
    searchContent: {
        marginLeft: "50px",
        margin: "0 10px",
        display: "flex",
        flexDirection: "row",
        border: "1px solid #CBD5E1",
        padding: "0px 12px",
        minWidth: "350px",
        height: "40px",
        justifyContent: "space-between",
        borderRadius: "10px",
        alignItems: "center",
        fontSize: "50px",
        '@media (max-width: 600px)': {
            '& .MuiTypography-body1': {
                fontSize: "10px !important"
            },
            minWidth: "75px !important",
            marginLeft: "15px !important"
        },

        '@media (max-width: 900px) and (min-width: 600px)': {
            minWidth: "200px !important"
        }


    },
    product: {
        '@media (max-width: 600px)': {
            display: "none",
        }
    },

    navItems: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: "30%",
        padding: '0 5px'
    }
};
export default DashboardNavbar