import React from "react";

import {
  Typography,
  Grid,
  Box,
  Hidden
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { imgDown } from "./assets";


const GeneralBlock = styled("div")(({ theme }) => ({
    paddingLeft:"24px",
    cursor:"default",
   "& .input-billing": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    padding: "10px",
    pointerEvents: "none",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    backgroundColor: "#f4f4f4", 
    color: "#888", 
   },
   "& .input:focus": {
    borderColor:"#FF6600",
    outline: "none", 
   },
   "& .label-text": {
    fontSize: "14px",
    color:"#272727",
    fontWeight: 700,
   },
   "& .input-billing-edit": {
    height: "44px",
    borderRadius: "8px",
    marginTop: "10px",
    padding: "10px",
    width: "97%",
    border: "1px solid #CBD5E1",
   },
   "& .comm-input-edit": {
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    width: "436px",
    height: "44px",
    borderRadius: "8px",
    padding: "10px"
   },
   "& .comm-input": {
    backgroundColor: "#f4f4f4", 
    color: "#888", 
    width: "436px",
    height: "44px",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "10px",
    pointerEvents: "none",
   },
  '& .dropdown-list-language-edit': {
    display: "flex",
    alignItems:"center",
    justifyContent:"space-between",
    flexDirection: "row",
    color: "#888", 
    cursor: "pointer !important",
    height: "21px",
    borderRadius: "8px",
    padding: "10px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    width: "93%",
  },
  '& .dropdown-list-language': {
    cursor: "pointer !important",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems:"center",
    justifyContent:"space-between",
    color: "#888", 
    pointerEvents: "none",
    border: "1px solid #CBD5E1",
    height: "21px",
    borderRadius: "8px",
    marginTop: "10px",
    width: "93%",
    backgroundColor: "#f4f4f4", 
  },
  "& .dropdown-list-data-language": {
    position: "absolute",
    maxHeight: "350px",
    backgroundColor:"#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    fontFamily: "Red Hat Display",
    top: "66px !important",
    cursor: "pointer",
    zIndex: 100,
    padding: "5px",
    width: "94.5% !important",
    overflowY: "auto",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list-data-language li": {
    margin: "15px 0px",
    padding: "2px 19px !important",
  },
  "& .dropdown-item:hover": {
    borderRadius:"8px",
    backgroundColor: "#FFE0CC",
  },
  "& .error-msg": {
    marginTop: "5px",
    color: "red",
    fontSize: "12px"
  },
  }))
// Customizable Area End

import BillingAddBuyerController, {
  Props,
} from "./BillingAddBuyerController";

export default class BillingAddBuyer extends BillingAddBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    return (
      // Customizable Area Start
      <GeneralBlock>
        <Grid container style={{ marginTop:"35px"}} spacing={2} data-test-id="main-div">
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">Street</Typography>
            <input data-test-id="street-input" id="company" className={this.props.isEdit ? "input-billing-edit" : "input-billing"} type="text" value={this.state.addressData.street} name="street" onChange={this.handleBillingInfoChange}></input>
          </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
              <Box style={{position:"relative"}}>
                <Typography className="label-text">City</Typography>
                <Box data-test-id="city-drop-btn" className={this.props.isEdit ? 'dropdown-list-language-edit':'dropdown-list-language'} onClick={this.handleDropdownCity}>
                    <Typography style={{ fontSize:"14px"}}>{this.state.selectedOption}</Typography>
                    <img style={{ mixBlendMode: "multiply"}} src={imgDown} alt="arrow" />
                </Box>                   

                <Hidden only="xs">
                {this.state.showDropDownCity && (
                  <div>
                    <ul className="dropdown-list-data-language">
                    <input
                      type="text"
                      value={this.state.searchTerm}
                      style={{ width: "100%"}}
                      className="search-data"
                      onChange={this.handleSearchChange}
                      data-test-id="search-btn-city"
                      placeholder="Search city..."
                    />
                      {this.state.filteredCitieData && this.state.filteredCitieData.map((datas: {name:string}) => (
                        <li
                          className="dropdown-item"
                          id="dropdowndata"
                          onClick={() => this.handleCityDropdownChange(datas.name)}
                          data-test-id="dropdown-data-cities"
                        > 
                          <div>{datas.name}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                </Hidden>
              </Box>
            </Grid>   
        </Grid>

        <Grid container style={{ marginTop:"31px" }} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Box>
              <Box style={{position:"relative"}}>
                <Typography className="label-text">State<span style={{ color: "red"}}>*</span></Typography>
                <Box data-test-id="state-btn" className={this.props.isEdit ? 'dropdown-list-language-edit':'dropdown-list-language'} onClick={this.handleDropdownState}>
                    <Typography style={{ fontSize:"14px"}}>{this.state.selectedStateOption}</Typography>
                    <img style={{ mixBlendMode: "multiply" }} src={imgDown} alt="down_arrow" />
                </Box>
                <Typography className="error-msg">{this.props.fieldRequiredData}</Typography>
                <Hidden only="xs">
                {this.state.showDropDownState && (
                  <div>
                    <ul className="dropdown-list-data-language">
                    <input
                      onChange={this.handleStateSearch}
                      className="search-data"
                      data-test-id="search-btn-state"
                      style={{ width:"100%"}}
                      type="text"
                      placeholder="Search state..."
                      value={this.state.searchStateTerm}
                    />
                      {this.state.filteredStateData.map((datas: {name:string}) => (
                        <li
                          className="dropdown-item"
                          onClick={() => this.handleStateChange(datas.name)}
                          data-test-id="dropdown-data-state"
                          id="dropdowndata"
                        > 
                          <div>{datas.name}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                </Hidden>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">Country<span style={{ color:'red'}}>*</span></Typography>
            <input data-test-id="company-name" id="company" className="input-billing" type="text" value={this.state.selectedCountryOption} name="country" onChange={this.handleBillingInfoChange}></input>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop:"31px" }} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">Zip Code</Typography>
            <input data-test-id="company-name" id="company" className={this.props.isEdit ? "input-billing-edit" : "input-billing"} type="text" value={this.state.addressData.zipcode} name="zipcode" onChange={this.handleBillingInfoChange}></input>
          </Grid>
        </Grid>
      </GeneralBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End