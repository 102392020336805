Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "peritempricing";
exports.labelBodyText = "peritempricing Body";
exports.createProductPath = "/bx_block_productdescription/seller_products"
exports.searchProductPath = "/bx_block_productdescription/seller_products?"
exports.btnExampleTitle = "CLICK ME";
exports.deleteProductPath = "/bx_block_productdescription/seller_products/"
exports.updatePricePath = "/bx_block_productdescription/bulk_price_update"
exports.productPricingListPath = "/bx_block_productdescription/seller_products?"
exports.viewProductListPath = "/bx_block_productdescription/seller_products/"
exports.priceUpdatePath = "/bx_block_productdescription/price_update/"
exports.priceDeletePath = "/bx_block_productdescription/seller_products/delete_price/"
// Customizable Area End