import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from 'framework/src/Utilities';

interface ProductAttribute {
  id: number;
  name: string;
  brand_name: string;
  price: number | null;
  prices: number[];
  change: string;
  change_up: boolean;
  description: string;
  manufacturer: string;
  specification: string;
  applications: string;
  certifications: string;
  is_recommended: boolean;
  status: string;
  product_id: string;
  images: string;
  category: string;
  sub_category: string;
  brand: string;
  updated: string;
  is_new: string;
  is_best_seller: boolean;
  product_feature: {
    data: string[];
  };
}

interface Product {
  id: string;
  type: string;
  attributes: ProductAttribute;
}

interface Product {
  id: string;
  name: string;
}
interface Category {
  id: number;
  name: string;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  selected_sub_categories: string; 
  user_category: string; 
  updated_at: string;
  sub_categories: SubCategory[];
}

interface SubCategory {
  updated_at: string;
  parent_id: number | null;
  id: number;
  name: string;
  created_at: string;
  rank: number | null;
  products: Product[];
}
interface BrandAttributes {
  id: number;
  name: string;
  currency: number;
  created_at: string;
  updated_at: string;
}

interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleToggleSidebar : ()=> void;
  passState: Function;
  passSubCat: Function;
  passBrand: Function;
  // Customizable Area End
}

interface S {
  openDialog: boolean;
  // Customizable Area Start
  subCatData: any;
  tokenData: string;
  catData: Category[];
  selectedCategoryId: number | null;
  selectedSubCategoryId: number | null;
  brandNamesList:{id:number,name:string}[];
  isChecked: number[];
  searchString: string;
  // catBlock:boolean
  priceRangeValue:number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryList: string = "";
  getBrandsList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openDialog: false,  
      subCatData: [],
      tokenData:"",
      catData: [],
      selectedCategoryId: null,
      selectedSubCategoryId: null,
      brandNamesList:[],
      isChecked: [],
      searchString: "",
      priceRangeValue:[15, 80]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    const tokenData = await getStorageData('otp-token');
    this.setState({ tokenData: tokenData})
    this.getCategoryData();
    this.getBrandsData();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.getCategoryList) {
      const dataCat = responseJson?.data?.map((datas: Product) => {
          return datas.attributes
        })
        dataCat? this.setState({ catData: dataCat}):this.setState({ catData: []})
    }
    if (apiRequestCallId == this.getBrandsList) {
      const brandData: { id: number; name: string }[] = responseJson?.data.map((data: Brand) => ({
        id: data.attributes.id,
        name: data.attributes.name,
      }))
      this.setState({ brandNamesList: brandData})
    }
    // Customizable Area End
  }
 

  // Customizable Area Start

  handleToggle = () => {
    this.setState({ openDialog: !this.state.openDialog});
  };
 
  handleCatClick = (data:any) => {
    if (data === null) {
      this.setState({ selectedCategoryId: null, openDialog: !this.state.openDialog });
      this.props.passState({ selectedCategoryId: null });
    }else{
      this.setState({subCatData: data, selectedCategoryId: data.id, openDialog: !this.state.openDialog});
      this.props.passState({ selectedCategoryId: data.id });
    }
    
  }

  handleSubCatClick = (subCategoryId: number) => {
    this.setState({ selectedSubCategoryId: subCategoryId });
    this.props.passSubCat({selectedSubCategoryId: subCategoryId })
  };

  getCategoryData = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.tokenData,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoryList = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/categories")
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getBrandsData = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.tokenData,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandsList = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_catalogue/brands")
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleCheckboxChange = (item: {id: number, name: string}) => {
    const isChecked = this.state.isChecked.includes(item.id) ? this.state.isChecked.filter((idData) => idData !== item.id) : [...this.state.isChecked, item.id];
    this.setState({ isChecked }); 
    this.props.passBrand({ isChecked }); 
  }

  handleSearch = (event:ChangeEvent<HTMLInputElement>) => {
  
    const searchString = event.target.value.toLowerCase();

    this.setState({ searchString }); // 
  }

  handleChange1 = (
    event: ChangeEvent<{}>,
    newValue: number | number[],
  ) =>{
    if (!Array.isArray(newValue)) {
      return;
    }
    
    const minDistance = 10;
    const [newStart, newEnd] = newValue;
  
    const activeThumb = this.state.priceRangeValue[0] === newStart ? 0 : 1;
  
    if (activeThumb === 0) {
      this.setState({
        priceRangeValue: [
          Math.min(newStart, this.state.priceRangeValue[1] - minDistance),
          this.state.priceRangeValue[1],
        ],
      });
    } else {
      this.setState({
        priceRangeValue: [
          this.state.priceRangeValue[0],
          Math.max(newEnd, this.state.priceRangeValue[0] + minDistance),
        ],
      });
    }
  };
  valuetext(value: number) {
    return `$ ${value}`;
  }
  // Customizable Area End
}
