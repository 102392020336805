import React from "react";
import {
  Table, 
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  Button,
  Checkbox,
  Typography,
  Box,
  Select,
  MenuItem,
} from "@material-ui/core";
// Customizable Area Start


import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import { createTheme,styled } from "@material-ui/core/styles";

// Customizable Area End


// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AiproductsearchandselectionController, {
  Props,
  configJSON,
} from "./AiproductsearchandselectionController";
import { Pagination } from "@material-ui/lab";
import SideBar from '../../../components/src/SideBar.web';

export default class Aiproductsearchandselection extends AiproductsearchandselectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    const { page, rowsPerPage } = this.state;
    const totalCount = this.state.proCount;
    const rangeStart = page * rowsPerPage + 1; 
    const emptyRows = this.calculateEmptyRows(rowsPerPage, totalCount, page);
    const rangeEnd = this.calculateRangeEnd(page, rowsPerPage, totalCount);   

    return (
      // Customizable Area Start
      <SideBar navigation={this.props.navigation} handleSearchFun={(event)=>this.handleSearchProduct(event)}>
        <Box style={{ padding: "20px", backgroundColor: "#F8FAFC" }}>
          <Box style={webStyle.headDiv}>
            <Box style={webStyle.iconBox} role="go-back" onClick={this.goBack}>
              <ArrowBackIosIcon style={{ fontSize: "15px" }} />
            </Box>
            <Typography style={webStyle.button}>
              Select Products
            </Typography>
          </Box>
          <Box sx={webStyle.dropdownBox}>
            <FormControl variant="outlined" style={webStyle.formControl}>
              <MuiSelectSelect
                value={this.state.categories}
                data-testId='categories-selection'
                onChange={(event) => this.handleChangeCat(event)}
                inputProps={{
                  name: 'categories',
                  id: 'outlined-age-native-simple',
                  style: { width: "100%", height: "20px" }
                }}
              >
                <MenuItem value={0} data-testId="cat-text">All Categories</MenuItem>
                 {this.state.categoriesList.map((option) => (
                   <MenuItem key={option.attributes.id} value={option.attributes.id}>{option.attributes.name}</MenuItem>
                ))}
              </MuiSelectSelect>
            </FormControl>

            <FormControl variant="outlined" style={webStyle.formControl}>
              <MuiSelectSelect
                onChange={(event) => this.handleChangeSubCat(event)}
                value={this.state.subCategories}
                inputProps={{
                  name: 'subCategories',
                  id: 'outlined-age-native-simple',
                  style: { width: "100%", height: "20px" }
                }}
                data-testId='sub-categories-selection'
                MenuProps={{ PaperProps: { style: { maxHeight: '300px' } } }}
              >
                <MenuItem value={0} data-testId="sub-cat-text">All Sub Categories</MenuItem>
                {this.state.subCategoryList?.map((option) => (
                  <MenuItem key={option.attributes.id} data-testId="sub-cat-list" value={option.attributes.id}>{option.attributes.name}</MenuItem>
                ))}
              </MuiSelectSelect>
            </FormControl>

            <FormControl variant="outlined" style={webStyle.formControl}>
              <MuiSelectSelect
                data-testId='manufacture-selection'
                value={this.state.manufacturer}
                onChange={(event) => this.handleChangeManu(event)}
                inputProps={{
                  name: 'manufactures',
                  style: { width: "100%", height: "20px" },
                  id: 'outlined-age-native-simple',                  
                }}
              >
                   <MenuItem value={"0"} data-testId="manu-text">All Manufacturers</MenuItem>
                {this.state.manufacturersList?.map((option) => {
                  return(                  
                  <MenuItem key={option.id} id="menu" value={option.value}>{option.value}</MenuItem>
                  )
  })}
              </MuiSelectSelect>
            </FormControl>
          </Box>

          <TableContainer component={Paper}  >
            <Table >
              <TableHead style={webStyle.tableHeader}>
                <TableRow >
                  <TableCell style={{ ...webStyle.head, minWidth: 200 }}>Product</TableCell>
                  <TableCell style={webStyle.head} data-testId="product-id" align='center'>Product ID</TableCell>
                  <TableCell style={webStyle.head} data-testId="product-manu" align='center'>Manufacture</TableCell>
                  <TableCell style={webStyle.head} data-testId="product-cat" align='center'>Category</TableCell>
                  <TableCell style={webStyle.head} data-testId="product-sub-cat" align='center'>Sub-Category</TableCell>
                  <TableCell style={webStyle.head} align='right'>
                    <Checkbox checked={this.state.allChecked} data-testId="all-product-checks" onChange={this.handleAllChnage} style={{ color: "gray", }} />
                    Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
                  { this.state.dataList.map((data: {
                  id: string;
                  type: string;
                  attributes: {
                    id: number;
                    brand_name: string;
                    prices: number[];
                    price: number | null;
                    change_up: boolean;
                    name: string;
                      change: string;
                      specification: string;
                      applications: string;
                      description: string;
                      manufacturer: string;
                      certifications: string;
                      product_id: string;
                      is_recommended: boolean;
                      images: {
                        content_type: string;
                          file_name: string;
                          id: number;
                          url: string;
                      }[];
                      sub_category: string;
                      category: string;
                      updated: string;
                  };
              }, index: number) => {
                  const isSelected = this.state.allSelectedIds.includes(data.attributes.id)

                  return (
                    <TableRow key={index} style={{ backgroundColor: isSelected ? "#edeef0" : "#fff" }}>
                      <TableCell style={webStyle.mainBox}>
                        <img src={data.attributes.images && data.attributes.images[0].url} width="40px" height="40px" style={webStyle.image} />
                        <Box style={webStyle.discBox}>
                          <Typography style={webStyle.categoryName}>{data.attributes.name} </Typography>
                          <Typography style={webStyle.disc} data-testId="product-descId">{this.getProductDescription(data.attributes.description)}</Typography>
                        </Box>

                      </TableCell>
                      <TableCell data-testId="product-id" align='center'>{data.attributes.product_id}</TableCell>
                      <TableCell data-testId="product-manufacture" align='center'>{data.attributes.manufacturer}</TableCell>
                      <TableCell data-testId="product-category" align='center'>{data.attributes.category}</TableCell>
                      <TableCell data-testId="product-sub-category" align='center'>{data.attributes.sub_category}</TableCell>
                      <TableCell align='right' style={{ paddingRight: "55px", minWidth: "100px" }}>
                        <Checkbox checked={isSelected} data-testId="product-checks" style={{ color: "#ff8000" }} onChange={(event) => this.handleRowClick(event, data.attributes.id)} />
                        <ExpandMoreIcon style={{ fontSize: "18px" }} />
                      </TableCell>
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow data-testId="emptyRows" style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box data-testId="paginationBox" style={webStyle.paginationBox}>
              <Box style={webStyle.paginationStyle}>
                <span>
                  {rangeStart} - {rangeEnd} of {totalCount} results
                </span>
              </Box>

              <Pagination
                count={Math.ceil(this.state.proCount / this.state.rowsPerPage)}
                page={page + 1}             
                onChange={(event, value) => this.handleChangePage(event, value - 1)}
                data-testId="pagination"
                style={{ padding: "20px" }}
              />

            </Box>
          </TableContainer>
          <Box style={webStyle.btnBox}>
            <Button onClick={this.navigateToProduct} data-testId="save-buttom" style={{ ...webStyle.btn, textTransform: "none" }} >Save</Button>
          </Box>
        </Box>
        </SideBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MuiSelectSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
      borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #979797',borderRadius: '8px'
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "1px solid #979797",borderRadius: '8px'
  },
})
const webStyle = {
  paginationStyle: { padding: "20px", opacity: 0.8 },
  headDiv: {
    display: "flex"
  },
  paginationBox: { display: "flex", justifyContent: "space-between" },
  dropdownBox: { marginTop: '20px', marginBottom: '10px' },
  iconBox: {
    width: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #C8C8C8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  head: {
    fontWeight: 700, color: '#808080', fontSize: "16px", minWidth: "max-content"
  },
  button: {
    fontWeight: 700, fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "7px",
  },
  image: {
    border: "5px solid #ffe6e6",
    borderRadius: "5px"
  },
  mainBox: {
    marginLeft: 0,
    minWidth: 250,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  formControl: {
    width: '200px', marginRight: "20px", marginBottom: "10px"
  },
  tableHeader: {
    backgroundColor: '#f1f4f9',
  },
  table: {
    display: "flex",
    justifyContent: "space-between",
  },
  lastRow: {
    display: "flex",
    justifyContent: "end",
  },
  btn: {
    width: "160px", color: "#fff", background: "#090A17", borderRadius: "10px", padding: "8px", fontSize: "18px", opacity: 0.9
  },
  btnBox: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px"
  },
  disc: {
    fontSize: "11px"
  },
  discBox: {
    marginLeft: "20px",
    minWidth: "max-content",
  },
  categoryName: {
    fontFamily: "Roboto-Medium",
    fontSize: "15px"
  },

};
// Customizable Area End
