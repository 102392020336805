import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { logo, designTop, designBottom, roundLeft, login, flag, usaFlag } from "./assets";


const LoginBlock = styled("div")(({ theme }) => ({
  background:"#FFFFFF",
  cursor:"default",
  "& .logo": {
    width: "290px",
    height:" 174px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex:1,
  },  
  "& .logo-box": {
    width: "100px",
    height:"50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex:1,
  },
  "& .bottom-design": {
    width: "216px",
    height: "216px",
    display: "flex",
    position: "absolute",
    bottom: 0,
    right: "50%",
    zIndex:0,
  },
  "& .top-design": {
    width: "216px",
    height: "216px",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex:0,
  },
  "& .main-block": {
    width:"100%",
    display:"flex"
  },
  "& .round-left": {
    width: "38px",
    height: "38px",
    position: "absolute",
    top: "70%",
    left: "10%",
    zIndex:0,
  },
  "& .round-right": {
    position: "absolute",
    top: "27%",
    left: "39%",
    zIndex:0,
  },
  "& .login-text": {
    fontSize:"22px",
    fontWeight:700,
    fontFamily: 'Inter, sans-serif',
    marginTop: "10px"
  },
  "& .login-content": {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    borderRight: "1px solid #FFC299",
    height:"100vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:900px)": {
      display:"none",
    },
  },
  "& .login-content-input": {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,   
    height:"100vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  "& .detail-text": {
    fontSize:"14px",
    fontWeight:400,
    color:"#334155",
    fontFamily: 'Inter, sans-serif'
  },
  "& .login-block": {
    display: "flex",
    flexDirection: "column",
  },
  "& .mobile-text": {
    fontSize:"14px",
    fontWeight:700,
    fontFamily: 'Inter, sans-serif',
    color:"#334155",
    justifyContent:"flex-start",
    display:"flex"
  },
  '& .mui-field': {
    borderRadius: '10px',
    border: '2px solid #FAFAFA',
    width:"70%",
  },
  "& .mobile-block":{
    display: "flex",
    flexDirection: "column",
  },
  '& .MuiFilledInput-root': {
    color:"hsla(240,7%,62%,1)",
  },
  "& .otp-btn": {
    width: "126px",
    height: "44px",
    backgroundColor: "#FFA76C",
    borderRadius: "12px",
    marginTop: "26px",
    color: "#FFFFFF"
  },
  "& .otp-btn-active": {
    width: "126px",
    height: "44px",
    backgroundColor: "#FF6600",
    borderRadius: "12px",
    marginTop: "26px",
    color: "#FFFFFF",
  },
  "& .sign-up": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px"
  },
  '& .country-code': {
    gap: "10px",
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid transparent",
    width: "83px",
    padding: "10px",
    borderRadius: "8px 0px 0px 8px",
    height: "22px",
    backgroundColor:"#F3F4F6",
    color:"#BCC1CA",
    fontWeight:400,
    fontSize:"16px"
  },
  '& .country-code-red': {
    gap: "10px",
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid transparent",
    width: "83px",
    padding: "10px",
    borderRadius: "8px 0px 0px 8px",
    height: "22px",
    backgroundColor:"#FEE8E8",
    color:"#BCC1CA",
    fontWeight:400,
    fontSize:"16px",
  },
  "& .dropdown-list-data-language": {
    fontFamily: 'Inter, sans-serif',
    position: "absolute",
    maxHeight: "400px",
    top: "50px",
    backgroundColor:"#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "94px",
  },
  "& .dropdown-list-data-language li": {
    padding: "0px 18px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .phone-input": {
    width:"296px", 
    height:"44px", 
    padding:"10px", 
    marginTop:"4px", 
    border: "1px solid transparent",
    borderRadius: "0px 8px 8px 0px",
    backgroundColor:"#F3F4F6",
    borderLeftColor:"#C4C4C4",
    color:"#171A1F",
    fontWeight:400,
    fontSize:"16px",
    resize: "none"
  },
  "& .phone-input::placeholder":{
    color:"#BCC1CA",
    opacity:1,
    fontWeight:400,
    fontSize:"16px",
    fontFamily:"Inter, sans-serif"
  },
  "& .phone-input-error": {
    width:"400px", 
    height:"42px", 
    borderRadius:"8px", 
    padding:"10px", 
    marginTop:"12px", 
    border: "1px solid #DC2626",
  },
  "& .phone-input-red": {
    width:"296px", 
    height:"45px", 
    padding:"10px", 
    marginTop:"4px", 
    border: "1px solid transparent",
    borderRadius: "0px 8px 8px 0px",
    backgroundColor:"#FEE8E8",
    borderLeftColor:"#C4C4C4",
    color:"#171A1F",
    fontWeight:400,
    fontSize:"16px",
    resize: "none"
  },
  "& .flag-icon": {
    width: "29px",
    height: "20px",
    borderRadius: "3px"
  },
  "& .error-text": {
    fontSize: "12px", 
    fontFamily:"Inter, sans-serif",
    fontWeight:400, 
    color:"#DC2626", 
    marginTop:"7px",
    display:"flex"
  },
  "& .phone-input[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .phone-input-red[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .phone-input-red[type=number]::-webkit-inner-spin-button,.phone-input[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .phone-input[type=number]::-webkit-inner-spin-button,.phone-input[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .logo-text":{
    fontWeight:500,
    fontSize:"18px",
    color:"#334155",
    marginTop:"-15px",
    zIndex:1,
  },
  "& .desc":{
    textAlign: "center",
    marginTop:"10px", 
    width: "77%",
    color:"#334155",
    zIndex:1,
  },
  "& .logo-desc":{
    fontWeight: 600,
    fontSize:"18px",
    color:"#2B2A29",
    marginTop:"30px",
    zIndex:1,
  },
  "& .displayLogo":{
    display: "none",   
    "@media (max-width:900px)": {
      marginBottom:"20px",
      display:"flex", alignItems:"center", justifyContent:"center",
    },
  },
  "& .dropdownMenu":{
    border:"none",
    backgroundColor:"transparent",
    fontSize:"16px",
    "& select":{
      display: "none",
    },
  },
 
}))
// Customizable Area End

import SignuploginController, {
  Props,
} from "./SignuploginController";

export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
   
    return (
      // Customizable Area Start
      <>
      <LoginBlock>
        <Box className="main-block">
          <Box className="login-content">
            <img className="top-design" src={designTop} alt="designTop" />
            <img className="round-left" src={roundLeft} alt="designTop" />
            <img className="logo" src={logo} alt="logo" />
            <Typography className="logo-text">KRATEX tagline goes here</Typography>
            <Typography className="logo-desc">Lorem ipsum dolor sit amet</Typography>
            <Typography className="desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a .Praesent aliquet, quam non lobortis feugia.</Typography>
            <img className="round-right" src={roundLeft} alt="designTop" />
            <img className="bottom-design" src={designBottom} alt="designBottom" />
          </Box>      
         
          <Box className="login-content-input">
            <Box>
              <Box style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <Box style={{textAlign:"center"}}>
                  <Box className="displayLogo" >
                    <img className="logo-box" src={logo} alt="logo" />
                  </Box>
                  <img style={{width: "56px",height: "56px"}} src={login} alt="login"/>
                  <Typography className="login-text">Welcome!</Typography>
                  <Typography className="detail-text">Please enter your details.</Typography>
                <Box style={{marginTop:"20px"}}>
                  <Typography className="mobile-text">Mobile Number</Typography>


                  <Box style={{position:"relative",display:"flex"}}>
                    <Box className={!this.state.isValid || this.state.requireMsg ? "country-code-red" : "country-code"}>
                      <img className='flag-icon' src={this.state.selectedFlag=='+1'? usaFlag : flag} alt="location" id="otp"/>
                      <Typography>
                        <select className="dropdownMenu" onChange={this.handleCode}>
                          <option value="+91">
                            +91
                          </option>
                          <option value="+1">
                            +1
                          </option>
                        </select> 
                      </Typography> 
                      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0.75L4 4.25L7.5 0.75L0.5 0.75Z" fill="#807E7E"/>
                        </svg>
                    </Box>

                    <textarea
                      id="phone-number" 
                      data-test-id = "phone-data"
                      className={!this.state.isValid || this.state.requireMsg ? "phone-input-red" : "phone-input"}
                      value={this.state.phoneNumber}
                      placeholder="Enter your contact no."
                      onChange={this.handlePhoneNumberChange}                      >
                    </textarea>
                  </Box>
                </Box>
                {!this.state.isValid ? <Box className="error-text">Only Validation for Indian user: 10 digit</Box> : ""}
                {this.state.requireMsg && this.state.requireMsg.length ? <Box className="error-text">{this.state.requireMsg}</Box> : ""}
                <Box style={{marginTop:"10px", textAlign:"start"}}>
                  <Button className={this.state.fullNumberField?"otp-btn-active":"otp-btn"} disabled={this.state.fullNumberField ? false : true} data-test-id="otp-btn" onClick={this.handleOtp}>Send OTP</Button>
                </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </LoginBlock>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End