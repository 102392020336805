import React from "react";

import {
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";

const BankDetailsBlock = styled("div")(({ theme }) => ({
    paddingLeft:"24px",
    cursor:"default",
    "& .label-text": {
        fontSize:"14px",
        fontWeight:700,
        fontFamily: 'Inter, sans-serif',
    },
    "& .bank-data": {
        width: "97%",
        height: "44px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        padding:"10px",
        pointerEvents: "none",
        backgroundColor: "#f4f4f4", 
        color: "#888", 
    },
    "& .bank-data-edit": {
        width: "97%",
        height: "44px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        padding:"10px",  
    },
    "& .error-msg": {
      color: "red",
      marginTop: "5px",
      fontSize: "12px"
    },
  }))
// Customizable Area End

import BankDetailsBuyerController, {
  Props,
} from "./BankDetailsBuyerController";

export default class BankDetailsBuyer extends BankDetailsBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    return (
      // Customizable Area Start
      <BankDetailsBlock>
        <Grid container style={{ marginTop:"35px"}} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
                <Typography className="label-text">Bank Name<span style={{ color:"red"}}>*</span></Typography>
                <input data-test-id="bank-name" className={this.props.isEdit ? "bank-data-edit" : "bank-data"} type="text" name="banknane" value={this.state.bankData.banknane} onChange={this.handleBankInfoChange}></input>
                <Typography className="error-msg">{this.props.bankNameErr}</Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
                <Typography className="label-text">Beneficiary Name</Typography>
                <input className={this.props.isEdit ? "bank-data-edit" : "bank-data"} type="text" name="beneficiaryname" value={this.state.bankData.beneficiaryname} onChange={this.handleBankInfoChange}></input>
            </Grid>
        </Grid>

        <Grid container style={{ marginTop:"35px"}} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
                <Typography className="label-text">Account Number</Typography>
                <input
                    className={this.props.isEdit ? "bank-data-edit" : "bank-data"}
                    data-test-id="account-input"
                    type="text"
                    onChange={this.handleBankInfoChange}
                //   onBlur={this.handleBlur}
                    name="accountnumber"
                  value={this.state.bankData.accountnumber}
                    placeholder="xxxxxxxxxxxx"
                />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
              <Typography className="label-text">IFSC Code</Typography>
              <input className={this.props.isEdit ? "bank-data-edit" : "bank-data"} type="text" name="ifsccode" value={this.state.bankData.ifsccode} onChange={this.handleBankInfoChange}></input>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop:"39px"}}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">Branch Name</Typography>
            <input data-test-id="bank-name" className={this.props.isEdit ? "bank-data-edit" : "bank-data"} type="text" name="branchname" value={this.state.bankData.branchname} onChange={this.handleBankInfoChange}></input>
          </Grid>
        </Grid>
      </BankDetailsBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End