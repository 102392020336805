import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Drawer, Collapse, Grid, Typography, } from '@material-ui/core';
import { Apps, ContactMail, AssignmentInd, Home } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DashboardController from '../../blocks/dashboard/src/DashboardController.web';
import { styled } from '@material-ui/styles';
import DashboardNavbar from './DashboardNav.web';
import CreateIcon from '@material-ui/icons/Create';
import { getStorageData } from "../../framework/src/Utilities";
import { setStorageData } from "../../framework/src/Utilities";
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
export interface Props {
    navigation: any;
    location: string;
    activeMenu: string,
    activeTab: string;
    children: React.ReactNode;
    handleSearchFun: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const userListItems = [
    {
        listIcon: <CreateIcon />, listText: 'Quotation', to: "/quotation"
    },
    { listIcon: <ReceiptIcon />, listText: 'Billing', to: "Home", },
    { listIcon: <ShoppingCartIcon />, listText: 'Sales', to: "/" },
    { listIcon: <ContactMail />, listText: 'Payments', to: "/", },
    { listIcon: <AssignmentInd />, listText: 'E-way Bills', to: "/", },
    { listIcon: <LocalShippingIcon />, listText: 'Delivery', to: "/" },
    {
        listIcon: <ListAltIcon />, listText: 'Catalogue',
        items: [
            {
                title: "Categories",
                to: "Catalogue1",
            },
            {
                title: "Products",
                to: "Aiproductsearchandselection",
            },
            {
                title: "Pricing",
                to: "Peritempricing",
            },
            {
                title: "Terms & Notes",
                to: "Termsandconditions",
            },
        ]
    },
    { listIcon: <ContactMail />, listText: 'My products', to: "/my-products" },
    { listIcon: <AccountCircleIcon />, listText: 'My Profile', to: "/my-profile" },
    { listIcon: <ContactMail />, listText: 'Contacts', to: "/contacts" },
];
const adminListItems = [
    {
        listIcon: <Home />, listText: 'Quotation', to: "/"
    },
    { listIcon: <AssignmentInd />, listText: 'Purchases', to: "Home", },
    { listIcon: <ContactMail />, listText: 'Payments', to: "/", },
    { listIcon: <AssignmentInd />, listText: 'E-way Bills', to: "/", },
    {
        listIcon: <Apps />, listText: 'Catalogue',
        items: [
            {
                title: "Categories",
                to: "/categories",
            },
            {
                title: "Products",
                to: "/products",
            },
            {
                title: "Pricing",
                to: "/pricing",
            },
            {
                title: "Terms & Notes",
                to: "/TermsConditions",
            },
        ]
    },
    { listIcon: <ContactMail />, listText: 'My products', to: "/" },
    { listIcon: <AssignmentInd />, listText: 'My Profile', to: "/" },
    { listIcon: <Apps />, listText: 'Portfolio', to: "/" },
    { listIcon: <ContactMail />, listText: 'Contacts', to: "/" },
];

class SideBar extends DashboardController {
    constructor(props: Props) {
        super(props)

    }
    async componentDidMount() {
        const activePath = await getStorageData('activePath');
        if (activePath) {
            this.setState({
                open: {
                    ...this.state.open,
                    [`open${activePath}`]: true
                }
            });
        }
    }

    sideList = () => {
        const listTextStyle = { marginRight: "40px", width: "max-content", }

        const activeListItemStyle = {
            fontWeight: 700,
            background: '#F8FAFC',

        };

        const isActive = (index: number, path?: string, listItems?: { title: string, to: string }[]) => {
            if (listItems && listItems.length > 0) {
                return listItems.map(element => element.to).includes(location.pathname.slice(1))
            } else {
                return location.pathname === `/${path}` && path !== undefined;
            }
        }

        const handleClick = async (index: number, isTopLevel: boolean, path: string) => {
            if (isTopLevel) {
                this.setState((prevState) => ({
                    open: {
                        ...prevState.open,
                        [`open${index}`]: !prevState.open[`open${index}`],
                    },
                }));
                await setStorageData('activePath', String(index));
            }
        };

        return (
            <Box component="div">
                <List>
                    {
                        this.state.role === "user" ?
                            (userListItems.map((listItem, index: number) => (
                                <div key={index}>
                                    <ListItem button data-testId="sidebar-menu-item" onClick={() => handleClick(index, true, listItem.to || '')}
                                    >
                                        <ListItemIcon  >
                                            {listItem.listIcon}
                                        </ListItemIcon>
                                        <Box component={'div'} style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                                            <Box component={'div'} ><Typography style={{
                                                ...(isActive(index, listItem.to || '', listItem.items) ? activeListItemStyle : {}),
                                            }}>{listItem.listText}</Typography></Box>
                                            <Box component={'div'}>
                                                {listItem.items && (this.state.open[`open${index}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />)}</Box>
                                        </Box>
                                    </ListItem>

                                    {listItem.items && (
                                        <Collapse in={!isActive(index, listItem.to || '') && this.state.open[`open${index}`]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {listItem.items.map((item, idxs) => (
                                                    <ListItem
                                                        button
                                                        key={idxs}
                                                        onClick={(event) => {
                                                            this.getNavigationMessage(item.to);
                                                        }}
                                                    >
                                                        <Typography style={{
                                                            marginLeft: "75px",
                                                            fontWeight: isActive(index, item.to || '') ? "bold":400
                                                        }}>{item.title}</Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                </div>
                            ))) :

                            (adminListItems.map((listItem, index: any) => (
                                <div key={index}>
                                    <ListItem onClick={() => handleClick(index, true, listItem.to || '')}  >

                                        <ListItemIcon  >
                                            {listItem.listIcon}
                                        </ListItemIcon>
                                        <ListItemText primary={listItem.listText} onClick={() => this.getNavigationMessage(listItem.to)} style={listTextStyle} />
                                        {listItem.items && (this.state.open[`open${index}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />)}

                                    </ListItem>
                                    {listItem.items && (
                                        <Collapse in={!isActive(index, listItem.to || '') && this.state.open[`open${index}`]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {listItem.items.map((item, idxs) => (
                                                    <ListItem button key={idxs}
                                                        style={{
                                                            marginLeft: "75px",
                                                        }}

                                                        onClick={() => this.getNavigationMessage(item.to)}>

                                                        <ListItemText primary={item.title} style={{ marginLeft: "60px" }} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                </div>
                            )))
                    }
                </List>
            </Box>
        );
    };


    // Customizable Area End

    render() {
        // Customizable Area Start
        // const handleSearch = this.props;
        // const handleChange = this.props.handleSearchFun


        return (
            //Merge Engine DefaultContainer
            <>
                <DashboardNavbar toggleSlider={this.toggleSlider} handleSearchFun={this.props.handleSearchFun} />
                <Box component="nav" style={{ backgroundColor: "#fff" }}>
                    <Grid container spacing={1}>
                        <Grid item lg={2} md={3} >

                            <MasterFinances >
                                <Drawer open={this.state.toggle} anchor="left" onClose={this.toggleSlider} >
                                    {this.sideList()}
                                </Drawer>
                            </MasterFinances>
                            <SidebarBLock >
                                {this.sideList()}
                            </SidebarBLock>

                        </Grid>
                        <Grid item lg={10} md={9} sm={12} xs={12}>
                            <Box style={{ background: "#F2F2F2 !important" }}>
                                {this.props.children}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const MasterFinances = styled(Box)({
    display: "none",
    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
        display: "block !important",
    },
});
const SidebarBLock = styled(Box)({
    display: "block",

    height: "100%",
    marginRight: "5px",
    borderRight: "1px solid grey",
    "@media (max-width: 600px)": {
        display: "none !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
        display: "none !important",
    },
});
export default SideBar;
// Customizable Area End
