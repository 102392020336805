import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react';


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any

}

interface S {
  corosolTopImage: string[];
  corosolBottomImage: string[];
  token: string | null;
  page:number;
  material:string;
  subMaterial:string;
  allMaterial:any;
  allSubmaterial:any;
  selMatId:number;
  selSubMatId:number;
  allMatProd:any;
  selNumPage:number;
  numberPages:number;
  openFilter:boolean;
  openPages:boolean;
  locationFilter:string[];
  selLocation:string;
}

interface SS {
  id: any;
}

export default class PriceController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTopCorosole: string = "";
  getBottomCorosole: string = "";
  getAllMat:string="";
  getAllSubMat:string="";
  getProdMat:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      corosolTopImage: [],
      corosolBottomImage: [],
      token: "",
      openFilter:false,
      openPages:false,
      page:0,                 //selected page
      numberPages:10,         //for pagination
      selNumPage:10,           //number of cards
      material:"Steel",
      allMaterial:[],
      subMaterial:"TMT Bar",
      allSubmaterial:[],
      selMatId:2,
      selSubMatId:2,
      selLocation:'',
      locationFilter:[],
      allMatProd:[
        {
          "id": "2",
          "type": "material_product",
          "attributes": {
              "id": 2,
              "material_id": 2,
              "sub_material_id": 2,
              "name": "Fe550 TMT Bar",
              "sub_name": "Primary 0.4",
              "brand": "RR Kabel",
              "location": "Surat",
              "price": 58000.0,
              "updated": "about 3 hours ago",
              "created_at": "2024-08-22T13:51:21.384Z",
              "updated_at": "2024-08-22T13:51:21.384Z"
          }
      },
      {
        "id": "1",
        "type": "material_product",
        "attributes": {
            "id": 1,
            "material_id": 1,
            "sub_material_id": 1,
            "name": "uspolo",
            "sub_name": "us",
            "brand": "new",
            "location": "knr",
            "price": 100.0,
            "updated": "1 day ago",
            "created_at": "2024-08-21T13:48:23.324Z",
            "updated_at": "2024-08-21T13:48:23.324Z"
        }
      }
      ]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
    this.setState({ token: token});
    await this.getAllMaterials();
    await this.getSubMaterials(2);
    await this.getProdMaterials(2, 2)
    this.updateNumberPages()  
  }
  updateNumberPages(){

    this.setState({ numberPages:Math.floor(this.state.allMatProd.filter((elem:any)=>elem.attributes.sub_material_id==this.state.selSubMatId).length/(this.state.selNumPage))+1});
  }

  async receive(from: string, message: Message) {
    
    runEngine.debugLog("All mat", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.getAllMat) {
      responseJson?.data?.map((elem:any)=>this.setState({allMaterial:[...this.state.allMaterial, {name:elem.attributes.name, id:elem.attributes.id}]}))
      
    }
    if(apiRequestCallId == this.getAllSubMat){
      responseJson?.data?.map((elem:any)=>{
        const exists = this.state.allSubmaterial.some((subMat:any) => subMat.id === elem.attributes.id);
        
        if (!exists) {
        this.setState({allSubmaterial:[...this.state.allSubmaterial, {name:elem.attributes.name, id:elem.attributes.id, matId:elem.attributes.material_id}]})
        }
      })
    }
    
    if (apiRequestCallId == this.getProdMat) {
      responseJson?.data?.map((elem:any)=>{
        const exists = this.state.allMatProd.some((prod: any) => prod.id === elem.id);
        if (!exists) {
          this.setState({allMatProd:[...this.state.allMatProd, elem]})
        }
      })
      this.updateNumberPages();
      this.updateLocations();
    }
    // Customizable Area Start
    
  }

  handleNavigate = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Signuplogin'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleMatId = (id:number) => {
    if(this.state.selMatId == id){
      this.setState({selMatId:0, page:0})
    }else{
      this.setState({selMatId:id, page:0})
      this.getSubMaterials(id);
    }    
  }
  handleSubMatId = (id:number) => {
    this.setState({selSubMatId:id, page:0},  ()=>{
      this.getProdMaterials(this.state.selMatId, this.state.selSubMatId)
    });
    
  }
  changeNumberPages = (n:number)=>{
    this.setState({selNumPage:n, openPages:false});
    this.updateNumberPages();
  }
  getAllMaterials = async () => {
    
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getAllMat = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      "bx_block_categories/materials")
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    
  }
  getSubMaterials = async (id:number) => {
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getAllSubMat = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `bx_block_categories/sub_materials?material_id=${id}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getProdMaterials = async (matId:number, subMatId:number) => {
    const headers = {
      token: this.state.token,
    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getProdMat = getValidationsMsg.messageId

  getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
  
      `/bx_block_categories/material_products?material_id=${matId}&sub_material_id=${subMatId}`)
      

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  renderPages(s: number) {
    let symbol: any;
    const p = s + 1;
    if (this.state.numberPages <= 4) {

      symbol = p;
    } else {

      if (p === 1 || p === this.state.numberPages) {
        symbol = p;
      } else if (p === this.state.page) {
        symbol = p;
      } else if (p === this.state.page-1 || p === this.state.page+1) {
        symbol = p;
      } else if ((p === 2 && this.state.page > 3) || (p === this.state.numberPages - 1 && this.state.page < this.state.numberPages - 2)) {
        symbol = (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00223 9.14118C8.63341 9.14118 9.14509 8.62951 9.14509 7.99833C9.14509 7.36714 8.63341 6.85547 8.00223 6.85547C7.37105 6.85547 6.85938 7.36714 6.85937 7.99833C6.85937 8.62951 7.37105 9.14118 8.00223 9.14118Z" stroke="#565D6D" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
              <path d="M2.85379 9.14118C3.48498 9.14118 3.99665 8.62951 3.99665 7.99833C3.99665 7.36714 3.48498 6.85547 2.85379 6.85547C2.22261 6.85547 1.71094 7.36714 1.71094 7.99833C1.71094 8.62951 2.22261 9.14118 2.85379 9.14118Z" stroke="#565D6D" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
              <path d="M13.1429 9.14118C13.774 9.14118 14.2857 8.62951 14.2857 7.99833C14.2857 7.36714 13.774 6.85547 13.1429 6.85547C12.5117 6.85547 12 7.36714 12 7.99833C12 8.62951 12.5117 9.14118 13.1429 9.14118Z" stroke="#565D6D" stroke-width="1.37143" stroke-miterlimit="10" stroke-linecap="square"/>
          </svg>);
      } else {
        symbol = null;
      }
    }
  
    return symbol;
  }
  changePage(newPage:number){
    if(newPage>=0&&newPage<this.state.numberPages){
      this.setState({page:newPage})
    }
  }
  updateLocations(){
    this.setState({locationFilter:[]});
    this.state.allMatProd.filter((elem:any) => elem.attributes.sub_material_id==this.state.selSubMatId).map((e:any)=>{
      const exists = this.state.locationFilter.some((prod: any) => prod === e.attributes.location);
      if(!exists){
        this.setState({locationFilter:[...this.state.locationFilter, e.attributes.location]})
      }
    })   
  }
  changeLocation(newLocation:string){
    if(newLocation==this.state.selLocation){
      this.setState({selLocation:''})
    }else{
      this.setState({selLocation:newLocation})
    }
    this.setState({openFilter:false})
  }
  comparison(cond:any, classN:string): string {
    return cond ? `${classN}-active` : classN;
  }
}