import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface GeneralData {
  companyname: string;
  contact: string;
  email: string;
  phonenumber: string;
  [key: string]: string; 
}

interface FieldRequire {
  companyname: string;
  contact: string;
  email: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        user_id: string;
        phone: string
      }
    }
  }
  handleNext: () => void
  // Customizable Area Start
  currentStep: number
passState:Function,
generalStateData: GeneralData
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  otpText: string;
  otpTextError: string;
  openDialog: boolean;
  currentStep: number;
  isChecked: boolean;
  generalData: GeneralData;
  fieldRequiredData: FieldRequire;
  showDropDownCom: boolean;
  selectedComOption: string;
  validMsg: string;
    // Customizable Area Start
    openPrivacy:boolean,
    loader: boolean
    modalTitle: string,
    termsContent: {
      id: number,
      title: string,
      description: string,
      created_at: string
    }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GeneralInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsConditionId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      otpText: "",
      otpTextError: "",
      openDialog: false,
      currentStep: 1,
      isChecked: false,
      showDropDownCom: false,
      selectedComOption:"Pvt Ltd",
      generalData: { companyname: "",contact: "", email: "", phonenumber:""},
      fieldRequiredData: {companyname:'', contact:'', email:''},
      validMsg:"",
            // Customizable Area Start
            openPrivacy:false,
            loader: false,
            modalTitle: '',
            termsContent: {
              id: 0,
              title: '',
              description: '',
              created_at: ''}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTermsConditionId) {
        console.log('responseJson.data',responseJson.data)
        this.setState({
          termsContent: responseJson.data.attributes,
          openPrivacy:true,
          modalTitle: responseJson.data.attributes.title,
         
        }
        )
        setTimeout(() => {
           this.setState({
          loader: false}) 
        }, 500);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({generalData: {...this.props.generalStateData}})
    this.setState({ selectedComOption: this.props.generalStateData.selectedComOption})
    this.setState({ generalData: { ...this.state.generalData, phonenumber: this.props.history.location.state.phone } });
    localStorage.setItem("user-id",this.props.history.location.state.user_id);
  }

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked})
  };

  validateEmail = (email: string) => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  handleGeneralInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
  
    if (name === "companyname" || name === "contact") {
      if (/^[a-zA-Z\s]*$/.test(value) || value === '') { 
        this.setState({ generalData: { ...this.state.generalData, [name]: value } });
        this.props.passState({ ...this.state.generalData, [name]: value, selectedComOption: this.state.selectedComOption });
      }
    } else {
      this.setState({ generalData: { ...this.state.generalData, [name]: value } });
      this.props.passState({ ...this.state.generalData, [name]: value, selectedComOption: this.state.selectedComOption });
    }
  
    if (name === 'email') {
      if (value) {
        if (this.validateEmail(value)) {
          this.setState({ validMsg: '' }); 
        } else {
          this.setState({ validMsg: 'Email is invalid' }); 
        }
      } else {
        this.setState({ validMsg: '' });
      }
    }
  
  
    this.setState((prevState) => ({}));
    this.setState({
      fieldRequiredData: {
        ...this.state.fieldRequiredData,
        [name]: '',
      },
    });
  }
  

  handleRequire = () => {
    const field = this.state.generalData;
    const message = "Required field";
    const requiredFields: any[] = ["companyname", "contact", "email", "phonenumber"];
  
    const missingFields = requiredFields.filter(fieldKey => !field[fieldKey]);
  
    const fieldRequiredData = requiredFields.reduce((accumulater:Record<string, string>, fieldKey:string) => {
      
      accumulater[fieldKey] = missingFields.includes(fieldKey) ? message : "";
      return accumulater;
    }, {});
  
    this.setState({ fieldRequiredData });
  
    return missingFields.length === 0;
  };
  

  handleValidation = () => {
    const field = this.state.generalData;

      if(field.companyname && field.contact && field.email &&  this.state.isChecked){
        this.props.handleNext()      
      }
      else{
        this.setState({isChecked: false})
        this.handleRequire()
      }
  }   

    handleDropdownCom = () => {
      this.setState({ showDropDownCom: !this.state.showDropDownCom })
    }

    handleComChange = (event: string) => {
      this.setState({ selectedComOption: event, showDropDownCom: false});
      this.props.passState({...this.state.generalData, selectedComOption:event})
    };

  // Customizable Area Start
   handleClickOpenPrivacy = (title: string) => {
    this.setState({
      loader: true,
      openPrivacy:true,
    })
    this.getPrivacyAndTermsData(title)
  };
  getPrivacyAndTermsData = (title: string) => {
    const getValMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsConditionId = getValMsg.messageId;
    getValMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsConditionPath + title
    );
    getValMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValMsg.id, getValMsg);
  }
  handleClosePrivacy = () => {
  this.setState({
    openPrivacy:false
  })
  };
  // Customizable Area End
}