import React from "react";

import { Typography, Grid, Box, Hidden } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import ArrowUp from '@material-ui/icons/KeyboardArrowUpOutlined';
import ArrowDown from '@material-ui/icons/KeyboardArrowDownOutlined';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import PriceController, { Props } from "./PriceController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";


export const PriceBlock = styled("div")(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  cursor:"default",
    "& .filter__category-active":{
        color:"#FF6600",
        display:"flex",
        justifyContent:"space-between",
        width:"100%"
    },
    "& .MuiTypography-body1":{
        fontFamily: 'Inter, sans-serif',
    },
    "& .filter":{
        width:"230px",
        border:"1px solid #DEE1E6",
        borderRadius:"12px",
        padding:"14px 22px",
        height:"auto",
        maxHeight: "400px",
        overflowY:"scroll",
        "@media (max-width:900px)": {
            marginLeft:"42px"
        },
    },
    "& .filter__category__text-active":{
        fontSize: "14px",
        fontWeight: 700,
        cursor:"pointer"
    },
    "& .filter__subcategory-active":{
        fontSize: "14px",
        fontWeight: 500,
        color:"#323743",
        cursor:"pointer"
    },
    "& .filter__subcategory":{
        fontSize: "14px",
        fontWeight: 400,
        color:"#9095A1",
        cursor:"pointer"
    },
    "& .filter__subcategory-s":{
        padding:"9px 0px"
    },
    "& .filter__category":{
        color:"#323743",
        display:"flex",
        justifyContent:"space-between",
        width:"100%",
        padding:"8px 0px",
        cursor:"pointer"
    },
    "& .price":{
        margin:"16px 42px",
        "@media (max-width:900px)": {
            marginTop:"16px",
            marginLeft:"0px",
            marginRight:"0px",
            marginBottom:"16px",
        },    
    },
    "& .price__list__header":{
        display:"flex",
        justifyContent:"space-between",
        width:"100%",
        "@media (max-width:900px)": {
            marginLeft:"42px"
        },
    },
    "& .price__list":{
        marginLeft:"40px",
        "@media (max-width:900px)": {
            marginLeft:"0px",
            marginTop:"10px"
        },    
    },
    "& .price__list__header__label":{
        fontSize: "21px",
        fontWeight: 500,
        color:"#171A1F",
        "@media (max-width:900px)": {
            fontSize: "18px",
        },
    
    },
    "& .price__list__header__button":{
        backgroundColor:"#F3F4F6",
        cursor:"pointer",
        borderRadius:"12px",
        textAlign:"center",
        padding:"6px 11px",
        display:"flex",
        marginLeft:"14px",
        borderColor:"transparent",
        "@media (max-width:900px)": {
            padding:"6px 5px",
            height:"50%"
        },
    },
    "& .price__list__header__buttons":{
        display:"flex",
        "@media (max-width:900px)": {
            height:"100%"
        },
    },
    "& .price__list__table":{
        border:"1px solid #DEE1E6",
        width:"100%",
        borderRadius:"12px",
        marginTop:"24px",
        textAlign:"center",
        overflow:"hidden",
        "@media (max-width:900px)": {
            width:"100%",
            marginLeft:"2px"
        },    
    },
    "& .th":{
        fontSize: "14px",
        fontWeight: 500,
        color:"#565D6D",
        padding:"12px 5px",
        "@media (max-width:900px)": {
            fontSize: "12px",
        },
    },
    "& .td":{
        padding:"16px 7px",
        "@media (max-width:900px)": {
            padding:"16px 0px",
        },
    },
    "& .price__list__table__products__text__main":{
        fontSize: "14px",
        fontWeight: 700,
        color:"#323743",
        "@media (max-width:900px)": {
            fontSize: "10px",
        },
    },
    "& .price__list__table__products__text__sec":{
        fontSize: "12px",
        fontWeight: 400,
        color:"#565D6D",
        "@media (max-width:900px)": {
            fontSize: "10px",
        },
    },
    "& .price__list__table__brand__text":{
        fontSize: "12px",
        fontWeight: 400,
        color:"#000000",
        backgroundColor:"#F7F7F7",
        borderRadius:"14px",
        padding:"4px 8px",
        "@media (max-width:900px)": {
            fontSize: "10px",
        },
    },
    "& .price__list__table__brand":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    "& .price__list__table__location__text":{
        fontSize: '12px',
        fontWeight: 400,
        color: '#FF6600',
        backgroundColor: '#FFF6F0',
        borderRadius: '14px',
        display: 'inline-block',
        padding: '4px 8px',
        marginTop:"-10px",
        "@media (max-width:900px)": {
            fontSize: "10px",
        },
    },
    "& .priceListTableLocation": {
        display: "grid",
        placeItems: "center",
    },
    "& .price__list__table__prices__text__main":{
        fontSize: "14px",
        fontWeight: 400,
        color:"#171A1F",
        "@media (max-width:900px)": {
            fontSize: "12px",
        },
    },
    "& .price__list__table__prices__text__sec":{
        fontSize: "12px",
        fontWeight: 400,
        color:"#565D6D",
        "@media (max-width:900px)": {
            fontSize: "10px",
        },
    },
    "& .price__list__table__updated__text":{
        fontSize: "14px",
        fontWeight: 400,
        color:"#171A1F",
        "@media (max-width:900px)": {
            fontSize: "10px",
        },
    },
    "& .price__list__table__icons":{
        width:"70px",
        cursor:"pointer"
    },
    "& .price__list__table__header":{
        backgroundColor:"#FAFAFB"
    },
    "& .price__list__table__zebra":{
        backgroundColor:"#FAFAFB"
    },
    "& .price__list__page":{
        display:"flex",
        marginTop:"23px",
        "@media (max-width:900px)": {
        justifyContent:"center"
    }
    },
    "& .price__list__page__number-active":{
        color:"#FFFFFF",
        backgroundColor:"#FF6600",
        borderRadius:"12px",
        padding:"7px 12px",
        margin:"0px 4px",
        fontSize: "14px",
        fontWeight: 400,
        cursor:"pointer"
    },
    "& .price__list__page__number":{
        color:"#565D6D",
        backgroundColor:"#F3F4F6",
        borderRadius:"12px",
        padding:"7px 12px",
        margin:"0px 4px",
        fontSize: "14px",
        fontWeight: 400,
        display:"flex",
        alignItems:"center",
        cursor:"pointer"
    },
    "& .price__list__page__arrow":{
        marginTop:"5px",
        cursor:"pointer"
    },
    "& .right":{
        marginLeft:"6px"
    },
    "& .left":{
        marginRight:"6px"
    },
    "& .table__ico":{
        margin:"0px 4px"
    },
    "& .navigMenu":{
        marginLeft:"48px",
        "@media (max-width:900px)": {
            width:"100vw",
            marginLeft:"0px",
            paddingLeft:"48px"
        },
    },
    "& .pagesFilter":{
        borderRadius:"12px",
        backgroundColor:"#F3F4F6",
        position:"absolute",
        top:"230px",
        right:"160px",
        "@media (max-width:900px)": {
            top:"660px",
        },
    },
    "& .pagesFilter__text":{
        fontFamily:"Inter, sans-serif",
        fontWeight:400,
        fontSize:"14px",
        color:"#323842",
        padding:"6px 12px",
    },
    "& .pagesFilter__text-active":{
        fontFamily:"Inter, sans-serif",
        fontWeight:400,
        fontSize:"14px",
        color:"#323842",
        borderRadius:"8px",
        backgroundColor:"#FFF",
        padding:"6px 12px",
    },
    "& .locationFilter":{
        borderRadius:"12px",
        backgroundColor:"#F3F4F6",
        position:"absolute",
        top:"230px",
        right:"40px",
        "@media (max-width:900px)": {
            top:"660px",
        },
    },
    "& .locationFilter__text-active":{
        fontFamily:"Inter, sans-serif",
        fontWeight:400,
        fontSize:"14px",
        color:"#323842",
        borderRadius:"8px",
        backgroundColor:"#FFF",
        padding:"6px 12px",
    },
    "& .locationFilter__text":{
        fontFamily:"Inter, sans-serif",
        fontWeight:400,
        fontSize:"14px",
        color:"#323842",
        padding:"6px 12px",
    },
    "& .perPageIco":{
        "@media (max-width:900px)": {
            width:"30",
            height:"30"
        },
    }
}));

export default class Price extends PriceController {
  constructor(props: Props) {
    super(props);
  }
  pricelist = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  render() {
    return (
      <div>
        <Navbar history={this.props.history} handleNavigate={() => this.handleNavigate()} data-test-id="nav-bar"/>
        <PriceBlock>
            <Grid container direction="column">
                <Grid item>
                    <Typography>
                        <Grid container direction="row" alignItems="center" style={webStyle.navMenu} className="navigMenu">
                            <Grid item style={webStyle.navMenuItem} id="navigation">
                                <span>Prices</span>           
                            </Grid>
                            <ArrowForward style={webStyle.arroForw}/>
                            <Grid item style={webStyle.navMenuItem} id="navigation">
                                <span data-test-id='materialName'>{this.state.allMaterial.find((elem: any) => elem.id === this.state.selMatId)?.name}</span>                            
                            </Grid>    
                            {this.state.allSubmaterial?.find((elem: any) => elem.matId === this.state.selMatId)&&
                            <>
                                <ArrowForward style={webStyle.arroForw}/>
                                <Grid item style={webStyle.navMenuItem} id="navigation">
                                    <span data-test-id='subMaterialName'>{this.state.allSubmaterial?.find((elem: any) => elem.id === this.state.selSubMatId)?.name}</span>        
                                </Grid>
                            </>}          
                        </Grid>
                    </Typography>
                </Grid>
                <Grid item >
                    <Grid container direction="row" className="price">
                        <Grid item direction="column" xs={8} sm={8} md={2} lg={2} className="filter">
                            <Grid container direction="column">
                                {
                                    this.state.allMaterial?.map((elem:any, id:number)=>{
                                        return(
                                        <>
                                            <Grid item direction="row" 
                                                justifyContent="space-between" 
                                                className={this.comparison(this.state.selMatId == elem.id, "filter__category")}
                                                data-test-id='materialId' 
                                                onClick={()=>this.handleMatId(elem.id)}
                                            >
                                                <Typography 
                                                    className={this.comparison(this.state.selMatId == elem.id, "filter__category__text")}
                                                >
                                                    {elem.name}
                                                </Typography>
                                                {this.state.selMatId == elem.id ? 
                                                <ArrowUp className="filter__category__arrow-active" fontSize="medium"/> :
                                                <ArrowDown className="filter__category__arrow" fontSize="medium"/>}
                                            </Grid>
                                            {this.state.selMatId == elem.id && this.state.allSubmaterial?.filter((e:any)=>e.matId == elem.id).map((sub:any, id:number)=>{
                                                return(
                                                    <Grid item direction="row" justifyContent="space-between" data-test-id="filter__subcategory-test" className="filter__subcategory-s" key={id} onClick={()=>this.handleSubMatId(sub.id)}>
                                                        <Typography className={this.comparison(this.state.selSubMatId == sub.id, "filter__subcategory")}>
                                                            {sub.name}
                                                        </Typography>
                                                    </Grid>
                                                )                                                
                                            })}
                                        </>
                                        )
                                    })
                                }
                                
                                
                            </Grid>
                        </Grid>
                        <Grid item direction="column" xs={10} sm={10} md={9} lg={9} className="price__list">
                            <Grid container direction="row">
                                <Grid item direction="row" className="price__list__header">
                                    <Typography className="price__list__header__label">
                                        {this.state.allSubmaterial?.find((elem: any) => elem.id === this.state.selSubMatId)?.name} Prices Today
                                    </Typography>
                                    <Box className="price__list__header__buttons">
                                        <button className="price__list__header__button" data-test-id='btn-page' onClick={()=>this.setState({openPages:true})}>
                                            {this.state.selNumPage} per page
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="perPageIco">
                                                <path d="M10 3L10 12" stroke="#171A1F" stroke-width="1.2" stroke-miterlimit="10"/>
                                                <path d="M2 6L10 6" stroke="#171A1F" stroke-width="1.2" stroke-miterlimit="10"/>
                                                <path d="M2 9L10 9" stroke="#171A1F" stroke-width="1.2" stroke-miterlimit="10"/>
                                                <path d="M12 3L3 3C2.44772 3 2 3.44772 2 4L2 11C2 11.5523 2.44772 12 3 12L12 12C12.5523 12 13 11.5523 13 11L13 4C13 3.44772 12.5523 3 12 3Z" stroke="#171A1F" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
                                            </svg>
                                        </button>
                                        <button className="price__list__header__button" data-test-id='btn-location' onClick={()=>this.setState({openFilter:true})}>
                                            Location
                                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 3.5L8 7.5L8 11.5L6 12.5L6 7.5L2 3.5L2 2L12 2V3.5Z" stroke="#171A1F" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
                                            </svg>
                                        </button>
                                    </Box>
                                    <Hidden>
                                        {this.state.openPages&&
                                            <Box className="pagesFilter">
                                                <Typography className={this.comparison(this.state.selNumPage==10, "pagesFilter__text")} data-test-id='btn-selector-page' onClick={()=>this.changeNumberPages(10)}>
                                                    10 per page
                                                </Typography>
                                                <Typography className={this.comparison(this.state.selNumPage==20, "pagesFilter__text")} data-test-id='btn-selector-page' onClick={()=>this.changeNumberPages(20)}>
                                                    20 per page
                                                </Typography>
                                                <Typography className={this.comparison(this.state.selNumPage==50, "pagesFilter__text")} data-test-id='btn-selector-page' onClick={()=>this.changeNumberPages(50)}>
                                                    50 per page
                                                </Typography>
                                            </Box>
                                        }
                                    </Hidden>
                                    <Hidden>
                                        {this.state.openFilter&&
                                            <Box className="locationFilter">
                                                {this.state.locationFilter.map((elem, i)=>{
                                                    return(
                                                    <Typography className={this.comparison(this.state.selLocation==elem,"locationFilter__text")} data-test-id='location-btn' onClick={()=>this.changeLocation(elem)} key={i}>
                                                        {elem}
                                                    </Typography>
                                                    )
                                                })}
                                                
                                            </Box>
                                        }
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <table className="price__list__table" cellSpacing="0" cellPadding="0">
                                    <tr className="price__list__table__header">
                                        <th className="th">
                                            Products
                                        </th>
                                        <th className="th">
                                            Brand
                                        </th>
                                        <th className="th">
                                            Location
                                        </th>
                                        <th className="th">
                                            Prices
                                        </th>
                                        <th className="th">
                                            Updated
                                        </th>
                                        <th className="price__list__table__icons">

                                        </th>
                                    </tr>
                                    {this.state.allMatProd?.filter((prd:any)=>prd.attributes.sub_material_id==this.state.selSubMatId).filter((prd:any)=>{
                                        if (this.state.selLocation === "") {
                                            return true;
                                          }
                                        return prd.attributes.location === this.state.selLocation;
                                    }).slice(this.state.page*this.state.selNumPage, this.state.page*this.state.selNumPage+this.state.selNumPage).map((elem:any, i:number)=>{
                                        return(
                                            <tr className={i%2!==0?"price__list__table__zebra":""}>
                                                <td className="td">
                                                    <Typography className="price__list__table__products__text__main">
                                                       {elem.attributes.name}
                                                    </Typography>
                                                    <Typography className="price__list__table__products__text__sec">
                                                    {elem?.attributes.sub_name} 
                                                    </Typography>
                                                </td>
                                                <td className="price__list__table__brand td">
                                                    <Typography className="price__list__table__brand__text">
                                                    {elem?.attributes.brand}                                                    
                                                    </Typography>                                                    
                                                </td>
                                                <td className="price__list__table__location td">
                                                    <Typography className="price__list__table__location__text">
                                                    {elem?.attributes.location}
                                                    </Typography>                                                    
                                                </td>
                                                <td className="td">
                                                    <Typography className="price__list__table__prices__text__main">
                                                        Rs. {elem?.attributes.price}
                                                    </Typography>
                                                    <Typography className="price__list__table__prices__text__sec">
                                                        {elem?.attributes.price_change_percentage}% Change
                                                    </Typography>                                                    
                                                </td>
                                                <td className="td">
                                                    <Typography className="price__list__table__updated__text">
                                                    {elem?.attributes.updated} 
                                                    </Typography>                                                    
                                                </td>
                                                <td className="price__list__table__icons td">
                                                <svg className="table__ico" data-test-id="graph-link" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>{this.props.history.push(`/PriceHistory/${this.state.selMatId}/${this.state.selSubMatId}/${elem?.attributes.id}`)}}>
                                                    <path d="M9.14621 2.9375L6.86049 2.9375C6.54506 2.9375 6.28906 3.1935 6.28906 3.50893L6.28906 13.7946C6.28906 14.1101 6.54506 14.3661 6.86049 14.3661H9.14621C9.46163 14.3661 9.71763 14.1101 9.71763 13.7946L9.71763 3.50893C9.71763 3.1935 9.46163 2.9375 9.14621 2.9375Z" fill="#565D6D"/>
                                                    <path d="M3.99777 9.22266H1.71205C1.39663 9.22266 1.14062 9.47866 1.14062 9.79408L1.14062 13.7941C1.14062 14.1095 1.39663 14.3655 1.71205 14.3655H3.99777C4.3132 14.3655 4.5692 14.1095 4.5692 13.7941L4.5692 9.79408C4.5692 9.47866 4.3132 9.22266 3.99777 9.22266Z" fill="#565D6D"/>
                                                    <path d="M14.2868 6.36719L12.0011 6.36719C11.6857 6.36719 11.4297 6.62319 11.4297 6.93862L11.4297 13.7958C11.4297 14.1112 11.6857 14.3672 12.0011 14.3672H14.2868C14.6023 14.3672 14.8583 14.1112 14.8583 13.7958L14.8583 6.93862C14.8583 6.62319 14.6023 6.36719 14.2868 6.36719Z" fill="#565D6D"/>
                                                </svg>
                                                <svg className="table__ico" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.17187 15.5112L2.13628 11.9896C1.54141 10.9591 1.22839 9.78992 1.22888 8.59219C1.23038 4.84524 4.27992 1.79688 8.02684 1.79688C9.84534 1.7976 11.5522 2.50537 12.8356 3.78986C14.1191 5.07437 14.8255 6.78179 14.8248 8.59761C14.8233 12.3446 11.7735 15.3933 8.02693 15.3933H8.02413C6.88638 15.3929 5.76848 15.1075 4.77557 14.5662L1.17187 15.5112ZM4.94253 13.3358L5.14885 13.4583C6.01634 13.9729 7.01076 14.2452 8.0246 14.2456H8.02691C11.1411 14.2456 13.6758 11.7117 13.677 8.59718C13.6776 7.08793 13.0905 5.66878 12.0237 4.60115C10.9569 3.53351 9.53829 2.94521 8.02915 2.94464C4.91255 2.94464 2.37788 5.47831 2.37663 8.59263C2.3762 9.65986 2.67492 10.6993 3.2405 11.5986L3.37486 11.8123L2.80406 13.8966L4.94253 13.3358Z" fill="#565D6D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33326 5.75059C6.19539 5.41948 6.05535 5.46428 5.95109 5.45909C5.85213 5.45416 5.73878 5.45312 5.62555 5.45312C5.51231 5.45312 5.3283 5.49562 5.1726 5.6656C5.01691 5.83559 4.57812 6.24641 4.57812 7.08217C4.57812 7.91794 5.18676 8.72537 5.27169 8.8387C5.35661 8.95203 6.46942 10.6672 8.17331 11.4027C8.57856 11.5776 8.89497 11.6821 9.14163 11.7604C9.54855 11.8896 9.91883 11.8714 10.2115 11.8276C10.5378 11.7789 11.2164 11.4169 11.358 11.0202C11.4995 10.6236 11.4995 10.2836 11.4571 10.2128C11.4146 10.1419 11.3014 10.0994 11.1315 10.0144C10.9617 9.92946 10.1266 9.51864 9.97086 9.46199C9.81517 9.40533 9.70195 9.377 9.58871 9.54699C9.47549 9.71696 9.14996 10.0994 9.05088 10.2128C8.95181 10.3261 8.85273 10.3403 8.68288 10.2553C8.51302 10.1703 7.96571 9.99096 7.31694 9.41247C6.81201 8.96224 6.47109 8.40614 6.37201 8.23615C6.27293 8.06616 6.36147 7.97425 6.4465 7.88961C6.5229 7.81353 6.61635 7.69129 6.70128 7.59213C6.7862 7.49297 6.81451 7.42214 6.87113 7.30882C6.92775 7.19549 6.89943 7.09633 6.85697 7.01134C6.8145 6.92635 6.47481 6.09057 6.33326 5.75059Z" fill="#565D6D"/>
                                                </svg>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-end" className="price__list__pages">
                                <Grid item direction="row" className="price__list__page">
                                    <ArrowLeft className="price__list__page__arrow left"  onClick={() => this.changePage(this.state.page - 1)}/>
                                    {Array.from({ length: this.state.numberPages }, (_, i) => (
                                        this.renderPages(i+1)&&<Typography className={this.comparison(this.state.page == i, "price__list__page__number")}  onClick={() => this.changePage(i)}>
                                            {this.renderPages(i)}
                                        </Typography>
                                    ))}
                                    <ArrowForward className="price__list__page__arrow right"  onClick={() => this.changePage(this.state.page + 1)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>           
        </PriceBlock>
        <Footer />
      </div>
    );
  }
}

const webStyle = {
    navMenu:{
        marginTop:"22px",
        fontFamily: 'Inter, sans-serif',
        fontSize: "14px",
        fontWeight: 400,
        
      },
      navMenuItem:{
        height:"19px",
        alignItems:"center",
        color:"#FF6600",
      },
      arroForw:{
        margin:"0px 10px",
        height:"16px"
      }
};
