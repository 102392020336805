import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import GeneralInfo from "../src/GeneralInfo.web";
import CompanyInfo from "../src/CompanyInfo.web";
import AddressInfo from "../src/AddressInfo.web";
import BankDetail from "../src/BankDetail.web";
import { Message } from "../../../framework/src/Message";

interface GeneralData {
  companyname: string;
  selectedComOption: string;
  contact: string;
  email: string;
  phonenumber: string;
  [key: string]: string; 
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        phone: string,
        user_id: string
      }
    },
    push: Function
    }
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  otpText: string;
  otpTextError: string;
  openDialog: boolean;
  currentStep: number;
  open: boolean;
  // Customizable Area Start
  generalStateData: GeneralData;
  addressStateData: {street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string};
  bankStateData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string};
  companyStateData: any;
  userId: number;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  generalRef = React.createRef<GeneralInfo>();
  companyRef = React.createRef<CompanyInfo>();
  addressRef = React.createRef<AddressInfo>();
  bankRef = React.createRef<BankDetail>();
  postGeneralData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      otpText: "",
      otpTextError: "",
      openDialog: false,
      currentStep: 1,
      open: false,
      // Customizable Area Start
      generalStateData:{ companyname: "",selectedComOption:"",contact: "", email: "", phonenumber:""},
      addressStateData: {street: "", zipcode: "", selectedOption: "", selectedStateOption: "", selectedCountryOption: ""},
      bankStateData: {banknane: "", beneficiaryname: "", accountnumber: "", ifsccode: "", branchname:""},
      companyStateData: null,
      userId: 0,
      loading:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    const localStorageData = (typeof localStorage !== "undefined" && localStorage.getItem('user-id')) || '';

    const number = parseInt(localStorageData)
    this.setState({ userId: number}) 
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId == this.postGeneralData && responseJson) {
        if(!responseJson.errors){ 
          localStorage.removeItem('user-id');
          this.setState({loading:false});
          this.handleOpen();
          return responseJson
        }
      }
    }


    // Customizable Area End
  }

  goToNext = () => {
    const { currentStep } = this.state;
    if(currentStep === 1){
      this.generalRef.current?.handleValidation();
      this.setState({ open: true})
    }else if(currentStep === 2){
      this.companyRef.current?.handleValidation();
      this.setState({ open: false})
    }
    else if(currentStep === 3){
      this.addressRef.current?.handleValidation();
    }
  }

  handleGeneralState = (generalData:{ companyname: "",selectedComOption:"",contact: "", email: "", phonenumber:""}) => {
    this.setState({...this.state, generalStateData:generalData})
      }

  handleCompanyState = (companyData:object) => {
    this.setState({...this.state, companyStateData:companyData})
  }

  handleAddressState = (addressData:{street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string}) => {
        this.setState({...this.state, addressStateData:addressData})
  }

  handleBankState = (bankData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string}) => {
    this.setState({...this.state, bankStateData:bankData})
  }
  handleNext = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep + 1 });
  };

  handleClose = () => {
    this.setState({ openDialog: false})
  };

  handleOpen= () => {
    this.setState({ openDialog: true})
  };

  handleContinue = () => {
    this.props.history.push("/Signuplogin")
  }

  handleClick = (data: number) => {
    const { currentStep } = this.state;

    if (data > currentStep) {
      if (this.validatePreviousStep(currentStep)) {
        this.setState({ currentStep: data });
      }
    } else if (data < currentStep) {
      this.setState({ currentStep: data });
    } else {
      return true; 
    }
  }

  validatePreviousStep = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return this.generalRef.current?.handleValidation();
      case 2:
        return this.companyRef.current?.handleValidation();
      case 3:
        return this.addressRef.current?.handleValidation();
      default:
        return true; 
    }
  };
  // Customizable Area Start
  handleSubmit = () => {
    this.setState({loading:true});
    this.submitForm();
  }
  // Customizable Area End

  submitForm = () => {
    
    const formDataObject = new FormData();
    formDataObject.append("data[attributes][full_phone_number]", this.state?.generalStateData?.phonenumber);
    formDataObject.append("data[company_informations_attributes][0][company_name]", this.state?.generalStateData?.companyname);

    formDataObject.append("data[company_informations_attributes][0][company_name]", this.state?.generalStateData?.companyname);
    formDataObject.append("data[company_informations_attributes][0][company_type]", this.state?.generalStateData?.selectedComOption);
    formDataObject.append("data[company_informations_attributes][0][contact_name]", this.state?.generalStateData?.contact);
    formDataObject.append("data[company_informations_attributes][0][email]", this.state?.generalStateData?.email);
    formDataObject.append("data[company_informations_attributes][0][full_phone_number]", this.state?.generalStateData?.phonenumber);

    formDataObject.append("data[company_informations_attributes][0][document_detail_attributes][gstin]", "09AAACH7409R1ZZ");
    formDataObject.append("data[company_informations_attributes][0][document_detail_attributes][company_name]", "Bestpeers");
    formDataObject.append("data[company_informations_attributes][0][document_detail_attributes][pdf]", this.state.companyStateData.uploadCertificate);

    formDataObject.append("data[company_informations_attributes][0][address_attributes][biling_address]", "abc");
    formDataObject.append("data[company_informations_attributes][0][address_attributes][street]", this.state.addressStateData.street);
    formDataObject.append("data[company_informations_attributes][0][address_attributes][city]", this.state.addressStateData.selectedOption);
    formDataObject.append("data[company_informations_attributes][0][address_attributes][state]", this.state.addressStateData.selectedStateOption);
    formDataObject.append("data[company_informations_attributes][0][address_attributes][country]", this.state.addressStateData.selectedCountryOption);
    formDataObject.append("data[company_informations_attributes][0][address_attributes][zipcode]", this.state.addressStateData.zipcode);

    formDataObject.append("data[company_informations_attributes][0][bank_detail_attributes][bank_name]", this.state.bankStateData.banknane);
    formDataObject.append("data[company_informations_attributes][0][bank_detail_attributes][beneficiary_name]", this.state.bankStateData.beneficiaryname);
    formDataObject.append("data[company_informations_attributes][0][bank_detail_attributes][account_number]", this.state.bankStateData.accountnumber);
    formDataObject.append("data[company_informations_attributes][0][bank_detail_attributes][ifsc_code]", this.state.bankStateData.ifsccode);
    formDataObject.append("data[company_informations_attributes][0][bank_detail_attributes][branch_name]", this.state.bankStateData.branchname);


    const requestSubmitMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage));
    const requestHeader = {
      // "Content-Type": "multipart/form-data",
        // token: this.state.token
    };
    this.postGeneralData = requestSubmitMessage.messageId;
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        // configJSON.postSignup/`${this.state.userId}`,
       `account_block/accounts/${this.state.userId}`
    );
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(requestHeader)
    );
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        // configJSON.exampleAPiMethod
        "PATCH"
    );
    requestSubmitMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formDataObject);
    runEngine.sendMessage(requestSubmitMessage.id, requestSubmitMessage);
  }

  // Customizable Area Start
  // Customizable Area End
}