import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { setStorageData } from "framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export type ImageType = string | null | undefined;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  categories: string;
  categoryList: {
    attributes: {
      id: number;
      name: string;
    }
  }[];
  catCount:number;
  rowsPerPage: number;
  page: number;
  dataList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      category_id: string;
      image: ImageType;
      sub_categories: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        parent_id: number | null;
        rank: number | null;
      }[];
    };
  }[];
  allChecked: boolean;
  allSelectedIds: number[];
  value: number;
  searchData: {}[];
  searchText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Catalogue1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryListApiCallId: string = "";
  getSearchDataListApiCallId: string = "";
  getAllCategoryListApiCallId: string = "";
  getCategorySearchApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      categories: " ",
      page: 0,
      value: 1,
      catCount:0,
      allChecked: false,
      allSelectedIds: [],
      rowsPerPage: 5,
      categoryList: [],
      dataList: [],
      searchData: [],
      searchText: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCategoryList(0)
    this.getAllCategoryList()
  }
 
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCategoryListApiCallId) {     
        this.setState({
          dataList: responseJson.data,
          catCount: responseJson.meta.pagy.count,
        })
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiReqCallId === this.getAllCategoryListApiCallId) {
        this.setState({          
          categoryList: resJson.data,
        })        
      }

      
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiSearchReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const resSearchJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiSearchReqCallId === this.getCategorySearchApiCallId) {     
        this.setState({
          dataList: resSearchJson.data,    
          catCount: resSearchJson.meta.pagy.count,
        })
      }
    }
    // Customizable Area End
  }
  getSearchDataList = async () => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategorySearchApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productSearchPath}` + `search=`+this.state.searchText
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getAllCategoryList = async () => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCategoryListApiCallId = getValidationMsg.messageId;
    getValidationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCategoryPath
    );

    getValidationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationMsg.id, getValidationMsg);
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleChangeCategories = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ categories: event.target.value as string ,
      searchText: event.target.value as string     
    })  
    this.getSearchDataList()
  }
  handleRowClick = (event: React.ChangeEvent<HTMLInputElement>, checkedId: number) => {
    const isCheckedRow = event.target.checked;
    this.setState((prevState: S) => {
      const updatedIds = isCheckedRow
        ? [...prevState.allSelectedIds, checkedId]
        : prevState.allSelectedIds.filter((selectId: number) => selectId !== checkedId);

      const areAllChecked = prevState.dataList.every((data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          category_id: string;
          image: ImageType
          sub_categories: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
            parent_id: number | null;
            rank: number | null;
          }[];
        }
      }) =>
        updatedIds.includes(data.attributes.id)
      );  
      return {
        allSelectedIds: updatedIds,
        allChecked: areAllChecked,
      };
    });
  };
  handleSearch = (event: React.ChangeEvent<{value: unknown}>) => {
    this.setState({ searchText: event.target.value as string})    
    this.getSearchDataList()
  };
  handleAllChnage=(event:React.ChangeEvent<HTMLInputElement>) => {    
    const isCheck = event.target.checked;
    this.setState({
      allChecked: isCheck
    })
    if(isCheck){
    const newDataIds = this.state.dataList.map((item:{
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
        category_id: string;
        image: ImageType;
        sub_categories: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
          parent_id: number | null;
          rank: number | null;
        }[];
      };
    }) => item.attributes.id)
    this.setState({
      allSelectedIds: newDataIds
    })
    }else{
      this.setState({
        allSelectedIds: []
      })  
    }
  }
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {

   
    
    this.setState({
      page: value
    })
    this.getCategoryList(value);
  };
  goBack = () => {
    this.catalogueNavigationMessage('Dashboard')
  }
  getCategoryList = async (pageNumber: number) => {
    const headers = {
      token: await getStorageData('authToken'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoryListApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategoryListPath}`+`page=${pageNumber+1}&items=${this.state.rowsPerPage}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);    
  }
   

  navigateToProduct = async() => { 
    
  await setStorageData("selectedCategoryIds", JSON.stringify(this.state.allSelectedIds));
  this.catalogueNavigationMessage('Aiproductsearchandselection')
 
  };
  catalogueNavigationMessage = (path:string|undefined) => {
    const catalogueMessage = new Message(getName(MessageEnum.NavigationMessage));
    catalogueMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    catalogueMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(catalogueMessage);
   }
  // Customizable Area End
}
