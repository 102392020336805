import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { State, City }  from 'country-state-city';

export interface Location {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
}

export interface Statelocations {
  countryCode: string;
  latitude: string;
  longitude: string;
  name: string;
  stateCode: string;
  isoCode: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  billAdd: {street: string, zipcode: string, selectedOption: string, selectedStateOption: string, selectedCountryOption: string};
  passState: Function;
  fieldRequiredData: string
  // Customizable Area End
}

interface S {
    // Customizable Area Start
    addressData: {street: string, zipcode: string, selectedOption:string, selectedStateOption:string, selectedCountryOption: string},
    showDropDownCity: boolean;
    selectedOption: string;
    searchTerm: string;
    searchStateTerm: string;
    showDropDownState: boolean;
    selectedStateOption: string;
    selectedCountryOption: string;
    filteredCitieData:  Location[];
    filteredStateData:  Statelocations[]
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BillingAddBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {     
            // Customizable Area Start
            addressData: {street: "", zipcode: "", selectedOption:"", selectedStateOption:"",  selectedCountryOption: ""},
            showDropDownCity: false,
            selectedOption: "",
            searchTerm: "",
            searchStateTerm: "",
            showDropDownState: false,
            selectedStateOption: "",
            selectedCountryOption:"India",
            filteredCitieData: [],
            filteredStateData: []

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();   
    this.setState({ addressData: this.props.billAdd, selectedOption: this.props.billAdd.selectedOption, selectedStateOption: this.props.billAdd.selectedStateOption, selectedCountryOption: this.state.selectedCountryOption})
    
    const cities = City.getCitiesOfCountry('IN');
    const filteredCitie: Location[] | undefined = cities && cities.filter((city: { name: string }) => city.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())) as Location[];
    this.setState({ filteredCitieData: filteredCitie ? filteredCitie : [] });
    
    const states = State.getStatesOfCountry('IN');
    const filteredState: Statelocations[] | undefined = states && states.filter((state:{name:string}) => state.name.toLowerCase().includes(this.state.searchStateTerm.toLowerCase())) as Statelocations[];
    this.setState({ filteredStateData:filteredState})
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
    if(prevProps.billAdd.street !== this.props.billAdd.street){   
      this.setState({ addressData: this.props.billAdd, selectedOption: this.props.billAdd.selectedOption, selectedStateOption: this.props.billAdd.selectedStateOption, selectedCountryOption: this.state.selectedCountryOption})
    }
  }

  // Customizable Area Start
  handleBillingInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({addressData:{...this.state.addressData, [event.target.name]:event.target.value}});
    this.props.passState({ ...this.state.addressData, [event.target.name]: event.target.value, selectedOption: this.state.selectedOption, selectedCountryOption:this.state.selectedCountryOption, selectedStateOption:this.state.selectedStateOption });
  } 

  handleDropdownCity = () => {
    this.setState({ showDropDownCity: !this.state.showDropDownCity })
  }

  handleCityDropdownChange = (event: string) => {
    this.setState({ selectedOption: event, showDropDownCity: false});
    this.props.passState({...this.state.addressData, selectedOption:event, selectedCountryOption:this.state.selectedCountryOption })
  
  };
 
  handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleStateSearch = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchStateTerm: event.target.value });
  };

  handleDropdownState = () => {
    this.setState({ showDropDownState: !this.state.showDropDownState })
  }

  handleStateChange = (event: string) => {
    this.setState({ selectedStateOption: event, showDropDownState: false });
    this.props.passState({...this.state.addressData,selectedStateOption:event,selectedOption:this.state.selectedOption, selectedCountryOption:this.state.selectedCountryOption })
  };

  // Customizable Area End
}
