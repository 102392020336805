import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, {DragEvent, ChangeEvent} from "react";

// Customizable Area Start
interface File {
  type: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  docData: Certificate,
  passState: Function,
  sizeErr: string
  // Customizable Area End
}

type Certificate = {
  name: string;
  file: File | null;
  gstin_number: string
}

interface S {
// Customizable Area Start
uploadCertificate: Certificate,
sizeErr: string,
isOver: boolean;
// Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentsBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    // Customizable Area Start
    uploadCertificate: {
      name: "",
      file: null,
      gstin_number: ""
    },
    sizeErr:"",
    isOver: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({uploadCertificate: this.props.docData })
  }

 

  // Customizable Area Start

  handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({...this.state,isOver:true})
  };

  handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      this.setState({...this.state,isOver:false})
  };

  handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({...this.state,isOver:false})

    const droppedFiles = Array.from(event.dataTransfer.files);

    if (droppedFiles.length > 0 && this.isPDF(droppedFiles[0])) {
      
      this.setState(
        (prevState) => ({
          uploadCertificate: { ...prevState.uploadCertificate, file: selectedFile },
          sizeErr: "", 
        }),
        () => {
          this.props.passState({ ...this.state.uploadCertificate, uploadCertificate: this.state.uploadCertificate.file });
        }
      );

      } else {
        this.setState({ sizeErr: "File size exceeds the maximum limit of 25MB. Please select a smaller file."})
      }

      const selectedFile = droppedFiles[0];
      if (selectedFile) {
        const maxSizeInBytes =  25 * 1024 * 1024; 
        if (selectedFile.size > maxSizeInBytes) {
          this.setState({ sizeErr: "File size exceeds the maximum limit of 25MB. Please select a smaller file."})
          return;
        }
      }  
  }

  isPDF = (file: File) => {
    return file.type === 'application/pdf';
  };

  onFileChange = (event:ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
    
      const maxSizeInBytes =  25 * 1024 * 1024;
      if (selectedFile.size > maxSizeInBytes) {
        this.setState({ sizeErr: "File size exceeds the maximum limit of 25MB. Please select a smaller file."})
        return;
      }
    }  
    
    this.setState(
      (prevState) => ({
        uploadCertificate: { ...prevState.uploadCertificate, file: selectedFile } as Certificate,
        sizeErr: "", 
      }),
      () => {
        this.props.passState({ ...this.state.uploadCertificate, uploadCertificate: this.state.uploadCertificate.file });
      }
    );
    this.props.passState({...this.state.uploadCertificate, uploadCertificate: this?.state?.uploadCertificate?.file})
  }

  handleDownload = () => {
    const { gstin_number, name } = this.props.docData;

        const link = document.createElement('a');
        link.href = name;
        link.download = `document_${gstin_number}.pdf`; 
        link.target = '_blank';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
  }

  // Customizable Area End
}
