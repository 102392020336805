import React from 'react';

// Customizable Area start
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableCell,
  Checkbox,
  Typography,
  Box,  
  Select,
  MenuItem,
  styled,
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormControl from '@material-ui/core/FormControl';
import { Pagination } from '@material-ui/lab';
import SideBar from '../../../components/src/SideBar.web';

// Customizable Area End

import Catalogue1Controller, {
  Props
} from "./Catalogue1Controller";


export default class Catalogue1 extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { page, rowsPerPage } = this.state;
    const totalCount = this.state.catCount;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCount - page * rowsPerPage);
    const rangeEnd = Math.min((page + 1) * rowsPerPage, totalCount); 
    const rangeStart = page * rowsPerPage + 1;
    
    return (
      // Customizable Area Start
      <SideBar navigation={this.props.navigation} handleSearchFun={(event)=>this.handleSearch(event)}>
        <Box style={{ padding: "20px", backgroundColor: "#F8FAFC", }}>
          <Box style={webStyle.headDiv}>
            <Button style={webStyle.iconBox} role="go-back-icon" onClick={this.goBack}>
              <ArrowBackIosIcon style={{ fontSize: "15px" }} />
            </Button>
            <Typography style={webStyle.button} align='center'>
              Select Categories
            </Typography>
          </Box>
          <Box sx={{ marginTop: '20px' }}>


            <FormControl variant="outlined" style={webStyle.formControl}>
              <MuiSelectSelect
                labelId="select-label"
                id="select"
                value={this.state.categories}
                inputProps={{
                  id: 'outlined-age-native-simple',
                  name: 'categories',
                  style: { width: "100%", height: "20px" }
                }}
                data-testId='category-selection'
                onChange={(event) => this.handleChangeCategories(event)}             
              >
                   <MenuItem value={" "}>All Categories</MenuItem>
                {this.state.categoryList?.map((option, index) => (
                  <MenuItem key={option.attributes.id} id="menuOption" value={option.attributes.name}>{option.attributes.name}</MenuItem>
                ))}
              </MuiSelectSelect>
            </FormControl>
          </Box>

          <TableContainer component={Paper} >
            <Table >
              <TableHead style={webStyle.tableHeader}>
                <TableRow >
                  <TableCell style={webStyle.head}>Category</TableCell>
                  <TableCell style={webStyle.head} align='center'>Category Id</TableCell>
                  <TableCell style={webStyle.head} align='right'>
                    <Checkbox style={{ color: "gray", }} data-testId="all-checksBox" checked={this.state.allChecked} onChange={this.handleAllChnage} />
                    Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              
                  {this.state.dataList.map((category: {
                    id: string;
                    type: string;
                    attributes: {
                      id: number;
                      name: string;
                      category_id: string;
                      image: string | null | undefined;
                      sub_categories: {
                        id: number;
                        name: string;
                        created_at: string;
                        updated_at: string;
                        parent_id: number | null;
                        rank: number | null;
                      }[];
                    };
                  }, index: number) => {

                    const isSelected = this.state.allSelectedIds.includes(category.attributes.id)
                    return (
                      <TableRow key={index} style={{ backgroundColor: isSelected ? "#edeef0" : "#fff" }}>
                        <TableCell style={webStyle.mainBox}>
                          <img src={category.attributes.image ? category.attributes.image : '' } width="40px" height="40px" style={webStyle.image} />
                          <Typography style={webStyle.categoryName}>{category.attributes.name}</Typography>
                        </TableCell>
                        <TableCell align='center'>{category.attributes.category_id}</TableCell>
                        <TableCell align='right' style={{ paddingRight: "75px" }}>
                          <Checkbox data-testId={`checkbox-testid-${index}`} checked={isSelected} onChange={(event) => this.handleRowClick(event, category.attributes.id)} style={{ color: "#ff8000" }} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow  data-testId="rowEmpty" style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box style={webStyle.paginationBox}>
              <Box style={webStyle.paginationStyle}>
                <span data-testId="range">
                  {rangeStart} - {rangeEnd} of {totalCount} results
                </span>
              </Box>

              <Pagination
                data-testId="pagination"
                count={Math.ceil(this.state.catCount / this.state.rowsPerPage)}
                page={page + 1}
                onChange={(event, value) => this.handleChangePage(event, value - 1)}
                style={{ padding: "20px" }}
              />

            </Box>
          </TableContainer>
          <Box style={webStyle.btnBox}>
            <Button style={{ ...webStyle.btn, textTransform: "none" }} data-testId="submit-button" onClick={this.navigateToProduct}>Save</Button>
          </Box>
        </Box>
      </SideBar>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MuiSelectSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
      borderRadius: '8px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #979797',borderRadius: '8px'
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "1px solid #979797",borderRadius: '8px'
  },
})
const webStyle = {
  paginationStyle: { padding: "20px", opacity: 0.8 },
  headDiv: {
    display: "flex"
  },
  paginationBox: { display: "flex", justifyContent: "space-between" },
  iconBox: {
    width: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #C8C8C8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  head: {
    fontWeight: 700, color: '#808080', fontSize: "16px", minWidth: "max-content"
  },
  button: {
    fontWeight: 700, fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "7px",
  },
  categoryName: {
    fontFamily: "Roboto-Medium",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "20px",
    minWidth: "max-content"
  },
  image: {
    border: "5px solid #ffe6e6",
    borderRadius: "5px"
  },
  mainBox: {
    marginLeft: 0,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  formControl: {
    width: '200px',
    marginBottom: "30px"
  },
  tableHeader: {
    backgroundColor: '#f1f4f9',
  },
  table: {
    display: "flex",
    justifyContent: "space-between",
  },
  lastRow: {
    display: "flex",
    justifyContent: "end",
  },
  btn: {
    width: "160px", color: "#fff", background: "#090A17", borderRadius: "10px", padding: "8px", fontSize: "18px", opacity: 0.9
  },
  btnBox: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px"
  },
};

// Customizable Area End
