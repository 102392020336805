export const back = require("../assets/cat.png");
export const search = require("../assets/ser.jpg");
export const range = require("../assets/range.jpg");
export const tool = require("../assets/tool.jpg");
export const down = require("../assets/dow.jpg");
export const plus = require("../assets/plus.png");
export const wishlist = require("../assets/wishlist.png");
export const eyeIcon = require("../assets/eye.png");
export const ribon = require("../assets/new.jpg");
export const bestSeller = require("../assets/best_seller.png");
export const soldOut = require("../assets/sold_out.png");











