import React from "react";

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { logo, designTop, designBottom, roundLeft, check, back, rightTick, cross, correct } from "./assets";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiDialogContentText-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '18px',
      fontWeight: 400,
      color: '#0F172A',
      margin: '10px 0',
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
    },
    '& .MuiButton-root': {
      backgroundColor: '#FAFAFA',
      border:"1px solid black",
      width:"104px",
      height:"44px",
      marginTop:"-19px"
    },
  }));

const OtpBlock = styled("div")(({ theme }) => ({
  background:"#FFFFFF",
  cursor:"default",
  "& .logo": {
    width: "290px",
    height:" 174px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    zIndex:1,
  },  
  "& .logo-box": {
    width: "100px",
    height:"50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex:1,
  },
  "& .login-content": {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    borderRight: "1px solid #FFC299",
    height:"100vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:900px)": {
      display:"none",
    },
  },
  "& .login-content-input": {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,   
    height:"100vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:900px)": {
      width:"100%"
    },
  },
  "& .top-design": {
    width: "216px",
    height: "216px",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex:0,
  },
  "& .bottom-design": {
    width: "216px",
    height: "216px",
    display: "flex",
    position: "absolute",
    bottom: 0,
    right: "50%",
    zIndex:0,
  },
  "& .main-block": {
    width:"100%",
    display:"flex",
    "@media (max-width:900px)": {
      width:"100vw",
      display:"flex", 
      alignItems:"center", 
      justifyContent:"center",
    },
  },
  "& .round-left": {
    width: "38px",
    height: "38px",
    position: "absolute",
    top: "70%",
    left: "10%",
    zIndex:0,
  },
  "& .round-right": {
    position: "absolute",
    top: "27%",
    left: "39%",
    zIndex:0,
  },
  "& .middle-box": {
    display:"flex",
    flexDirection:"column",
    "@media (max-width:900px)": {
    alignItems:"center", 
    justifyContent:"center",
    },
  },  
  "& .detail-text": {
    marginBottom: "20px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    fontSize:"16px",
    fontWeight:400,
    color:"#334155",
    fontFamily: 'Inter, sans-serif',
    "@media (max-width:900px)": {
      width:"80%",
      marginRight:"auto",
      marginLeft:"auto",
      textAlign: "center",
    },
  },
  "& .login-block": {
    display: "flex",
    flexDirection: "column",
  },
  "& .otp-text": {
    fontSize:"22px",
    fontWeight:700,
    fontFamily: 'Inter, sans-serif',
    marginTop: "10px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    "@media (max-width:900px)": {
      fontSize:"25px"
    },
  }, 
  "& .mobile-text": {
    fontSize:"14px",
    fontWeight:700,
    fontFamily: 'Inter, sans-serif',
    color:"#334155"
  },
  "& .mobile-block":{
    display: "flex",
    flexDirection: "column",
  },
  '& .mui-field': {
    borderRadius: '10px',
    border: '2px solid #FAFAFA',
    width:"70%",
  },
  '& .MuiFilledInput-root': {
    color:"hsla(240,7%,62%,1)",
  },
  "& .sign-up": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px"
  },
  '& .dropdown-list-language': {
    gap: "10px",
    marginTop: "13px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid #CBD7E1",
    width: "83px",
    padding: "10px",
    borderRadius: "10px 0px 0px 10px",
    height: "23px"
  },
  "& .otp-btn": {
    width: "130px",
    height: "44px",
    backgroundColor: "#FFA76C",
    borderRadius: "12px",
    marginTop: "26px",
    color: "#FFFFFF",
    padding:"10px 20px",
    "@media (max-width:900px)": {
      width:"80%",
      marginRight:"auto",
      marginLeft:"auto",
      display: "block"
    },
  },
  "& .otp-btn-active": {
    width: "130px",
    height: "44px",
    backgroundColor: "#FF6600",
    borderRadius: "12px",
    marginTop: "26px",
    color: "#FFFFFF",
    padding:"10px 20px",
    "@media (max-width:900px)": {
      width:"80%",
      marginRight:"auto",
      marginLeft:"auto",
      display: "block"
    },
  },
  "& .dropdown-list-data-language": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "50px",
    backgroundColor:"#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "94px",
  },
  "& .dropdown-list-data-language li": {
    padding: "0px 18px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .phone-input": {
    width:"400px", 
    height:"42px", 
    borderRadius:"8px", 
    padding:"10px", 
    marginTop:"12px", 
    border: "1px solid #CBD7E1",
  },
  "& .flag-icon": {
    width: "29px",
    height: "20px",
    borderRadius: "3px"
  },
  "& .time-data": {
    fontSize:"14px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#2B2A29",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start"
  },
  "& .phone-input-error": {
    width:"400px", 
    height:"42px", 
    borderRadius:"8px", 
    padding:"10px", 
    marginTop:"12px", 
    border: "1px solid transparent",
    backgroundColor:"#FEE8E8",
    color:"#171A1F",
    fontWeight:400,
    fontSize:"16px",
    resize:"none",
    "@media (max-width:900px)": {
      width:"100%",
    },
  },
  "& .que-data": {
    fontSize:"16px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#323842"
  },
  "& .resend-data": {
    fontSize:"16px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#FF6600",
    marginLeft: "5px",
    cursor:"pointer"
  },
  "& .resend-data-passive": {
    fontSize:"16px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#FFA76C",
    marginLeft: "5px"
  },
  "& .back-text": {
    fontSize:"14px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#FF6600",
    cursor:"pointer",
  },
  "& .otp-verify": {
    display:"flex", 
    alignItems:"center", 
    justifyContent:"center",
    fontSize:"18px",
    fontWeight: 400,
    color:"#0F172A"
  },
  "& .otp-error": {
    fontSize:"12px",
    fontWeight: 400,
    color:"#DC2626",
    marginTop: "7px",
    fontFamily: 'Inter, sans-serif',
  },
  "& .phone-input[type=number]::-webkit-inner-spin-button,.phone-input[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .phone-input[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .phone-input-error[type=number]::-webkit-inner-spin-button,.phone-input-error[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .phone-input-error[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .input-data-text[type=number]::-webkit-inner-spin-button,.input-data-text[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .input-data-text[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .logo-text":{
    marginTop:"-15px",
    fontSize:"18px",
    fontWeight:500,
    color:"#334155"
  },
  "& .logo-desc":{
    marginTop:"30px",
    fontSize:"18px",
    fontWeight: 600,
    color:"#2B2A29",
    zIndex:1,
  },
  "& .displayLogo":{
    display: "none",   
    "@media (max-width:900px)": {
      marginBottom:"20px",
      display:"flex", alignItems:"center", justifyContent:"center",
    },
  },
  "& .desc":{
    marginTop:"10px", 
    width: "77%",
    textAlign: "center",
    color:"#334155",
    zIndex:1,
  },
  "& .buttonIn": {
    position: "relative",
    "@media (max-width:900px)": {
      width:"80vw",
      display:"flex", 
      alignItems:"center", 
      justifyContent:"center",
    },
  },
  "& .input-data-text::placeholder":{
    color:"BCC1CA"
  },
"& .input-data-text": {
    margin: "0px",
    padding: "10px",
    outline: "none",
    fontFamily: 'Inter, sans-serif',
    width: "400px",
    height: "42px",
    borderRadius: "8px",
    border: "1px solid transparent",
    backgroundColor:"#F3F4F6",
    marginTop: "10px",
    color:"#171A1F",
    fontWeight:400,
    fontSize:"16px",
    resize:"none",
    "@media (max-width:900px)": {
      width:"100%"
    },
},
"& .btn-data": {
    position: "absolute",
    top: "16px",
    right:"10px",
    zIndex: 2,
    border: "none",
    height: "30px",
},
}))
// Customizable Area End

import OtpverificationController, {
  Props,
} from "./OtpverificationController";

export default class Otpverification extends OtpverificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
  
    return (
      // Customizable Area Start
      <>
      <OtpBlock>
        <Box className="main-block">
          <Box className="login-content">
            <img className="top-design" src={designTop} alt="designTop" />
            <img className="round-left" src={roundLeft} alt="designTop" />
            <img className="logo" src={logo} alt="logo" />
            <Typography className="logo-text">KRATEX tagline goes here</Typography>
            <Typography className="logo-desc">Lorem ipsum dolor sit amet</Typography>
            <Typography className="desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a .Praesent aliquet, quam non lobortis feugia.</Typography>
            <img className="round-right" src={roundLeft} alt="designTop" />
            <img className="bottom-design" src={designBottom} alt="designBottom" />
          </Box>      
        
          <Box className="login-content-input">
              <Box style={{}}>
                <Box style={{textAlign:"left"}}>
                <Box className="displayLogo" >
                    <img className="logo-box" src={logo} alt="logo" />
                  </Box>
                    <img style={{width: "56px",height: "56px", marginLeft:"42%"}} src={check} alt="check"/>
                    <Typography className="otp-text">OTP Verification</Typography>
                    <Typography className="detail-text">Enter verification code sent to your mobile number.</Typography>
                    <Box className="middle-box">
                    <Typography className="mobile-text">OTP</Typography>

                    <Box style={{ display:"flex", flexDirection:"column"}}>
                    <div className="buttonIn">
                      <textarea 
                        id="enter"
                        name="otp"
                        data-test-id="otp-val"
                        className={this.state.otpTextError ? "phone-input-error" : "input-data-text"}
                        placeholder="Enter your 5 digit OTP"
                        value={this.state.otpText}
                        onChange={this.handleOTPChange}
                        />
                      {this.state.isVerified ? (<img className="btn-data" src={correct}/>) : ""}
                    </div>
                      {this.state.otpTextError && this.state.otpTextError.length ? <Box className="otp-error">{this.state.otpTextError}</Box> : ""}
                    </Box>
                    </Box>                
                    <Box style={{marginTop:"10px"}}>
                    <Button data-test-id="otp-submit-btn" className={this.state.fullFieldOtp? "otp-btn-active" : "otp-btn"} disabled={this.state.fullFieldOtp?false:true} onClick={this.handleSubmit}>Submit OTP</Button>
                    </Box>
            
                    <Box style={{marginTop:"20px"}}>
                        <Typography className="time-data">Time Remaining {this.formatTime()}</Typography>
                    </Box>
                    <Box style={{ display:"flex",alignItems:"center", justifyContent:"flex-start", marginTop:"9px"}}>
                        <Typography className="que-data">Didn't receive the OTP?</Typography>
                        <Typography data-test-id="otp-resend-btn" className={this.state.timeIsUp?"resend-data":"resend-data-passive"}>Click to resend</Typography>
                    </Box>
                    <Box data-test-id="back-btn" onClick={this.handleBack} style={{ display:"flex", gap:"5px",alignItems:"center", justifyContent: "flex-start", width: "100%", margin:"20px 0px"}}>
                    <img style={{ width:"12px", height: "12px"}} src={back} alt="back"/>
                    <Typography className="back-text">Back to login</Typography>
                    </Box>
                </Box>
              </Box>
                
            </Box>
        </Box>

        <div className="dialoug">
            <StyledDialog open={this.state.openDialog} onClose={this.handleClose}>
                <Box style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}> 
                <img src={rightTick} alt="rightTick" style={{marginLeft: "159px", marginTop: "33px"}}/>
                <img data-test-id="close-btn" src={cross} style={{ width:"24px", height:"24px", margin:"14px", cursor:"pointer"}} onClick={this.handleClose}/>
                </Box>
                
                <DialogContent>
                <DialogContentText>
                    OTP Verified
                </DialogContentText>
                <DialogContentText style={{fontSize: '14px',fontWeight: 400, color:"#64748B"}}>
                Your mobile no has been Verified successfully.  
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button data-test-id="continue-btn" onClick={this.handleContinue} variant="contained" autoFocus>
                    Continue
                </Button>
                </DialogActions>
            </StyledDialog>
        </div>
      </OtpBlock>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End