Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "signuplogin";
exports.labelBodyText = "signuplogin Body";

exports.postOtp = "bx_block_login/otps";
exports.postOtpPinData = "bx_block_login/logins";
exports.postSignup = "account_block/accounts";
exports.privacy = "Privacy";
exports.termsCondition = "Terms and Conditions";
exports.termsConditionPath = "/bx_block_terms_and_conditions/terms_and_conditions?title=";


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End