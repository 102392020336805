import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";

const InfoBlock = styled("div")(({ theme }) => ({
  cursor:"default",
  "& .input-data": {
    width: "100%",
    height: "44px",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "10px"
  },
  "& .label-text": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
  },
  "& .input-data:focus": {
    borderColor: "#FF6600",
    outline: "none",
  },
  "& .phone-input: focus": {
    borderColor: "#FF6600",
    outline: "none",
  },
  "& .phone-input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& .phone-input": {
    width: "462px",
    height: "46px",
    padding: "10px",
    marginTop: "12px",
    border: "1px solid #CBD7E1",
    borderRadius: "0px 10px 10px 0px"
  },
  "& .phone-input[type=number]::-webkit-inner-spin-button,.phone-input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .error-msg": {
    color: "red",
    marginTop: "5px",
    fontSize: "12px"
  },

}))
// Customizable Area End

import BankDetailController, {
  Props,
} from "./BankDetailController";

export default class BankDetail extends BankDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <InfoBlock>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className="label-text">Bank Name</Typography>
            <input data-test-id="bank-name" className="input-data" type="text" onChange={this.handleBankInfoChange} name="banknane" value={this.state.bankData.banknane}></input>
            <span className="error-msg">{this.state.fieldRequiredData}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className="label-text">Beneficiary Name</Typography>
            <input className="input-data" type="text" onChange={this.handleBankInfoChange} name="beneficiaryname" value={this.state.bankData.beneficiaryname}></input>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className="label-text">Account Number</Typography>
            <input
              className="input-data"
              data-test-id="account-input"
              type="text"
              onChange={this.handleBankInfoChange}
              onBlur={this.handleBlur}
              name="accountnumber"
              value={this.state.bankData.accountnumber}
              placeholder="xxxxxxxxxxxx"
            />
            <span className="error-msg">{this.state.fieldRequiredData}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className="label-text">IFSC Code</Typography>
            <input className="input-data" type="text" onChange={this.handleBankInfoChange} name="ifsccode" value={this.state.bankData.ifsccode}></input>
            <span className="error-msg">{this.state.fieldRequiredData}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className="label-text">Branch Name</Typography>
            <input className="input-data" type="text" onChange={this.handleBankInfoChange} name="branchname" value={this.state.bankData.branchname}></input>
            <span className="error-msg">{this.state.fieldRequiredData}</span>
          </Grid>
        </Grid>

      </InfoBlock>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End