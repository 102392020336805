Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "aiproductsearchandselection";
exports.labelBodyText = "aiproductsearchandselection Body";
exports.getProductListPath="/bx_block_productdescription/products?"
exports.btnExampleTitle = "CLICK ME";
exports.getProductSearchListPath="/bx_block_productdescription/products/search?query[]=";
exports.allCategoryPath="/bx_block_categories/categories/category_list";
exports.allSubCategoryPath="/bx_block_categories/sub_categories/sub_category_list";
exports.allManufacturerPath="/bx_block_productdescription/get_manufacturer";
exports.getFilterListPath="/bx_block_productdescription/products?";
// Customizable Area End