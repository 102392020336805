import React from "react";

import {
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  // Customizable Area Start  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { topDesign, login, designBottom, checking, kxLogo, rightTick, cross } from "./assets";
import GeneralInfo from "../src/GeneralInfo.web";
import CompanyInfo from "./CompanyInfo.web";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .MuiDialogContentText-root': {
    margin: '10px 0',
    display: 'flex',
    color: '#0F172A',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    fontSize: '18px',
  },
  '& .MuiButton-root': {
    backgroundColor: '#FAFAFA',
    width: "104px",
    marginTop: "-19px",
    border: "1px solid black",
    height: "44px",
  },
  '& .MuiDialogActions-root': {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SignupBlock = styled("div")(({ theme }) => ({
  cursor:"default",
  "& .account-text": {
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    marginTop: "16px"
  },
  "& .detail-text": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#334155",
    marginTop: "6px"
  },
  "& .steps": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop:"60px",
    margin: "0 100px",
    "@media (max-width:900px)": {
      margin: "20px !important",
    },
  },
  "& .step": {
    position: "relative",
    display: "inline-block",
    backgroundColor: "#94A3B8",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    textAlign: "center",
    lineHeight: "40px",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  "& .step-number": {
    display: "block",
    fontFamily: 'Inter, sans-serif',
    marginTop:"-5px"
  },
  "& .step.active": {
    backgroundColor: " #FF6600",
    color: "#fff",
  },
  "& .step.checked": {
    content: '✓',
    color: "#34D399",
    backgroundColor: "#34D399",
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    textAlign: "center",
    lineHeight: "40px",
    fontWeight: "bold",
    top: 0,
    paddingRight:"4px",
    paddingTop:"2px",
  },
  "& .step-content": {
    marginBottom: "20px",
  },
  "& .signupBox": {
    margin: "0 120px",
    "@media (max-width:900px)": {
      margin: "0 70px !important",
    },
  },
  "& .actions": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bottom: "20px",
    position: "relative",
    marginTop: "50px",
  },
  "& .first-step": {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: "#64748B"
  },
  "& .first-step.active": {
    color: "black"
  },

  "& .continue-btn": {
    width: "360px",
    height: "44px",
    color: "#FFFFFF",
    backgroundColor: "#F60",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 700,
  },
  "& .bottom-design": {
    display: "flex",
    justifyContent: "end"
  },
  "& .skip-btn": {
    width: "67px",
    height: "44px",
    background: "#FFC299",
    border: "none",
    borderRadius: "8px",
    color: "#FF6600",
    cursor: "pointer"
  },
  "& .main-box": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .MuiAlert-standardSuccess": {
    backgroundColor: "#FEE2E2"
  },
  "& .MuiAlert-standardError .MuiAlert-icon": {
    display: "none"
  },
  "& .MuiAlert-standardError": {
    borderLeft: "4px solid #DC2626",
    color: "#DC2626"
  },
}))
// Customizable Area End

import SignupController, {
  Props,
} from "./SignupController";
import AddressInfo from "./AddressInfo.web";
import BankDetail from "./BankDetail.web";

export default class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const getStepClassName = (stepNumber: number) => {
      const { currentStep } = this.state;

      if (currentStep === stepNumber) {
        return 'active';
      } else if (currentStep > stepNumber) {
        return 'checked';
      } else {
        return '';
      }
    };

    const renderButton = () => {
      const { currentStep } = this.state;

      if (currentStep < 3) {
        return (
          <button data-test-id="stepper-btn" onClick={this.goToNext} className="continue-btn">
            Continue
          </button>
        );
      } else if (currentStep === 3) {
        return (
          <button data-test-id="stepper-btn-nxt" onClick={this.goToNext} className="continue-btn">
            Continue
          </button>
        );
      } else if (currentStep === 4) {
        return (
          <button data-test-id="create-account-btn" onClick={this.handleSubmit} className="continue-btn">
            Continue
          </button>
        );
      } else {
        return null;
      }
    };

    const renderStepNumber = (stepNumber: number) => {
      const { currentStep } = this.state;
      return currentStep > stepNumber ? (
        <img style={{ margin: "10px" }} src={checking} />
      ) : (
        String(stepNumber)
      )
    };

    return (
      // Customizable Area Start
      <SignupBlock>

        <Box className="main-box">
          <img src={topDesign} alt="topDesign" />
          <img src={kxLogo} style={{ marginRight: "42px" }} />
        </Box>
        <Box className="signupBox">
          <Box >
            <img style={{ width: "56px", height: "56px" }} src={login} alt="login" />
            <Typography className="account-text">Create an account</Typography>
            <Typography className="detail-text">Please enter your details.</Typography>
          </Box>
          {(!this.generalRef?.current?.state?.isChecked && this.state.currentStep ==1 ) ? (
            <div style={{ margin: "30px 0" }}>
              <Collapse in={this.state.open}>
                <Alert
                  severity="error"
                  data-test-id="close-btn-m"
                  onClick={() => {
                    this.setState({ open: false })
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  You have to agree with Privacy Policy and Terms and Conditions to Sign up
                </Alert>
              </Collapse>
            </div>

          ) : ""}
          <div>
            <div className="steps">
              <Grid container spacing={2} justifyContent="center">
                <Grid item lg={3} md={6} sm={6} xs={12} >
                  <div data-test-id="step-btn-one" onClick={() => this.handleClick(1)} style={webStyle.tabSteps}>
                    <div className={`step ${getStepClassName(1)}`}>
                      <span className="step-number">{renderStepNumber(1)}</span>
                    </div>
                    <div className={`first-step ${getStepClassName(1)}`}>General Information</div>
                  </div>
                </Grid>
                <div className={`connector ${this.state.currentStep > 1 ? 'active' : ''}`}></div>
                <Grid item lg={3} md={6} sm={6} xs={12} >
                  <div data-test-id="step-btn-two" onClick={() => this.handleClick(2)} style={webStyle.tabSteps}>
                    <div className={`step ${getStepClassName(2)}`}>
                      <span className="step-number">{renderStepNumber(2)}</span>
                    </div>

                    <div className={`first-step ${getStepClassName(2)}`}>Documents Details</div>
                  </div>
                </Grid>
                <div className={`connector ${this.state.currentStep > 2 ? 'active' : ''}`}></div>
                <Grid item lg={3} md={6} sm={6} xs={12} >
                  <div data-test-id="step-btn-three" onClick={() => this.handleClick(3)} style={webStyle.tabSteps}>
                    <div className={`step ${getStepClassName(3)}`}>
                      <span className="step-number">{renderStepNumber(3)}</span>
                    </div>
                    <div className="first-step">Address Information</div>
                  </div>
                </Grid>
                <div className={`connector ${this.state.currentStep > 3 ? 'active' : ''}`}></div>
                <Grid item lg={3} md={6} sm={6} xs={12} >
                  <div data-test-id="step-btn-four" onClick={() => this.handleClick(4)} style={webStyle.tabSteps}>
                    <div className={`step ${getStepClassName(4)}`}>
                      <span className="step-number">{renderStepNumber(4)}</span>
                    </div>
                    <div className="first-step">Bank Details</div>
                  </div>
                  </Grid>
              </Grid>
            </div>
            <Divider style={{ margin: "30px 0" }}></Divider>
            <div className="step-content">
              {this.state.currentStep === 1 && <GeneralInfo generalStateData={this.state.generalStateData} ref={this.generalRef} passState={this.handleGeneralState} currentStep={this.state.currentStep} handleNext={this.handleNext} navigation={this.props.navigation} history={this.props.history} id={""} />}
              {this.state.currentStep === 2 && <CompanyInfo companyStateData={this.state.companyStateData} ref={this.companyRef} passState={this.handleCompanyState} handleNext={this.handleNext} navigation={this.props.navigation} history={this.props.history} id={""} data-test-id="company-info"/> }
              {this.state.currentStep === 3 && <AddressInfo addressStateData={this.state.addressStateData} ref={this.addressRef} passState={this.handleAddressState} handleNext={this.handleNext} navigation={this.props.navigation} history={this.props.history} id={""} />}
              {this.state.currentStep === 4 && <BankDetail bankStateData={this.state.bankStateData} ref={this.bankRef} passState={this.handleBankState} currentStep={this.state.currentStep} handleNext={this.handleNext} navigation={this.props.navigation} history={this.props.history} id={""} />}            
            </div>
            <div className="actions">
              {renderButton()}
            </div>
          </div>
        </Box>
        <Box   className="bottom-design">
        <img src={designBottom} alt="designBottom" /></Box>

        <div className="dialoug">
          <StyledDialog open={this.state.openDialog} onClose={this.handleClose}>
            <Box style={webStyle.closeBox}>
              <img data-test-id="close-btn" src={cross} style={webStyle.closeImg} onClick={this.handleClose} />
            </Box>
            <Box style={webStyle.rightBox}>
              <img src={rightTick} alt="rightTick" />
            </Box>

            <DialogContent>
              <DialogContentText>
                Profile Created Sucessfully.
              </DialogContentText>
              <DialogContentText style={{ fontSize: '14px', fontWeight: 400, color: "#64748B" }}>
                Your profile has been Created successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button data-test-id="continue-btn" style={{ textTransform: "none", fontWeight: "bold" }} onClick={this.handleContinue} variant="contained" autoFocus>
                Continue
              </Button>
            </DialogActions>
          </StyledDialog>
          <Loader loading={this.state.loading} />
        </div>
        
      </SignupBlock>
      // Customizable Area End
    );
  } 
}

// Customizable Area Start
const webStyle = {
  tabSteps: { display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
 
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  rightBox: {
    display: "flex",
    justifyContent: "center",   
  },
  closeBox: {
    display: "flex",
    justifyContent: "end",
  },
  closeImg:{ width: "24px", height: "24px", margin: "14px", cursor: "pointer" }
};
// Customizable Area End
