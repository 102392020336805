import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Divider, Grid } from '@material-ui/core';
import { styled } from "@material-ui/styles";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";

type S = { showDropDownCountry: boolean, showDropDownLanguage: boolean, scrollPosition: number, showDropDownCategori: boolean, selectedCategory:string, isSubMenuOpen: boolean, token: any, catData: any, catAllData: any, subCat:any, selectedItem: any, dropAccount: boolean };
type Props = { history?: any};


const FooterBlock:any = styled("div")(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
      "& .MuiTypography-body1":{
        fontFamily: 'Inter, sans-serif',
      },
  "& .main-content": {
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden'
  },
  "& .accessText":{
    color:"#323643",
    fontSize: "20px",
    fontWeight: 400,
    textAlign:"center",
    "@media (max-width:900px)": {
      marginTop:"10px"
    },
  },
  "& .requestAccess":{
    height:"100px",
    backgroundColor:"rgba(0, 0, 0, .05)",
    "@media (max-width:900px)": {
      height:"auto"
    },
  },
  "& .requestAccessBtn":{
    width:"130px",
    height:"30px",
    padding:"10px 6px",
    backgroundColor:"#FF6600",
    display:"flex",
    marginLeft:"30px",
    borderColor:"transparent",
    borderRadius:"8px",
    justifyContent:"center",
    alignItems:'center',
    "@media (max-width:900px)": {
      marginLeft:"0px",
      marginBottom:"10px"
    },
  },
  "& .requestAccessTxt":{
    color:"#FFF",
    fontSize: "14px",
    fontWeight: 500,
    textAlign:"center",
  },
  "& .footer":{
    padding:"30px 20vw",
    display:"flex",
    justifyContent:"center",
    width:"100vw"
  },
  "& .footerLogo":{
    width:"90px",
    height:"50px"
  },
  "& .footerCont":{
    display:"flex",
    justifyContent:"center"
  },
  "& .footerText":{
    textAlign:"center",
    color:"#323643",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .footerInfo":{
    color:"#323643",
    fontSize: "12px",
    fontWeight: 400,
    margin:"7px"
  },
  "& .footerSoc":{
    width:"36px",
    height:"36px",
    margin:"0 8px",
    marginBottom:"20px"
  },
}))
const webStyle ={
  footerBox:{
    display: "flex", float: "right", flexWrap: "wrap", marginBottom: "53px", justifyContent: "center", 
  },
  firstContent:{
    minWidth: "200px" ,minHeight:"400px", mb:1, display: "flex", justifyContent:{ lg:"start",sm:"center", xs:"center" },
    "@media (max-width:900px)": {
      width:"80%",
      minHeight:"200px",
      justifyContent:"start",
    },
  },
  allContent:{
    minWidth: "200px", mb: 1,minHeight:"400px", display: "flex", justifyContent: "center",
    "@media (max-width:900px)": {
      width:"80%",
      minHeight:"200px",
      justifyContent:"start",
    },
  }
}

interface SS {
  id: any;
}
class Footer extends BlockComponent<Props, S, SS>{
  getCatList: string = "";
  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        showDropDownCountry: false,
        showDropDownLanguage: false,
        scrollPosition: 0,
        showDropDownCategori: false,
        selectedCategory: "",
        isSubMenuOpen: false,
        token:"",
        catData: [],
        catAllData: [],
        subCat: "",
        selectedItem:"",
        dropAccount: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: any): void {
    
  }

  render() {
    return (
        <>
          <FooterBlock>
            <Box style={{backgroundColor:"white"}}>
              <Grid container direction="row" justifyContent="center" alignItems="center" className="requestAccess">
                <Grid item>
                  <Typography className="accessText">
                    Elevate your Building Material procurement with Krate<span style={{color:"#FF6600"}}>X</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <button className="requestAccessBtn">
                    <Typography className="requestAccessTxt">
                      Request Access
                    </Typography>                  
                  </button>
                </Grid>              
              </Grid>
              <footer>
                <Grid container direction="column" justifyContent="center" className="footer">
                  <Grid item className="footerCont" xs={12} sm={12} md={12} lg={12}>
                    <svg width="214" height="90" viewBox="0 0 214 90" fill="none" xmlns="http://www.w3.org/2000/svg" className="footerLogo">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M62.3359 89.9789H86.6248L63.1464 48.1662L46.3133 62.5845L62.3359 89.9789ZM82.5675 19.4618L105.105 0.0195312H81.9726L32.4795 42.0431L42.1331 0.0195312H21.4415L0.777344 89.9789H21.4688L26.6934 67.2322L32.1911 62.6073L82.5675 19.4618Z" fill="#1B1918"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M194.745 89.9789L173.919 47.591L159.447 61.6677L158.176 63.061L170.714 89.9789H194.745ZM175.817 39.3307L213.224 0.0195312H190.734L167.691 24.9125L168.326 26.2377L175.817 39.3307Z" fill="#1B1918"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M141.1 43.4578L132.939 29.1929L118.949 0.0195312H95.3036L116.763 44.2289L72.9688 89.9789H96.8722L125.379 58.7503L141.1 43.4578Z" fill="#FF6600"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M171.889 43.4578L163.728 29.1929L149.738 0.0195312H126.093L147.552 44.2289L103.758 89.9789H127.661L156.168 58.7503L171.889 43.4578Z" fill="#FF6600"/>
                    </svg>
                  </Grid>
                  <Grid item className="footerCont" direction="row" xs={12} sm={12} md={12} lg={12}>
                    <Typography className="footerInfo">Solutions</Typography>
                    <Typography className="footerInfo">Material Prices</Typography>
                    <Typography className="footerInfo">News</Typography>
                    <Typography className="footerInfo">Request Access</Typography>
                    <Typography className="footerInfo">Contact Us</Typography>
                  </Grid>
                  <Grid item className="footerCont" direction="row" xs={12} sm={12} md={12} lg={12}>
                    <img src={require("./x1.png")} className="footerSoc" />
                    <img src={require("./facebook2.png")} className="footerSoc" />
                    <img src={require("./linkedin3.png")} className="footerSoc" />
                    <img src={require("./youtube4.png")} className="footerSoc" />
                  </Grid>
                  <Grid item className="footerCont" xs={12} sm={12} md={12} lg={12}>
                    <Typography className="footerText">
                      Adipisicing exercitation et amet tempor ipsum non. 
                      Ex ex id nostrud dolore duis est excepteur fugiat venia.
                      Qui consectetur sunt magna eiusmod ipsum nostrud sint consequat 
                      officia fugiat duis exercitation.
                    </Typography>
                  </Grid>
                </Grid>
              </footer>
            </Box>
          </FooterBlock>
        </>
    )
  }
}
export default Footer;