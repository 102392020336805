import React from "react";

import {
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";

const GeneralBlock = styled("div")(({ theme }) => ({
  paddingLeft:"24px",
   "& .rera-input": {
    width: "650px",
    height: "44px",
    marginTop: "10px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    pointerEvents: "none",
    backgroundColor: "#f4f4f4", 
    color: "#888", 
   },
   "& .input:focus": {
    borderColor:"#FF6600",
    outline: "none", 
   },
   "& .label-text": {
    fontSize: "14px",
    fontWeight: 700,
    color:"#272727"
   },
   "& .rera-input-edit": {
    width: "650px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    padding: "10px",
   },
   "& .comm-input": {
    width: "436px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    padding: "10px",
    height: "44px",
    borderRadius: "8px",
    pointerEvents: "none",
    backgroundColor: "#f4f4f4", 
    color: "#888", 
   },
   "& .comm-input-edit": {
    width: "436px",
    border: "1px solid #CBD5E1",
    marginTop: "10px",
    padding: "10px",
    height: "44px",
    borderRadius: "8px",
   }
  }))
// Customizable Area End

import ReraBuyerController, {
  Props,
} from "./ReraBuyerController";

export default class ReraBuyer extends ReraBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    return (
      // Customizable Area Start
      <GeneralBlock>
        <Grid container style={{ marginTop:"35px"}} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">RERA</Typography>
            <input data-test-id="rera-name" id="rera" className={this.props.isEdit ? "rera-input-edit" : "rera-input"} type="text" name="rera" onChange={this.handleReraChange} value={this.state.rera}></input>
          </Grid>
        </Grid>
      </GeneralBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End