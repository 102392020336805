import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start 
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  Grid,
  Divider,
  Tabs,
  Tab,
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { imgDone, img5, viewIcon, DeleteIcon } from './assets'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';



// Customizable Area End

import PeritempricingController, {
  Props,
} from "./PeritempricingController";
import { Pagination } from "@material-ui/lab";
import SideBar from '../../../components/src/SideBar.web';

export default class Peritempricing extends PeritempricingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  viewWebStyleData = (isActive: boolean) => {
    return isActive
      ? viewWebStyle.activeTabStyle
      : { ...viewWebStyle.tabStyle1 };
  };

  getImageUrl = (images:{file_name: string; content_type: string; id: number; url: string;}[] | undefined) => {
    return images && images.length > 0 ? images[0].url : ''
  }
 

  getDescription = (text: string ) => {
    return this.state.showFullText ? text : text.slice(0, 146);
  }

  getProductDescription = (description: string) => {
      return description ?  `${description.slice(0,46)}...`:'';
  }

  getReadMore = (text: string) => {
    return text.length >= 146 && !this.state.showFullText &&
    <span style={viewWebStyle.textColor} onClick={this.handleReadMoreClick} data-testId="read-more">
      Read more...
    </span>
  }

  getReadLess = () => {
    return this.state.showFullText &&
    <span style={viewWebStyle.textColor} onClick={this.handleLessClick} data-testId="less" >
      Less...
    </span>
  }

  getLearnMore = (specificationData:{[key: string]: string}) => {
    return Object.entries(specificationData).length >= 5 && !this.state.showFullObj &&
    <Typography style={viewWebStyle.textColor} onClick={this.handleLearnMoreClick} data-testId="learn-more">
      Learn More
    </Typography>
  }

  getLearnLess = () => {
    return this.state.showFullObj &&
      <Typography style={viewWebStyle.textColor} onClick={this.handleLearnLessClick} data-testId="less-learn">
        Less...
      </Typography>
  }

   getSpecificationData = (specificationData: { [key: string]: string }) => {
    return Object.keys(specificationData).map((items) => {
      const value = specificationData[items];
      return (
        <Typography key={items} style={viewWebStyle.lineHights}>
          <img src={imgDone} />
          <span style={{ width: "max-content" }}>{items}</span>:
          <span style={viewWebStyle.subtTitle2}>{value}</span>
        </Typography>
      );
    });
  };
  // Customizable Area End

  render() {
    const { page, rowsPerPage } = this.state;
    const totalCount = this.state.proTotalCount;
    const rangeStart = page * rowsPerPage + 1;
    const emptyRows = this.itemsEmptyRows(rowsPerPage, totalCount, page);
    const rangeEnd = this.itemRangeEnd(page, rowsPerPage, totalCount);
    const viewData = this.state.viewProData.product.data.attributes;
    const showProductId = this.state.viewProData.product.data.id;
    const viewProductdata = viewData.product.data.attributes
    const specificationData = this.getSpecifications(viewProductdata.specification);
   
    const text = viewProductdata.description
    const TabPanel: React.FC<{
      children: React.ReactNode;
      value: number;
      index: number;
    }> = ({ children, value, index, ...other }) => {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    return (
      // Customizable Area Start  
      <SideBar navigation={this.props.navigation} handleSearchFun={(event) => this.handleSearchData(event)}>
        <>
          {!this.state.openView ?
            <Box style={webStyle.mainPage} data-testId='view-box'>
              <Box style={webStyle.headDiv}>
                <Box data-testId='back-icon' style={webStyle.iconBox} onClick={this.goBackProduct}>
                  <ArrowBackIosIcon style={{ fontSize: "15px" }} />
                </Box>
                <Typography style={webStyle.button}>
                  Set Products Price
                </Typography>
              </Box>
              {!this.state.tableLoading &&
                <TableContainer component={Paper} >
                  <Table >
                    <TableHead style={webStyle.tableHeader}>
                      <TableRow >
                        <TableCell style={{ ...webStyle.head, minWidth: 200 }}>Product</TableCell>
                        <TableCell style={webStyle.head} align='center'>Product ID</TableCell>
                        <TableCell style={webStyle.head} align='center'>Manufacture</TableCell>
                        <TableCell style={webStyle.head} align='center'>Category</TableCell>
                        <TableCell style={webStyle.head} align='center'>Sub Category</TableCell>
                        <TableCell style={webStyle.head} align='center'>Your Price / Unit</TableCell>
                        <TableCell style={{ ...webStyle.head, minWidth: "150px", paddingRight: "35px" }} align='right'>
                          Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        this.state.dataList.map((data: {
                          id: number | string;
                          type: string;
                          attributes: {
                            price: string | number;
                            account_id: number;
                            product_id: number;
                            created_at: string;
                            updated_at: string;
                            prices: {
                              id: number;
                              action: string;
                              quantity: number;
                              amount: number;
                              product_id: number | null;
                              created_at: string;
                              updated_at: string;
                              seller_product_id: number;
                            }[];
                            product: {
                              data: {
                                id: string;
                                type: string;
                                attributes: {
                                  id: number;
                                  name: string;
                                  brand_name: string;
                                  price: number | null;
                                  prices:{
                                    id: number;
                                    action: string;
                                    quantity: number;
                                    amount: number;
                                    product_id: number | null;
                                    created_at: string;
                                    updated_at: string;
                                    seller_product_id: number;
                                  }[];
                                  change: string;
                                  change_up: boolean;
                                  description: string;
                                  manufacturer: string;
                                  specification: string;
                                  applications: string;
                                  certifications: string;
                                  is_recommended: boolean;
                                  status: string;
                                  product_id: string;
                                  images?: {
                                    file_name: string;
                                    content_type: string;
                                    id: number;
                                    url: string;
                                  }[] ;
                                  category: string;
                                  sub_category: string;
                                  brand:string;
                                  updated: string;
                                  is_new: string;
                                  is_best_seller: boolean;
                                  product_feature: {
                                    data: {
                                      id: string;
                                      type: string;
                                      attributes: {
                                          feature_name: string;
                                          feature_options: {
                                              id: number;
                                              option_value: string;
                                              product_feature_id: number;
                                          }[];
                                      };
                                    }[];
                                  };
                                };
                              };
                            };
                          };
                        }, index: number) => {
                          const productData = data.attributes.product.data.attributes;
                          return (
                            <TableRow key={index} style={{ backgroundColor: "#fff" }}>
                              <TableCell data-testid="productName" style={webStyle.mainBox}>
                                <img src={this.getImageUrl(productData.images)} width="40px" height="40px" style={webStyle.image} />
                                <Box style={webStyle.discBox}>
                                  <Typography style={webStyle.categoryName}>{productData.name} </Typography>
                                  <Typography style={webStyle.disc}> {this.getProductDescription(productData.description)}</Typography>
                                </Box>

                              </TableCell>
                              <TableCell align='center'>{productData.product_id}</TableCell>
                              <TableCell align='center'>{productData.manufacturer}</TableCell>
                              <TableCell align='center'>{productData.category}</TableCell>
                              <TableCell align='center'>{productData.sub_category}</TableCell>
                              <TableCell align='center' style={webStyle.priceMainBox}><InputBase inputProps={{ placeholder: `price-input-${index}` }} value={data.attributes.price} onChange={(event: { target: { value: string } }) => this.handlePriceChange({ value: event.target.value, dataId: data.id })} style={webStyle.priceBox} /></TableCell>
                              <TableCell align='right' style={webStyle.action}>
                                <img src={viewIcon} width="28px" height="28px" data-testId="view-icon" style={{ cursor: "pointer" }} onClick={(event) => this.handleOpenView(event, data.id)} />
                                <img src={DeleteIcon} style={webStyle.deleteIcon} width="28px" height="28px" data-testId="delete-icon" onClick={() => this.handleClickOpen(data.id)} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={3} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Dialog
                    open={this.state.openModel}
                    onClose={this.handleClose}
                    data-testId="open-model"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{marginTop:"-900px"}}
                  >
                    <DialogTitle id="alert-dialog-title" ><Typography variant="h6" style={webStyle.deleteTitle}>Delete Product</Typography></DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Product?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleClose}
                        color="primary"
                        style={{...webStyle.deleteButton, textTransform:"none"}}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleDelete()}
                        data-testId="handleDelete"
                        color="primary"
                        autoFocus
                        style={{...webStyle.deleteButton,textTransform:"none", background:"#DC2626"}}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Box style={webStyle.paginationBox}>
                    <Box style={webStyle.paginationStyle}>
                      <span>
                        {rangeStart} - {rangeEnd} of {totalCount} results
                      </span>
                    </Box>

                    <Pagination
                      data-testId="pagination"
                      count={Math.ceil(this.state.proTotalCount / this.state.rowsPerPage)}
                      page={page + 1}
                      onChange={(event, value) => this.handleChangePagePerItem(event, value - 1)}
                      style={{ padding: "20px" }}
                    />

                  </Box>

                </TableContainer>}
              <Box style={webStyle.btnBox}>
                <Button style={{...webStyle.btn, textTransform:"none"}} data-testId='button-save' onClick={this.navigateToProduct}>Save</Button>
              </Box>
            </Box>
            :
            <Box style={viewWebStyle.mainPage} data-testId="open-view">
              <Box style={viewWebStyle.headDiv}>
                <Box style={viewWebStyle.iconBox} data-testId="close-view" onClick={this.handleViewClose}>
                  <ArrowBackIosIcon style={{ fontSize: "15px" }} />
                </Box>
                <Typography style={viewWebStyle.button} data-testId="product-name">
                  {viewProductdata.name}
                </Typography>
              </Box>

              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item lg={6} md={12} xs={12}>
                  <Box style={viewWebStyle.img}>
                    <img src={this.getImageUrl(viewProductdata.images)} height='500px' width="500px" />
                  </Box>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    {viewProductdata.images && viewProductdata.images.length > 1 && viewProductdata.images.filter((element,index) => index !== 0).map((element,index) =>(
                      <Grid key={index} item lg={4} md={4} sm={4} xs={6}>
                      <Box style={viewWebStyle.imgBox}>
                        <img src={element.url} width='100%' height="100%" />
                      </Box>
                    </Grid>
                    ) )}
                  </Grid>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="h3" style={viewWebStyle.title} data-testId="brand-name">
                    {viewProductdata.brand_name}
                  </Typography>
                  <Divider />
                  <Typography style={viewWebStyle.subTitle} data-testId="description">
                    {this.getDescription(text)}
                    {this.getReadMore(text)}
                    {this.getReadLess()}
                  </Typography>
                  <Divider />
                  <Box style={{ marginTop: "15px" }}>
                    <Typography style={viewWebStyle.title2}> Specifications</Typography>
                    
                    {this.getSpecificationData(specificationData)}
                    {this.getLearnMore(specificationData)}
                    {this.getLearnLess()}
                  </Box>
                </Grid>

              </Grid>
              <div style={viewWebStyle.tabsBox}>
                <Tabs
                  value={this.state.value}
                  data-testId="tabs"
                  onChange={this.handleChangeValue}
                  TabIndicatorProps={{ style: { backgroundColor: "#FF6600" } }}
                  variant="scrollable"
                  aria-label="simple tabs example"
                  style={{ borderBottom: "1px solid #E2E8F0", }}
                >
                  <Tab label="Description" {...a11yProps(0)} style={{ ...this.viewWebStyleData(this.state.value === 0), fontSize: '16px', textTransform: 'none' }} />

                  <Tab label="Specifications" {...a11yProps(1)} style={{ ...this.viewWebStyleData(this.state.value === 1), fontSize: '16px', textTransform: 'none' }} />
                  <Tab label="Applications" {...a11yProps(2)} style={{ ...this.viewWebStyleData(this.state.value === 2), fontSize: '16px', textTransform: 'none' }} />
                  <Tab label="Certifications" {...a11yProps(3)} style={{ ...this.viewWebStyleData(this.state.value === 3), fontSize: '16px', textTransform: 'none' }} />
                </Tabs>
                <TabPanel value={this.state.value} index={0}>
                  {viewProductdata.description}
                </TabPanel>
                <TabPanel value={this.state.value} index={1} >
                {this.getSpecificationData(specificationData)}
                </TabPanel>
                <TabPanel value={this.state.value} index={2} >
                  {viewProductdata.applications}
                </TabPanel>
                <TabPanel value={this.state.value} index={3} >
                  {viewProductdata.certifications}
                </TabPanel>
              </div>
              <Box style={viewWebStyle.inputs}>
                <Typography style={viewWebStyle.typo}>Add Bulk Pricing</Typography>

                {this.state.range.map((range: {
    id: number,
    quantity:number,
    amount:number,
    action:string,
    isNew?: boolean
  }, index: number) => (
                  <Box key={index} data-testId="range-inputs" style={viewWebStyle.allInputBox} >
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} xs={6}>
                        <Box>
                          <Typography style={viewWebStyle.inputLabel}>
                            When Quantity is
                          </Typography>
                          <Box style={viewWebStyle.box1}>
                            <FormControl
                              variant="outlined"
                              style={{ marginRight: "2%", width: "50px" }}
                            >
                              <select
                                name="action"
                                value={range.action}
                                
                                onChange={(event) => this.handleChangeQty(event, index)}
                                style={{
                                  height: "55px",
                                  borderRadius: "5px",
                                  border: "1px solid lightgray",
                                  backgroundColor: "white"
                                }}
                              >
                                <option value="<">&lt;</option>
                                <option value="=">=</option>
                                <option value=">">&gt;</option>
                              </select>
                            </FormControl>
                            <TextField
                              type="number"
                              name="quantity"
                              inputProps={{ placeholder: `range-qty-${index}` }}
                              value={range.quantity}
                              variant="outlined"
                              style={viewWebStyle.firstInput}
                              onChange={(event) => this.handleChangeQty(event, index)}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} xs={6}>
                        <Box>
                          {" "}
                          <Typography style={viewWebStyle.inputLabel}>Price</Typography>
                          <Box style={{ display: "flex" }}>
                            <TextField
                              type="number"
                              name="amount"
                              value={range.amount}
                              variant="outlined"
                              style={viewWebStyle.firstInput}
                              onChange={(event) => this.handleChangeQty(event, index)}
                            />
                            <Box
                              style={viewWebStyle.deleteIcon}
                              data-testId="delete-row-icon"
                              onClick={() => this.handleDeleteRange(range.id, index)}
                            >
                              <DeleteOutlineIcon />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button style={viewWebStyle.addInput} onClick={this.handleAddRange}><img src={img5} style={{ marginRight: "4px" }} /> Add another Range</Button>
                <Box style={viewWebStyle.btnBox}>
                  <Button data-testId="save-button" style={{ ...viewWebStyle.btn, textTransform: "none" }} onClick={() => this.handleSaveBtn(showProductId)}>Save</Button>
                </Box>
              </Box>  
                  
            </Box>
          }

        </>
      </SideBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  paginationStyle: { opacity: 0.8, padding: "20px", },
  paginationBox: { display: "flex", justifyContent: "space-between" },
  mainPage: { backgroundColor: "#F8FAFC", padding: "20px" },
  headDiv: {
    display: "flex",
    marginBottom: "20px"
  },
  head: {
    fontWeight: 700, color: '#808080', fontSize: "16px",
    minWidth: "max-content"
  },
  button: {
    fontWeight: 700, fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "7px",
  }, iconBox: {
    width: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #C8C8C8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    border: "5px solid #ffe6e6",
    borderRadius: "5px"
  },
  tableHeader: {
    backgroundColor: '#f1f4f9',
  },
  mainBox: {
    marginLeft: 0,
    minWidth: 250,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  table: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    width: "160px", color: "#fff", background: "#090A17", borderRadius: "10px", padding: "8px", fontSize: "18px", opacity: 0.9,
  },
  lastRow: {
    display: "flex",
    justifyContent: "end",
  },
  btnBox: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px"
  },
  disc: {
    fontSize: "11px"
  },
  categoryName: {
    fontFamily: "Roboto-Medium",
    fontSize: "15px"
  },
  discBox: {
    marginLeft: "20px",
    minWidth: "max-content",
  },
  priceMainBox: {
    borderRadius: "10px",
  },
  deleteIcon: { fontSize: "30px", color: "#DC2626", marginLeft: "20px", cursor: "pointer" },
  action: { paddingRight: "30px", width: "80px" },
  priceBox: {
    border: "1px solid lightgray",
    borderRadius: "5px",
    margin: "auto",
    width: "80px",
    padding: "0 5px"
  },
  deleteTitle:{fontWeight:700},
  deleteButton: { margin:"0 10px 10px 0",width: "120px", color: "#fff", background: "#090A17", borderRadius: "10px", padding: "8px", fontSize: "18px", opacity: 0.9, }
};

const viewWebStyle = {
  mainPage: { padding: "20px", backgroundColor: "#F8FAFC" },
  headDiv: {
    display: "flex",
    marginBottom: "20px"
  },
  btn: {
    width: "90px", color: "#fff", background: "#FF6600", borderRadius: "10px", padding: "8px", fontSize: "18px", opacity: 0.9,margin:"20px 40px"
  },
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto-Medium",
    background: "#fff",
    flexDirection: "column",
    paddingBottom: "30px",
  },
  iconBox: {
    width: "35px",
    height: "35px",
    border: "1px solid #C8C8C8",
    borderRadius: "5px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100px",
    justifyContent: "space-between",
  },
  buttonStyle: {
    height: "45px",
    width: "100%",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
  },
  title: {
    marginBottom: "20px",
    fontWeight: 600,
  },
  title2: {
    fontWeight: 600,
  },
  subTitle: {
    margin: "20px",
    fontSize: "20px",
  },
  subtTitle2: {
    color: '#666666'
  },
  lineHights: {
    alignItems: "center",
    gap: "10px",
    display: "flex",
    marginTop: "10px",
  },
  tabStyle: { color: "#334155", padding: "15px", },
  textColor: {
    color: "#FF6600",
    cursor: "pointer"
  },
  img: {
    justifyContent: "center",
    border: '2px solid #94A3B8',
    padding: '50px 0',
    alignItems: "center",
    display: "flex",
  },
  imgBox: {
    padding: "20px",
    border: "1px solid #94A3B8",
    maxWidth: '200px',
    height: "200px"
  },
  tabsBox: {
    borderRadius: "8px",
    marginTop: "20px",
    border: "1px solid #E2E8F0",
    fontSize: "16px",
    boxShadow: "0px 0px 5px #E2E8F0",
  },
  tabStyle1: {
    borderBottom: "2px solid transparent",
  },
  activeTabStyle: {
    color: "#FF6600",
    borderBottom: "2px solid #FF6600",
  },
  inputs: {
    borderRadius: "5px",
    boxShadow: "0px 0px 5px #E2E8F0",
    border: "1px solid #E2E8F0",
    marginTop: "20px",
  },
  typo: { fontWeight: 600, padding: "20px", borderBottom: "1px solid #E2E8F0" },
  allInputBox: { display: "flex", marginBottom: "0px", padding: "20px", },
  box1: {
    display: "flex",
    marginBottom: "10px",
  },
  inputLabel: { fontWeight: 600, fontSize: "13px", marginBottom: "8px", color: "#334155" },
  deleteIcon: {
    fontSize: "30px", color: "#DC2626", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "15px", width: "50px", cursor:"pointer"
  },
  firstInput: { width: "88%", borderRadius: "8px" },
  selectDropdown: {
    border: " 1px solid lightgray",
    borderRadius: "5px",
    height: "55px",
    background: "white",
    padding: "15px"
  },
  addInput: {
    color: "#FF6600",
    padding: "20px", fontWeight: 500,
    textDecotation: "none",
    alignItems: 'center',
    gap: "15px",
    display: "flex",
  },
  btnBox: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px"
  }, 
  button: {
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "7px",
    fontSize: "16px",
  },

};
// Customizable Area End
